import {isObjEmpty} from '../util/obj-util';
import {Farm, Field, FieldCovers, FieldSeriesData, GetFieldScoringRowsRow, HarvestYear} from './interfaces';
import {strToHarvestYear} from './types';

export interface FieldScoringItem {
  field: Pick<Field, 'added_on' | 'external_field_id' | 'field_area' | 'field_id' | 'field_location'>;
  farm: Farm;
  cropId: string;
  regional?: {
    region: null | string;
    relative_productivity: null | number;
    relative_stability: null | number;
    percentile_productivity: null | number;
    percentile_stability: null | number;
  };
  productivity: {
    score: null | number;
    history: Record<string, null | number>;
  };
  stability: {
    score: null | number;
    volatility: null | number;
  };
  homogeneity: {
    score: null | number;
    history: Record<string, null | number>;
  };
  cropCoverage?: Record<string, null | number>;
  cloudCover: Record<string, null | number>;
}

export function toFieldScoreItem(row: GetFieldScoringRowsRow): FieldScoringItem[] {
  return (row.field_data || []).map(fd => {
    const hasRegional =
      !!fd &&
      (fd.relative_productivity_score !== null ||
        fd.relative_stability_score !== null ||
        fd.percentile_productivity_score ||
        fd.percentile_stability_score);
    return {
      field: row.field!,
      farm: row.farm!,
      cropId: fd?.crop_id ?? 'unknown',
      regional: hasRegional
        ? {
            region: fd.region_name,
            relative_productivity: fd.relative_productivity_score,
            relative_stability: fd.relative_stability_score,
            percentile_productivity: fd.percentile_productivity_score,
            percentile_stability: fd.percentile_stability_score,
          }
        : undefined,
      productivity: {
        score: fd?.productivity_score ?? null,
        history: getHistogram(row.field_series_data, 'max_ndvi'),
      },
      stability: {
        score: fd?.stability_score ?? null,
        volatility: fd?.vol_ndvi ?? null,
      },
      homogeneity: {
        score: fd?.homogeneity_score ?? null,
        history: getHistogram(row.field_series_data, 'avg_homogeneity'),
      },
      cropCoverage: getCropCovers(row.land_covers, fd?.crop_id ?? null),
      cloudCover: getHistogram(row.field_series_data, 'cloudy_weeks_pct'),
    };
  });
}

function getCropCovers(fieldCovers: null | (null | FieldCovers)[], crop_id: null | string) {
  const res: {[k in HarvestYear]?: number} = {};
  for (const cover of fieldCovers ?? []) {
    const year = strToHarvestYear(cover?.source.slice(0, 4));
    if (year && cover?.cover && cover.cover_type && crop_id?.startsWith(cover.cover_type)) {
      res[year] = cover.cover;
    }
  }

  if (isObjEmpty(res)) {
    return undefined;
  }

  return res;
}

function getHistogram(
  fieldSeriesData: null | (null | FieldSeriesData)[],
  key: keyof FieldSeriesData,
): Record<string, number> {
  return Object.fromEntries(
    (fieldSeriesData ?? [])
      .filter(fsd => !!fsd && fsd[key] !== null)
      .map(fsd => [fsd?.harvest_year, fsd?.[key] as number]),
  );
}
