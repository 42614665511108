import {unsampledFieldsAreUndamagedEnabled} from '../feature-flags';
import {HarvestCustomColumns} from '../models/CustomColumns';
import {
  CostType,
  Farm,
  Field,
  GetFarmHarvestRowsRow,
  Harvest,
  HarvestCrop,
  HarvestData,
  LossCause,
  Policy,
  Sample,
} from '../models/interfaces';
import {
  AreaUnit,
  AreaValue,
  Cost,
  DensityUnit,
  DensityValue,
  UnitPriceValue,
  ValueUnit,
  YieldUnit,
  YieldValue,
} from '../models/types';
import {IndexedCrops} from '../redux/reducers/crops';
import {DbState} from '../redux/reducers/db';
import {HarvestDesc} from '../report/report-types';
import {aggregate, filterNulls, remove, unique} from '../util/arr-util';
import cmp from '../util/cmp';
import {isFarmHarvest} from '../util/harvest-util';
import {getBaseCrop} from './crops';
import {getSamplesByHarvestId} from './getSamplesByHarvestId';
import {harvestAggregationKeyEq} from './harvest-key';
import {
  UnitSystem,
  convertArea,
  convertDensity,
  convertLossToUnit,
  convertYield,
  getIdealYieldUnit,
  metricUnitSystem,
} from './units';
import {
  YieldStats,
  calculateYieldStats,
  deriveEstimated,
  deriveFeasible,
  deriveTotalLoss,
  getSampleLosses,
} from './yield';

export function getMinMaxSampleDate(samples: Sample[]): null | [string, string] {
  let minDate = null,
    maxDate = null;
  for (const sample of samples) {
    if (minDate == null || (sample.sample_date && sample.sample_date < minDate)) {
      minDate = sample.sample_date;
    }
    if (maxDate == null || (sample.sample_date && sample.sample_date > maxDate)) {
      maxDate = sample.sample_date;
    }
  }

  if (!minDate || !maxDate) {
    return null;
  } else {
    return [minDate, maxDate];
  }
}

export function getSum<U extends ValueUnit>(
  values: U[],
  fallbackUnit: U['unit'],
  harvest_crop: null | HarvestCrop,
): null | U {
  if (values.length == 0) {
    return null;
  }

  // Collect only units with non-zero value.val as zeroes won't change the sum, but may unnecessarily cause the usage of fallbackUnit.
  const units = new Set<U['unit']>(values.filter(value => value.val).map(x => x.unit));
  const sum = {
    val: 0,
    unit: (units.size == 1 ? Array.from(units)[0] : fallbackUnit) as U['unit'],
  } as U;

  const mode = YieldUnit.includes(sum.unit as YieldUnit)
    ? 'yield'
    : AreaUnit.includes(sum.unit as AreaUnit)
      ? 'area'
      : DensityUnit.includes(sum.unit as DensityUnit)
        ? 'density'
        : 'percent';
  for (const value of values) {
    const conv =
      mode == 'yield'
        ? convertYield(sum.unit as YieldUnit, value as YieldValue, harvest_crop)
        : mode == 'area'
          ? convertArea(sum.unit as AreaUnit, value as AreaValue)
          : mode == 'density'
            ? convertDensity(sum.unit as DensityUnit, value as DensityValue)
            : mode == 'percent'
              ? value
              : null;
    if (!conv) {
      return null;
    }
    sum.val += conv.val;
  }

  return sum;
}

export function getAvg<U extends ValueUnit>(
  values: (null | U)[],
  fallbackUnit: U['unit'],
  harvest_crop: null | HarvestCrop,
  weights: null | number[],
): null | U {
  if (weights && weights.length != values.length) {
    console.error('getAvg called with weights of different length:', values.length, weights.length);
    return null;
  }

  const summableValues: U[] = [];
  let totalWeight = 0;
  for (let i = 0; i < values.length; ++i) {
    const value = values[i];
    if (value) {
      const weight = weights ? weights[i] : 1;
      totalWeight += weight;
      summableValues.push({...value, val: value.val * weight});
    }
  }

  if (totalWeight == 0) {
    return null;
  }

  const sum = getSum(summableValues, fallbackUnit, harvest_crop);
  return sum ? ({unit: sum.unit, val: sum.val / totalWeight} as U) : null;
}

// Returns the area-weighted average yield of the given fields' samples. Assumes that all samples' harvests are
// consistent and are of the same crop type. Fields without samples do not affect the weighted average.
type YieldColumns = Pick<Sample, 'estimated_yield' | 'feasible_yield' | 'losses'>;

export interface HarvestSamples {
  field: Pick<Field, 'field_area'>;
  harvest: Pick<Harvest, 'crop_id'>;
  samples: YieldColumns[];
}

export interface AggregatedHarvest {
  stats: YieldStats;
  data: (YieldStats & {sample_id: null | string})[];
  harvest: Harvest;
  field: null | Field;
  farm: Farm;
  policy: null | Policy;
  samples: Sample[];
}

export type CostsData = {
  [P in CostType]?: {
    [field_id: string]: {
      cost: null | number;
      cost_area: null | AreaValue;
      cost_per_area: null | number;
    };
  };
};

export interface HarvestAnalytics {
  // Stats for this group of harvests. A grouping may be a farm-crop-season-year tuple, or a crop-season-year triplet.
  stats: YieldStats;

  // The harvests and their stats.
  harvests: AggregatedHarvest[];

  // Costs (e.g. resowing)
  costs: CostsData;

  // Farm-harvest level sample/observations
  overrideSample: null | Sample;

  numFields: number;
  numSamples: number; // TODO(savv): deprecate in favor of stats.numSamples.
}

export function harvestToAggregates(
  unit: YieldUnit,
  harvest_crop: null | HarvestCrop,
  farm: Farm,
  policy: null | Policy,
  field: null | Field,
  harvest: Harvest,
  samples: Sample[],
  insuredYield: null | YieldValue,
): AggregatedHarvest {
  // Treat detached samples as belonging to a "virtual" field of area equal to harvest area or sum of all affected areas
  if (!field) {
    const notAnOverrideSample = (x: Sample) => !isFarmHarvest(harvest);
    const nonOverrideSamples = samples.filter(notAnOverrideSample);
    const areaHa = getTotalAreaInHectares(harvest, nonOverrideSamples);
    const {data, ...stats} = calculateYieldStats(
      farm.user_group,
      unit,
      areaHa != null
        ? {
            unit: 'hectares',
            val: areaHa,
          }
        : null,
      harvest_crop,
      insuredYield,
      nonOverrideSamples,
    );
    return {
      stats,
      data,
      harvest,
      farm,
      policy,
      // Return the original samples, including those with missing yield/loss information.
      samples: samples.filter(notAnOverrideSample),
      field: null,
    };
  } else {
    // TODO(seb): Prioritize harvest_area over field_area.
    //  The best area metric is generally the harvest_area, followed by the field_area. As we are on the field-level
    //  here, we cannot fall back on the insured_area which will be only defined on the farm-level.
    const {data, ...stats} = calculateYieldStats(
      farm.user_group,
      unit,
      field.field_area,
      harvest_crop,
      insuredYield,
      samples,
    );
    return {
      stats,
      data,
      harvest,
      field,
      farm,
      policy,
      // Return the original samples, including those with missing yield/loss information.
      samples,
    };
  }
}

// Remove any samples older than 14 days compared to the latest sample date found.
// Returns a shallow copy of DbState.
export function applyLegacy14DayRule(samples: Sample[]): Sample[];
export function applyLegacy14DayRule(dbState: DbState): DbState;
export function applyLegacy14DayRule(input: DbState | Sample[]): DbState | Sample[] {
  const sampleDateCutoff =
    (Object.values(Array.isArray(input) ? input : input.sample)
      .map(s => new Date(s.sample_date ?? 0).getTime())
      .reduce(...aggregate.max) ?? 0) -
    14 * 24 * 60 * 60 * 1000;
  const withinDateCutoff = (sample: Sample) => new Date(sample.sample_date ?? 0).getTime() >= sampleDateCutoff;
  return Array.isArray(input)
    ? input.filter(withinDateCutoff)
    : {
        ...input,
        sample: Object.fromEntries(Object.entries(input.sample).filter(([, sample]) => withinDateCutoff(sample))),
      };
}

export function getHarvestAnalytics<T>(
  units: UnitSystem,
  dbState: Pick<DbState, 'farm' | 'field' | 'harvest' | 'policy' | 'sample'>,
  crops: IndexedCrops,
  fn: (h: Harvest, dbState: Pick<DbState, 'farm' | 'field' | 'harvest' | 'policy' | 'sample'>) => T,
): (T & HarvestAnalytics)[] {
  const {field: fieldObj, harvest: harvestObj} = dbState;
  const allHarvests: {[key: string]: AggregatedHarvest[]} = {};
  const harvestIdToKey: {[harvest_id: string]: string} = {};
  const harvestIdToSamples = getSamplesByHarvestId(dbState.sample);

  // Go over each Harvest, aggregate its stats, and group the Harvest under its grouping key.
  for (const harvest_id in harvestObj) {
    const harvest = harvestObj[harvest_id];
    const farm_id = harvestObj[harvest_id].farm_id ?? fieldObj[harvestObj[harvest_id].field_id!]?.farm_id;
    const farmHarvests = Object.values(harvestObj).filter(
      h => h.farm_id == farm_id && harvestAggregationKeyEq(h, harvest),
    );
    const inputHarvest = getInputHarvest(farmHarvests);
    const key = fn(harvest, dbState);
    if (key == null) {
      continue;
    }
    const keyStr = JSON.stringify(key);
    harvestIdToKey[harvest_id] = keyStr;

    if (!allHarvests[keyStr]) {
      allHarvests[keyStr] = [];
    }

    const cropFamily = getBaseCrop(crops, harvest.crop_id);
    const samples = harvestIdToSamples[harvest.harvest_id] ?? [];
    // Determine the calculation unit based on all relevant samples.
    const hasOverrideSample = isFarmHarvest(harvest) && samples.length > 0;
    const harvestSamples = Object.values(dbState.sample).filter(sample => {
      const sampleHarvest = dbState.harvest[sample.harvest_id];
      const override = isFarmHarvest(sampleHarvest);
      return harvestAggregationKeyEq(sampleHarvest, harvest) && override == hasOverrideSample;
    });
    const unit = getAggregationUnit(units, cropFamily, inputHarvest?.insured_yield ?? null, harvestSamples);
    allHarvests[keyStr].push(
      harvestToAggregates(
        unit,
        cropFamily,
        dbState.farm[harvest.field_id ? dbState.field[harvest.field_id].farm_id : harvest.farm_id!],
        harvest.policy_id == null ? null : dbState.policy[harvest.policy_id],
        harvest.field_id ? (fieldObj[harvest.field_id] as Field) : null,
        harvest,
        samples,
        inputHarvest?.insured_yield ?? null,
      ),
    );
  }

  const result: (T & HarvestAnalytics)[] = [];

  for (const k in allHarvests) {
    const key: T = JSON.parse(k);
    const harvests = allHarvests[k];
    const harvest_crop = getBaseCrop(crops, harvests[0]?.harvest?.crop_id);
    const farmOverrideSample = getFarmOverrideSample(
      harvests.map(aggregatedHarvest => aggregatedHarvest.harvest),
      harvestIdToSamples,
    );
    const res = {
      ...key,
      ...reaggregateHarvestAnalytics(units, harvest_crop, harvests, farmOverrideSample),
      costs: getCosts(harvests),
    };
    result.push(res);
  }

  return result;
}

// IMPORTANT: the logic here needs to match the logic in get_farm_harvest_rows, for getting the farm_override_sample
export function getFarmOverrideSample(
  harvests: Harvest[],
  harvestIdToSamples: {
    [harvest_id: string]: undefined | Sample[];
  },
) {
  let farmOverrideSample = null;
  for (const harvest of harvests) {
    const samples = harvestIdToSamples[harvest.harvest_id];
    if (!isFarmHarvest(harvest) || !samples?.length) {
      continue;
    }
    samples.sort((a, b) => cmp(b.sample_date || '', a.sample_date || ''));
    farmOverrideSample = samples[0];
  }
  return farmOverrideSample;
}

function reaggregateHarvestAnalytics(
  units: UnitSystem,
  harvest_crop: null | HarvestCrop,
  harvests: AggregatedHarvest[],
  farmOverrideSample: null | Sample,
): HarvestAnalytics {
  const harvestStats: HarvestYieldStats[] = harvests.map(x => ({
    ...x.stats,
    isFarmHarvest: !x.field?.field_id && !x.harvest.external_harvest_id,
  }));
  const unsampledFieldsAreUndamaged = unsampledFieldsAreUndamagedEnabled(harvests[0]?.farm?.user_group);
  const inputHarvest = getInputHarvest(harvests.map(x => x.harvest));
  return {
    overrideSample: farmOverrideSample,
    harvests,
    numFields: harvests.filter(x => !!x.field).length,
    numSamples: (harvests.map(x => x.samples.length).reduce(...aggregate.sum) ?? 0) + (farmOverrideSample ? 1 : 0),
    costs: {},
    stats: aggregateFarmYieldStats(
      units,
      harvest_crop,
      harvestStats,
      inputHarvest?.insured_yield ?? null,
      farmOverrideSample,
      unsampledFieldsAreUndamaged,
    ),
  };
}

export const aggregateCostValues = (costs: Omit<Cost, 'cost_type'>[]) => {
  // If there is a single cost entry, return that cost directly.
  // Typically, most samples contain only one cost.
  // This also handles cases where only cost_per_area is provided (with cost and cost_area as null).
  if (costs.length == 1) {
    return costs[0];
  }

  // All costs must have the same unit for cost_area
  const costAreaUnits = Array.from(new Set(filterNulls((costs ?? []).map(x => x.cost_area?.unit))));
  const aggCostArea: null | AreaValue =
    costAreaUnits.length == 1
      ? {
          unit: costAreaUnits[0] as AreaUnit,
          val: costs.map(x => x.cost_area?.val ?? 0).reduce(...aggregate.sum) || 0,
        }
      : null;

  const aggCost = costs.map(x => x.cost).reduce(...aggregate.sum);
  const aggCostPerArea = aggCostArea && aggCostArea.val > 0 && aggCost != null ? aggCost / aggCostArea.val : null;

  return {
    cost: aggCost,
    cost_area: aggCostArea,
    cost_per_area: aggCostPerArea,
  };
};

export function getCosts(harvests: AggregatedHarvest[]) {
  let costsData = {} as CostsData;

  for (const h of harvests) {
    const harvestCosts = {} as Record<CostType, Omit<Cost, 'cost_type'>[]>;

    for (const sample of h.samples) {
      for (const cost of sample.costs) {
        if (!!cost.cost_type) {
          if (!(cost.cost_type in harvestCosts)) {
            harvestCosts[cost.cost_type] = [];
          }
          harvestCosts[cost.cost_type].push({
            cost: cost.cost,
            cost_area: cost.cost_area,
            cost_per_area: cost.cost_per_area,
          });
        }
      }
    }

    for (const costType in harvestCosts) {
      if (!(costType in costsData)) {
        costsData[costType as CostType] = {};
      }

      const costs = harvestCosts[costType as CostType];
      costsData[costType as CostType]![h.field ? h.field.field_id : '-'] = aggregateCostValues(costs);
    }
  }

  return costsData;
}

export function getSharedValues(
  units: UnitSystem,
  farmHarvest: HarvestAnalytics & HarvestDesc,
): {
  insured_price: null | UnitPriceValue;
  insured_area: null | AreaValue;
  deductibleTexts: string[];
  historicalYields: [null | number, null | number, null | number, null | number, null | number];
  meanHistoricalYield: null | number;
  updatedYields: [null | number, null | number, null | number, null | number, null | number];
} {
  const inputHarvest = getInputHarvest(farmHarvest);
  const inputCustomColumns = inputHarvest && new HarvestCustomColumns(inputHarvest.custom_columns);

  const insured_price = inputHarvest?.insured_price ?? null;
  const insured_area = inputHarvest?.insured_area ?? null;

  // Groupama provides the historical yields AND the mean historical yield upon mission import.
  // updatedYields only exist in the context of Groupama, allowing them to override the imported historical yields.
  if (
    inputCustomColumns?.grpmPastYields ||
    inputCustomColumns?.grpmUpdatedYields ||
    inputCustomColumns?.grpmAvgYield ||
    inputCustomColumns?.grpmFranchises
  ) {
    const historicalYields = inputCustomColumns.grpmPastYields ?? [null, null, null, null, null];
    const updatedYields = inputCustomColumns.grpmUpdatedYields ?? [null, null, null, null, null];
    const meanHistoricalYield = inputCustomColumns.grpmAvgYield ?? null;
    const deductibleTexts = inputCustomColumns.grpmFranchises ?? [];
    return {
      insured_price,
      insured_area,
      deductibleTexts,
      historicalYields,
      meanHistoricalYield,
      updatedYields,
    };
  }

  if (inputCustomColumns?.etl?.franchises) {
    const deductibleTexts = inputCustomColumns.etl?.franchises ?? [];
    return {
      insured_price,
      insured_area,
      deductibleTexts,
      // Below values are not defined for this customer.
      historicalYields: [null, null, null, null, null],
      meanHistoricalYield: null,
      updatedYields: [null, null, null, null, null],
    };
  }

  return {
    insured_price,
    insured_area,
    // Below values are only defined for some customers.
    deductibleTexts: [],
    historicalYields: [null, null, null, null, null],
    meanHistoricalYield: null, // Would be the average of historicalYields.
    updatedYields: [null, null, null, null, null],
  };
}

type HarvestYieldStats = YieldStats & {isFarmHarvest: boolean};

export function aggregateFarmYieldStats(
  units: UnitSystem,
  harvest_crop: null | HarvestCrop,
  harvestStats: HarvestYieldStats[],
  insuredYield: null | YieldValue,
  farmOverrideSample: null | Sample,
  unsampledFieldsAreUndamaged: boolean,
): YieldStats {
  const fieldHarvestStats = harvestStats.filter(
    x => !x.isFarmHarvest && (unsampledFieldsAreUndamaged || x.numInputSamples > 0),
  );
  const fieldAreas = fieldHarvestStats.map(x => x.areaHa);
  // use simple average if no harvest has an area
  const fieldAreaWeights = filterNulls(fieldAreas).length == 0 ? undefined : fieldAreas.map(x => x ?? 0);
  const yieldUnit = getIdealYieldUnit(units, harvest_crop);

  // Calculate the estimated and feasible yield as a weighted average of each field's value.
  // We do not aggregate the estimated yield, if:
  // - none of the fields have an estimated yield or none of the fields have any samples with yield values
  // - unsampledFieldsAreUndamaged is false and some estimates are missing (as we wouldn't know what estimate to assign
  //   to those fields, if calculateYieldStats wasn't already able to derive it!)
  // In those cases, we may still be able to derive the estimated yield as feasible - loss below.
  const calcEstimated =
    fieldHarvestStats.some(x => x.estimated != null) &&
    // Note that this logic relies on excludeSamplesWithEmptyYieldOrLossInAggregation, because it affects
    // the number of samples.
    fieldHarvestStats.some(x => x.numInputSamples > 0) &&
    (unsampledFieldsAreUndamaged || fieldHarvestStats.every(x => x.estimated != null));
  let estimated = !calcEstimated
    ? null
    : getAvg(
        fieldHarvestStats.map(x => (x.estimated == null ? insuredYield : {unit: x.unit, val: x.estimated})),
        yieldUnit,
        harvest_crop,
        fieldAreaWeights ?? null,
      );
  let feasible =
    getAvg(
      fieldHarvestStats.map(x => (x.feasible == null ? insuredYield : {unit: x.unit, val: x.feasible})),
      yieldUnit,
      harvest_crop,
      fieldAreaWeights ?? null,
    ) ?? insuredYield;
  let totalLoss = getAvg(
    fieldHarvestStats.map(x => (x.totalLoss == null ? null : {unit: x.unit, val: x.totalLoss})),
    yieldUnit,
    harvest_crop,
    fieldAreaWeights ?? null,
  );

  if (farmOverrideSample && farmOverrideSample.estimated_yield != null) {
    estimated = farmOverrideSample.estimated_yield;
  }
  if (farmOverrideSample && farmOverrideSample.feasible_yield) {
    feasible = farmOverrideSample.feasible_yield;
  }

  let areaHa: null | number = fieldHarvestStats.map(h => h.areaHa).reduce(...aggregate.sum) ?? 0;
  if (!areaHa) {
    areaHa = harvestStats
      .filter(x => x.isFarmHarvest)
      .map(h => h.areaHa)
      .reduce(...aggregate.sum);
  }
  const sampledAreaHa = harvestStats
    // We cannot filter out farm harvests here, as they may have detached/subplot samples attached.
    .map(h => h.sampledAreaHa)
    .reduce(...aggregate.sum);

  if (!feasible) {
    feasible = deriveFeasible(estimated, totalLoss, harvest_crop) ?? null;
  }
  if (!estimated) {
    estimated = deriveEstimated(feasible, totalLoss, harvest_crop) ?? null;
  }

  // Determine the yield unit as late as possible, once we have determined the estimated and feasible yields.
  const yieldUnits: Set<YieldUnit> = new Set([estimated?.unit, feasible?.unit, totalLoss?.unit].filter(remove.nulls));
  if (feasible) {
    // Avoid returning percent results, because it becomes impossible to return estimated and feasible yields.
    yieldUnits.delete('percent');
  }
  const unit = yieldUnits.size == 1 ? Array.from(yieldUnits)[0] : yieldUnit;

  if (!totalLoss) {
    totalLoss = deriveTotalLoss(feasible, estimated, harvest_crop, unit == 'percent') ?? null;
  }

  const farmStats: YieldStats = {
    unit,
    areaHa,
    sampledAreaHa,
    estimated: convertYield(unit, estimated, harvest_crop)?.val ?? null,
    feasible: convertYield(unit, feasible, harvest_crop)?.val ?? null,
    totalLoss: convertLossToUnit(unit, harvest_crop, feasible, totalLoss)?.val ?? null,
    lossByCause: {},
    numInputSamples: harvestStats.map(x => x.numInputSamples).reduce(...aggregate.sum) ?? 0,
  };

  const lossCauses: Set<LossCause> = new Set(
    fieldHarvestStats.map(x => Object.keys(x.lossByCause ?? {}) as LossCause[]).flat(1),
  );
  if (lossCauses.size > 0) {
    for (const loss_cause of lossCauses) {
      const lossesForCause: YieldValue[] = fieldHarvestStats.map(x => ({
        unit: x.unit,
        val: x.lossByCause?.[loss_cause] ?? 0,
      }));
      const avgLoss = convertLossToUnit(
        unit,
        harvest_crop,
        feasible,
        getAvg(lossesForCause, yieldUnit, null, fieldAreaWeights ?? null),
      );
      const lossByCause = farmStats.lossByCause;
      if (avgLoss && lossByCause) {
        lossByCause[loss_cause] = avgLoss.val;
      }
    }
  }
  // TODO(savv): else: set lossByCause back to null?

  if (farmOverrideSample && farmOverrideSample.losses && farmOverrideSample.losses.length > 0) {
    const losses = getSampleLosses(farmOverrideSample.losses, unit, harvest_crop, farmStats.feasible);
    // Override sample can have losses with empty loss value (e.g. by toggling recognized), which will return an empty losses object.
    // We don't want to override lossByCause and totalLoss in that case.
    // Question occurs on why would we keep the losses of the overridden samples, when the override sample does not contain any losses.
    // Override sample logic is due for revamp and we will address the question there https://app.asana.com/0/1204441850448359/1206947953712356
    if (losses && Object.keys(losses).length) {
      farmStats.lossByCause = losses;
      farmStats.totalLoss = Object.values(losses).reduce(...aggregate.sum) ?? 0;
    }
  }

  return farmStats;
}

export function aggregateFarmHarvestRows(crops: IndexedCrops, farmHarvests: GetFarmHarvestRowsRow) {
  const farmStats: HarvestYieldStats[] = filterNulls(farmHarvests.harvest_data ?? []).map(h => ({
    unit:
      h.loss_by_cause?.length && h.loss_by_cause.every(x => x.loss?.unit == 'percent') ? 'percent' : 'tons-per-hectare',
    areaHa: h.yield_area_ha,
    insured: h.insured_yield_t_ha,
    feasible: h.feasible_yield_t_ha,
    estimated: h.harvest_yield_t_ha,
    totalLoss: h.total_loss_yield_t_ha,
    lossByCause: Object.fromEntries(h.loss_by_cause?.map(x => [x.loss_cause, x.loss?.val]) ?? []),
    sampledAreaHa: h.sampled_area_ha,
    isFarmHarvest: !h.field_id,
    numInputSamples: h.sample_dates?.filter(remove.falsy).length ?? 0,
  }));

  const insuredYieldTHa = farmHarvests.harvest_data?.find(h => h?.insured_yield_t_ha)?.insured_yield_t_ha;
  const unsampledFieldsAreUndamaged = unsampledFieldsAreUndamagedEnabled(farmHarvests?.farm?.user_group ?? null);
  return aggregateFarmYieldStats(
    metricUnitSystem, // Do aggregations in t/ha; the exporter will take care of converting to the correct unit.
    getBaseCrop(crops, farmHarvests.crop_id),
    farmStats,
    !insuredYieldTHa ? null : {unit: 'tons-per-hectare', val: insuredYieldTHa},
    farmHarvests.farm_override_sample,
    unsampledFieldsAreUndamaged,
  );
}

// TODO(seb): Better define harvestData aggregations for policies.
export function aggregatePolicyHarvestData(crops: IndexedCrops, harvests: HarvestData[], cropId: string): YieldStats {
  const farmStats: HarvestYieldStats[] = harvests.map(h => ({
    unit:
      h.loss_by_cause?.length && h.loss_by_cause.every(x => x?.loss?.unit == 'percent')
        ? 'percent'
        : 'tons-per-hectare',
    areaHa: h.yield_area_ha,
    feasible: h.feasible_yield_t_ha,
    estimated: h.harvest_yield_t_ha,
    totalLoss: h.total_loss_yield_t_ha,
    lossByCause: Object.fromEntries(h.loss_by_cause?.map(x => [x?.loss_cause, x?.loss?.val]) ?? []),
    sampledAreaHa: h.sampled_area_ha,
    isFarmHarvest: !h.field_id,
    numInputSamples: h.sample_dates?.filter(remove.falsy).length ?? 0,
  }));
  const insuredYieldTHa = harvests.find(h => h?.insured_yield_t_ha)?.insured_yield_t_ha;
  const unsampledFieldsAreUndamaged = unsampledFieldsAreUndamagedEnabled(harvests[0]?.user_group ?? null);
  return aggregateFarmYieldStats(
    metricUnitSystem,
    getBaseCrop(crops, cropId),
    farmStats,
    !insuredYieldTHa ? null : {unit: 'tons-per-hectare', val: insuredYieldTHa},
    null,
    unsampledFieldsAreUndamaged,
  );
}

// Return the total affected area for a given farm level harvest, and it's associated samples.
// Will return null for field-level harvests.
// Preferences for the source of the total affected area: harvest_area > sum(sample_area) > insured_area > null
function getTotalAreaInHectares(harvest: Harvest, samples: Sample[]) {
  if (harvest.field_id) {
    return null; // Total area is not defined on field-level harvests.
  }
  const harvestArea = convertArea('hectares', harvest.harvest_area);
  if (harvestArea) {
    return harvestArea.val;
  }
  const sampleAreas = samples
    .map(x => convertArea('hectares', x.sample_area))
    .filter(remove.nulls)
    .map(x => x.val);
  if (sampleAreas.length > 0) {
    return sampleAreas.reduce(...aggregate.sum) ?? 0;
  }
  const insuredArea = convertArea('hectares', harvest.insured_area);
  if (insuredArea) {
    return insuredArea.val;
  }
  return null;
}

export function getInputHarvest(farmHarvest: (HarvestAnalytics & HarvestDesc) | Harvest[]): null | Harvest {
  const harvests = 'harvests' in farmHarvest ? farmHarvest.harvests.map(x => x.harvest) : farmHarvest;
  const inputHarvests = harvests.filter(x => x.farm_id && x.insured_yield);
  // Fallback to any harvest if we can't find an imported one.
  return inputHarvests.find(isImportedHarvest) ?? inputHarvests[0] ?? null;
}

export function isImportedHarvest(harvest: null | Harvest): boolean {
  return (
    !!harvest?.metadata?.ptf_imported_at ||
    !!harvest?.metadata?.ptf_updated_at ||
    !!new HarvestCustomColumns(harvest?.custom_columns).grpmCulture ||
    !!harvest?.metadata?.Culture
  );
}

export function getAggregationUnit(
  units: UnitSystem,
  cropFamily: null | HarvestCrop,
  insured_yield: null | YieldValue,
  samples: Sample[],
): YieldUnit {
  // First, all yield values are checked and unified to one type of unit, to simplify all downstream calculations; if
  // all data uses the same unit, then it is kept; otherwise a fallback unit is selected using the UnitSystem.
  // Area values are always converted to hectares, as they are only used as weights in this function.
  const sampleUnits: YieldUnit[] = unique(
    samples.flatMap(x => [x.estimated_yield?.unit, x.feasible_yield?.unit]).filter(remove.nulls),
  );
  if (sampleUnits.length == 1) {
    return sampleUnits[0];
  } else if (sampleUnits.length > 1 && insured_yield) {
    // We only fall back to the insured_yield unit if we need to disambiguate between multiple units.
    return insured_yield.unit;
  } else if (sampleUnits.length == 0) {
    // Special case - if this is a percent loss sample, with no other values, set the unit to 'percent'.
    const lossUnits: YieldUnit[] = unique(
      samples.flatMap(sample => sample.losses?.map(loss => loss.loss?.unit)).filter(remove.nulls),
    );
    if (insured_yield) {
      return insured_yield.unit;
    } else if (lossUnits.length == 1) {
      return lossUnits[0];
    }
  }

  return getIdealYieldUnit(units, cropFamily);
}
