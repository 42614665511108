import {createSelector} from 'reselect';
import {UnreachableCaseError} from 'ts-essentials';
import {
  aaHiddenName,
  assAllGroups,
  brasileiraRuralMgaHiddenName,
  europeGroups,
  grAllGroups,
  grAllGroupsExGan,
  grHiddenName,
  internalAdminGroup,
  latamGroups,
  mdaAllGroups,
  mgmtAllGroups,
  paAllGroups,
  paHiddenName,
  txAllGroups,
  txHiddenName,
} from './constants/group-consts';
import {InteryieldCropIds} from './models/interyield';
import {getUserGroupSet} from './selectors/userGroups';
import {remove} from './util/arr-util';
import {getUserGroupType} from './util/user-group-util';

// UserGroupsWhitelist enables a feature for all mentioned user groups *and their descendants*.
interface UserGroupsWhitelist {
  type: 'user-group-whitelist';
  userGroups: string[];
}

// Enables a feature only if the user is *exclusively* in the list groups. In other words, after excluding country +
// utility groups, you are not allowed to have any other groups besides `userGroups`. See also tests for more clarity
// on the possible cases.
interface UserGroupsExclusive {
  type: 'user-groups-exclusive';
  userGroups: string[];
}

// A UserGroupSet is a generic way to map feature flags to concrete user groups.
type UserGroupSet = UserGroupsExclusive | UserGroupsWhitelist;

// A mapping from a feature flag to a set of user groups for which this flag is enabled.
const Flags = [
  'hail',
  'interyield',
  'visitListWeb',
  'visitListApp',
  'allVisitReports',
  'signaturePerCrop',
  'costs',
  'customColumnsBrazil',
  'gpsTracking',
  'fieldScoring',
  'startingYield',
  'import',
  'marSamplingLocations',
  'mergeEntities',
  'hasTelepac',
  'portfolioReport',
  'regionalPredictedYield',
  'claims',
  'exoYieldCalculator',
  'hideEditingFunctionality',
  'offlineRegionNames',
  'invalidShapeWarning',
  'subsidyConfig',
  'hasSomeBrazilUserGroup',
  'webPolicyEditor',
  'showPredictedYield',
  'historicalYields',
] as const;
export type Flags = (typeof Flags)[number];
type FeatureFlags = {[P in Flags]: UserGroupSet};

export const frenchClaimGroups = [...grAllGroups, ...paAllGroups, ...txAllGroups];
export const claimModeGroups = [...internalAdminGroup, ...latamGroups, ...frenchClaimGroups, 'tester', 'pzu'];
export const visitListWebGroups = [
  ...internalAdminGroup,
  ...latamGroups,
  ...frenchClaimGroups,
  'tester',
  'pzu',
  aaHiddenName,
  'mobiliar',
  'sancor-argentina',
  'sancor-uruguay',
];
export const visitListAppGroups = visitListWebGroups.filter(remove.allOf(frenchClaimGroups));
export const customClaimStatusGroup = [...frenchClaimGroups];

const featureFlags: FeatureFlags = {
  hail: {type: 'user-group-whitelist', userGroups: [...europeGroups, 'USA', 'CAN']},
  interyield: {
    type: 'user-group-whitelist',
    userGroups: [],
  },
  visitListWeb: {
    type: 'user-group-whitelist',
    userGroups: [...visitListWebGroups, 'FRA', 'ITA'],
  },
  visitListApp: {
    type: 'user-group-whitelist',
    userGroups: [...visitListAppGroups, 'ITA'],
  },
  // Only show all visit reports to users part of client management
  allVisitReports: {
    type: 'user-group-whitelist',
    userGroups: mgmtAllGroups,
  },
  signaturePerCrop: {type: 'user-group-whitelist', userGroups: grAllGroups},
  startingYield: {type: 'user-group-whitelist', userGroups: [...paAllGroups, ...txAllGroups]},

  // Note: this flag is also used for harvest.insuredPrice.
  costs: {type: 'user-group-whitelist', userGroups: [...internalAdminGroup, 'tester', 'FRA']},
  customColumnsBrazil: {type: 'user-group-whitelist', userGroups: ['sombreroseguros']},
  gpsTracking: {type: 'user-group-whitelist', userGroups: [...internalAdminGroup]},
  fieldScoring: {
    type: 'user-group-whitelist',
    userGroups: [...internalAdminGroup, 'tester', 'demo', 'BRA', 'URY', 'ARG', 'PRY'],
  },
  import: {
    type: 'user-group-whitelist',
    userGroups: [...internalAdminGroup, 'demo', 'FRA', 'sancor-argentina', 'sancor-uruguay'],
  },
  marSamplingLocations: {type: 'user-group-whitelist', userGroups: ['MAR']},
  mergeEntities: {
    type: 'user-group-whitelist',
    userGroups: [...internalAdminGroup, txHiddenName, `${grHiddenName}-gpvl`, paHiddenName],
  },
  hasTelepac: {type: 'user-group-whitelist', userGroups: [...internalAdminGroup, 'tester', 'FRA']},
  portfolioReport: {type: 'user-group-whitelist', userGroups: [...internalAdminGroup]},
  regionalPredictedYield: {type: 'user-group-whitelist', userGroups: [...internalAdminGroup, 'liberty']},
  claims: {type: 'user-group-whitelist', userGroups: [...claimModeGroups]},
  exoYieldCalculator: {type: 'user-group-whitelist', userGroups: [...internalAdminGroup, 'FRA', 'ITA', 'MAR']},
  hideEditingFunctionality: {
    type: 'user-groups-exclusive',
    userGroups: ['disabled-for-tests', 'msamlin', 'msamlin-head'],
  },
  offlineRegionNames: {
    type: 'user-group-whitelist',
    userGroups: [...internalAdminGroup, ...paAllGroups],
  },
  invalidShapeWarning: {
    type: 'user-group-whitelist',
    userGroups: [...internalAdminGroup],
  },
  subsidyConfig: {
    type: 'user-group-whitelist',
    userGroups: [...internalAdminGroup, brasileiraRuralMgaHiddenName],
  },
  hasSomeBrazilUserGroup: {
    type: 'user-group-whitelist',
    userGroups: ['BRA'],
  },
  webPolicyEditor: {
    type: 'user-group-whitelist',
    userGroups: [...internalAdminGroup],
  },
  showPredictedYield: {
    type: 'user-group-whitelist',
    userGroups: [...internalAdminGroup],
  },
  historicalYields: {
    type: 'user-group-whitelist',
    userGroups: [...internalAdminGroup, ...latamGroups],
  },
};

const interyieldAdminGroup = [...internalAdminGroup, 'tester'];
export const interyieldCropUserGroups: {[P in InteryieldCropIds]: string[]} = {
  'wheat-winter': [...interyieldAdminGroup, ...europeGroups, 'MAR'],
  'barley-winter': [...interyieldAdminGroup, ...europeGroups, 'MAR'],
  'wheat-hard-winter': [...interyieldAdminGroup, ...europeGroups, 'MAR'],
  'triticale-winter': [...interyieldAdminGroup, ...europeGroups, 'MAR'],
  // TODO(karol): Add europe and MAR user groups once the pipeline calculates interyield for corn.
  'corn-grain-spring': [...interyieldAdminGroup],
  'soybeans-first-crop': [...interyieldAdminGroup, 'GMPCI-Donnelly'],
};

export function checkRuleWithTransitiveGroups(groups: Set<string>, rule: UserGroupSet) {
  if (groups.has('test')) {
    // For unit tests, all flags are on by default, to maximize the amount of code paths that get exercised.
    // We exclude those rules including the sentinel group non-test, which can be used for "disabling" flags.
    return !rule.userGroups.includes('disabled-for-tests');
  }

  if (rule.type == 'user-group-whitelist') {
    return rule.userGroups.some(x => groups.has(x));
  } else if (rule.type == 'user-groups-exclusive') {
    const relevantUserGroups = Array.from(groups).filter(x => !['country', 'utility'].includes(getUserGroupType(x)));
    // Every group of the user must be in the list of allowed groups.
    return relevantUserGroups.length > 0 && relevantUserGroups.every(x => rule.userGroups.includes(x));
  } else {
    console.error(new UnreachableCaseError(rule));
    return false;
  }
}

export const getEnabledFlags = createSelector(getUserGroupSet, userGroups => {
  const enabled = new Set<Flags>();
  for (const flag of Flags) {
    if (checkRuleWithTransitiveGroups(userGroups, featureFlags[flag])) {
      enabled.add(flag);
    }
  }

  return enabled;
});

export function estimatedYieldAboveLossesEnabled(user_group: null | string): boolean {
  return !!user_group && [...grAllGroups, ...paAllGroups].includes(user_group);
}

export function showFarmReferenceInVisitConfigScreenEnabled(user_group: null | string): boolean {
  return !!user_group && grAllGroups.includes(user_group);
}

export function showFarmerDetailsInVisitConfigScreenEnabled(user_group: null | string): boolean {
  return !!user_group && grAllGroups.includes(user_group);
}

export function showGrpmCustomSectionEnabled(user_group: null | string): boolean {
  return !!user_group && grAllGroups.includes(user_group);
}

export function customGrpmChecksEnabled(user_group: null | string): boolean {
  return !!user_group && grAllGroups.includes(user_group);
}

export function visitReportLandscapeEnabled(user_group: null | string): boolean {
  return !!user_group && grAllGroups.includes(user_group);
}

export function showCustomGrpmReportEnabled(user_group: null | string): boolean {
  return !!user_group && grAllGroups.includes(user_group);
}

export function showFeasibleYieldWarning(user_group: null | string): boolean {
  return !!user_group && grAllGroups.includes(user_group);
}

export function showCustomPaReportEnabled(user_group: null | string): boolean {
  return !!user_group && [...paAllGroups].includes(user_group);
}

export function lossAmountInPercentEnabled(user_group: null | string): boolean {
  // By default, we allow to specify the loss amount in percent or t/ha; except for Groupama who would rather have it
  // only in t/ha.
  return !grAllGroupsExGan.includes(user_group!);
}

export function sampleFormImageRequiredEnabled(user_group: null | string): boolean {
  return !!user_group && mdaAllGroups.includes(user_group);
}

// If true, when we aggregate data for that farm/policy, we consider unvisited fields to have the same yield as the
// insured yield at the farm level. This is something we anyway do with the insured yield at the field harvest level,
// but had to disable because Pacifica was using it in this certain way.
export function unsampledFieldsAreUndamagedEnabled(user_group: null | string): boolean {
  return !!user_group && !paAllGroups.includes(user_group);
}

// Whether to exclude samples that have no yield values from aggregation.
// TODO(savv): consider removing this flag and enabling for everybody.
export function excludeSamplesWithEmptyYieldOrLossInAggregationEnabled(user_group: null | string): boolean {
  return !!user_group && grAllGroups.includes(user_group);
}

export function disableClaimEditingFromVisit(user_group: null | string): boolean {
  return !!user_group && grAllGroups.includes(user_group);
}

// If true, the harvest.insured_yield field should be read only across the harvest experiences.
export function readOnlyInsuredYieldHarvestField(user_group: null | string): boolean {
  return !!user_group && grAllGroups.includes(user_group);
}

// If true, allows selecting visit action type on the visit report screen.
// The value is set in the visit grpm custom columns.
export function visitActionSelectionEnabled(user_group: null | string): boolean {
  return !!user_group && grAllGroups.includes(user_group);
}

export function showClaimManager(user_group: null | string): boolean {
  // Everyone but gr + pa.
  return !!user_group && ![...grAllGroups, ...paAllGroups].includes(user_group);
}

export function allowVisitCopy(user_group: null | string): boolean {
  // Disabled for frenchClaimGroups, as they should only create new visits from claims.
  return !!user_group && ![...frenchClaimGroups].includes(user_group);
}

export function showClaimCropInformation(user_group: null | string): boolean {
  // Everyone but gr + pa.
  return !!user_group && ![...frenchClaimGroups].includes(user_group);
}

// If true, shows a warning on submitting the sample form, if there are pending losses to allocate.
export function showSampleFormPendingYieldLossesAllocation(user_group: null | string): boolean {
  return !!user_group && grAllGroups.includes(user_group);
}

// If true, additional attributes are rendered on the policy web editor and specific server-side validation is run.
export function isBrazilPolicy(user_group: null | string): boolean {
  return !!user_group && user_group === brasileiraRuralMgaHiddenName;
}

// If true, policy status column is rendered on the web policy list and in the exported Excel file.
export function policyStatusColumnEnabled(user_group: null | string): boolean {
  return !!user_group && user_group === brasileiraRuralMgaHiddenName;
}

// If true, shows a delete button on policy editor on the web.
export function deletePoliciesEnabled(user_group: null | string): boolean {
  return !!user_group && user_group !== brasileiraRuralMgaHiddenName;
}

// If true, disables policy number input on policy editor on the web. Useful when the policy number is generated and
// depends on external systems.
export function disablePolicyNumberInput(user_group: null | string): boolean {
  return !!user_group && user_group === brasileiraRuralMgaHiddenName;
}

// If true, policy download button is rendered on the policy web editor.
export function enablePolicyDownload(user_group: null | string): boolean {
  return !!user_group && user_group === brasileiraRuralMgaHiddenName;
}

// If true, policy validation endpoint is used on the policy web editor.
export function enablePolicyValidation(user_group: null | string): boolean {
  return !!user_group && user_group === brasileiraRuralMgaHiddenName;
}

// If true, policy inputs are enabled even after the status is changed to approved, rejected or policy.
export function enablePolicyInputsIgnoringStatus(user_group: null | string): boolean {
  return !!user_group && user_group !== brasileiraRuralMgaHiddenName;
}

export function showSampleAggregatedLossesWarning(user_group: null | string): boolean {
  return !!user_group && !grAllGroups.includes(user_group);
}

// Use a dedicated override sample screen to set per-crop aggregate override values.
export function hasDedicatedOverrideSampleScreen(user_group: null | string): boolean {
  return !!user_group && [...paAllGroups].includes(user_group);
}

// Customizable configuration for the override sample screen.
export function simplifiedOverrideSampleScreenConfig(
  user_group: string,
): Record<'attachments' | 'comments' | 'costs' | 'estimated_yield' | 'images' | 'losses', boolean> {
  return {
    estimated_yield: true,
    losses: true,
    costs: paAllGroups.includes(user_group),
    images: grAllGroups.includes(user_group),
    attachments: grAllGroups.includes(user_group),
    comments: [...grAllGroups, ...paAllGroups].includes(user_group),
  };
}

export function qualityLossesEnabled(user_group: null | string): boolean {
  return !!user_group && assAllGroups.includes(user_group);
}

export function showSampleFormPreRisk(user_group: null | string): boolean {
  return !!user_group && assAllGroups.includes(user_group);
}

export function showLossesToAllocate(user_group: null | string): boolean {
  return !!user_group && !paAllGroups.includes(user_group);
}
