import {
  AreaValue,
  CropCondition,
  DensityValue,
  DistanceValue,
  HarvestYear,
  LayerType,
  UnitPriceValue,
  WeightValue,
  YieldValue,
} from './interfaces';

export {AreaUnit, DensityUnit, YieldUnit} from './interfaces';

export type {
  AreaValue,
  Cost,
  DensityValue,
  DistanceValue,
  Loss,
  SampleImage,
  UnitPriceValue,
  WeightValue,
  YieldValue,
} from './interfaces';

export type ValueUnit =
  | AreaValue
  | DensityValue
  | DistanceValue
  | PriceAreaValue
  | UnitPriceValue
  | WeightValue
  | YieldValue;

export function strToLayerType(s: string): null | LayerType {
  return LayerType.includes(s as LayerType) ? (s as LayerType) : null;
}

export function strToHarvestYear(s: any): null | HarvestYear {
  return HarvestYear.includes(s as HarvestYear) ? (s as HarvestYear) : null;
}

export type DbFields = {
  added_on: string;
  added_by: string;
  updated_at: string;
};

export type OmitDbColumns<T> = Omit<T, keyof DbFields>;

export const OrderedCropCondition = [
  'very_good',
  'good',
  'normal',
  'poor',
  'very_poor',
  'harvested',
  'not_planted',
] as const;
type CropConditionCheck = CropCondition extends (typeof OrderedCropCondition)[number] ? null : never;
// noinspection JSUnusedLocalSymbols
const cropConditionCheck: CropConditionCheck = null;

export type Uuid = string;

export type NumRange = Range<number>;
export type DateRange = Range<string>;

export interface Range<T> {
  begin: T;
  end: T;
  bounds: string;
}

export const PriceAreaUnit = ['eur-per-hectare', 'brl-per-hectare'] as const;
export type PriceAreaUnit = (typeof PriceAreaUnit)[number];

export const Currency = ['EUR', 'BRL'] as const;
export type Currency = (typeof Currency)[number];

export interface PriceAreaValue {
  val: number;
  unit: PriceAreaUnit;
}

// Sandy soil, with clay content between 10% and 15%
// Average textured soil, with clay content between 15% and 35%
// Clay soil, with a clay content greater than 35%
export const SoilType = ['sandy', 'average', 'clay'] as const;
export type SoilType = (typeof SoilType)[number];
