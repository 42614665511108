import {UnreachableCaseError} from 'ts-essentials';
import {Claim, ClaimDamage, Farm, Field, Harvest, Policy, Sample, Visit} from '../models/interfaces';
import {
  EntitySqlTransportFormat,
  EntityType,
  TableName,
  TransportOptions,
  claimDamageToTransport,
  claimToTransport,
  farmToTransport,
  fieldToTransport,
  harvestToTransport,
  policyToTransport,
  sampleToTransport,
  visitToTransport,
} from '../models/serialization';

export function getPrimaryKeyName(table: TableName): string {
  if (table === 'farm') {
    return 'farm_id';
  } else if (table === 'field') {
    return 'field_id';
  } else if (table === 'harvest') {
    return 'harvest_id';
  } else if (table === 'sample') {
    return 'sample_id';
  } else if (table === 'policy') {
    return 'policy_id';
  } else if (table === 'visit') {
    return 'visit_id';
  } else if (table === 'claim') {
    return 'claim_id';
  } else if (table === 'claim_damage') {
    return 'claim_damage_id';
  } else {
    throw new UnreachableCaseError(table);
  }
}

export function getPrimaryKey(table: TableName, entity: EntityType): string {
  return entity[getPrimaryKeyName(table) as never];
}

export function entityToTransport(
  table: 'farm',
  entity: Partial<Farm>,
  opts: TransportOptions,
): EntitySqlTransportFormat<Farm>;
export function entityToTransport(
  table: 'field',
  entity: Partial<Field>,
  opts: TransportOptions,
): EntitySqlTransportFormat<Field>;
export function entityToTransport(
  table: 'harvest',
  entity: Partial<Harvest>,
  opts: TransportOptions,
): EntitySqlTransportFormat<Harvest>;
export function entityToTransport(
  table: 'sample',
  entity: Partial<Sample>,
  opts: TransportOptions,
): EntitySqlTransportFormat<Sample>;
export function entityToTransport(
  table: 'policy',
  entity: Partial<Policy>,
  opts: TransportOptions,
): EntitySqlTransportFormat<Policy>;
export function entityToTransport(
  table: 'visit',
  entity: Partial<Visit>,
  opts: TransportOptions,
): EntitySqlTransportFormat<Visit>;
export function entityToTransport(
  table: 'claim',
  entity: Partial<Claim>,
  opts: TransportOptions,
): EntitySqlTransportFormat<Claim>;
export function entityToTransport(
  table: 'claim_damage',
  entity: Partial<ClaimDamage>,
  opts: TransportOptions,
): EntitySqlTransportFormat<ClaimDamage>;
export function entityToTransport(
  table: TableName,
  entity: Partial<EntityType>,
  opts: TransportOptions,
): EntitySqlTransportFormat<EntityType>;
export function entityToTransport(table: TableName, entity: Partial<EntityType>, opts: TransportOptions) {
  if (table === 'farm') {
    return farmToTransport(entity as Partial<Farm>, opts);
  } else if (table === 'field') {
    return fieldToTransport(entity as Partial<Field>, opts);
  } else if (table === 'harvest') {
    return harvestToTransport(entity as Partial<Harvest>, opts);
  } else if (table === 'sample') {
    return sampleToTransport(entity as Partial<Sample>, opts);
  } else if (table === 'policy') {
    return policyToTransport(entity as Partial<Policy>, opts);
  } else if (table === 'visit') {
    return visitToTransport(entity as Partial<Visit>, opts);
  } else if (table === 'claim') {
    return claimToTransport(entity as Partial<Claim>, opts);
  } else if (table === 'claim_damage') {
    return claimDamageToTransport(entity as Partial<ClaimDamage>, opts);
  } else {
    throw new Error('Invalid TableName ' + table);
  }
}
