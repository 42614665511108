import {Polygon} from 'geojson';
import XLSX from 'xlsx';
import {LngLat, parseLatlng} from '../geo';
import {HarvestYear} from '../models/interfaces';
import cmp from '../util/cmp';
import {I18nError} from '../util/err-util';
import {ImportedData, ImportedFarm, ImportedField, ImportedHarvest} from './gt-pack';

interface Row {
  user_group?: string;
  policy_number?: string;
  external_claim_id?: string;
  external_farm_id?: string;
  farm_name: string;
  address?: string;
  external_field_id?: string;
  field_location_lat?: string;
  field_location_lng?: string;
  field_shape_lat_1?: string;
  field_shape_lng_1?: string;
  // field_shape_lat_2, field_shape_lng_2, ...
  field_area?: number;
  crop_id?: string;
  irrigated?: string;
  organic?: string;
  variety?: string;
  harvest_year?: string;
  harvest_area?: number;
}

const allowedColumns: Set<keyof Row> = new Set([
  'user_group',
  'policy_number',
  'external_claim_id',
  'external_farm_id',
  'farm_name',
  'address',
  'external_field_id',
  'field_location_lat',
  'field_location_lng',
  // NOTE: excludes field_shape_lat_1, field_shape_lng_1, ... as those are part of a special check below.
  'field_area',
  'crop_id',
  'irrigated',
  'organic',
  'variety',
  'harvest_year',
  'harvest_area',
]);

function getFarmKey(row: {farm_name?: null | string; external_farm_id?: null | string}): string {
  if (row.external_farm_id) {
    return 'external_farm_id=' + row.external_farm_id;
  } else {
    return 'farm_name=' + row.farm_name;
  }
}

export function sheetToGtPack(fileContents: ArrayBuffer | Buffer) {
  const wb = XLSX.read(fileContents);
  if (!wb.Sheets['Data']) {
    throw new I18nError('NoSheetDataError');
  }

  // Note that if the user does not name the columns properly, sheet_to_json will add _<number> prefixes and still load
  // the data, but not in the expected format.
  const rows: Row[] = XLSX.utils.sheet_to_json(wb.Sheets['Data']);

  for (const row of rows) {
    const invalidKeys = new Set(Object.keys(row).filter(x => !allowedColumns.has(x as keyof Row)));
    for (let i = 1; i < 50; i++) {
      if (invalidKeys.has('field_shape_lat_' + i) && invalidKeys.has('field_shape_lng_' + i)) {
        invalidKeys.delete('field_shape_lat_' + i);
        invalidKeys.delete('field_shape_lng_' + i);
      } else {
        // If one of the two is missing, then both should be missing; and we should stop looking for more keys as we
        // don't allow for breaks in the sequence. In these cases, the user will get the error below (which may be
        // somewhat unclear, but we can iterate if users hit this often enough).
        break;
      }
    }
    if (invalidKeys.size) {
      throw new I18nError({
        type: 'InvalidColumnsError',
        columns: Array.from(invalidKeys).join(', '),
      });
    }
  }

  // Sort the rows by key, so that the clustering logic below works correctly.
  rows.sort((a, b) => cmp(getFarmKey(a), getFarmKey(b)));

  let gtPack: ImportedData = new ImportedData(null);

  let prevFarmKey = null;
  for (const row of rows) {
    if (prevFarmKey != getFarmKey(row)) {
      gtPack.farms.push(new ImportedFarm(null));
    }
    const farm = gtPack.farms[gtPack.farms.length - 1];
    if (prevFarmKey == getFarmKey(row)) {
      if (farm.farm_name != row.farm_name) {
        throw new I18nError({
          type: 'NonUniqueFarm',
          key: prevFarmKey,
          x1: farm.farm_name ?? '',
          x2: row.farm_name ?? '',
        });
      }
      if (farm.address != row.address) {
        throw new I18nError({type: 'NonUniqueFarm', key: prevFarmKey, x1: farm.address ?? '', x2: row.address ?? ''});
      }
    }
    prevFarmKey = getFarmKey(row);
    farm.user_group = row.user_group || null;
    farm.farm_name = row.farm_name;
    farm.external_farm_id = row.external_farm_id || null;
    farm.address = row.address || null;

    let field_location: null | LngLat = null;
    if (row.field_location_lat && row.field_location_lng) {
      field_location = parseLatlng(row.field_location_lat + ',' + row.field_location_lng);
    }

    const shapeCoords: LngLat[] = [];
    for (let i = 1; i < 50; i++) {
      const lat = (row as Record<string, any>)['field_shape_lat_' + i];
      const lng = (row as Record<string, any>)['field_shape_lng_' + i];
      if (lat && lng) {
        const lngLat = parseLatlng(lat + ',' + lng);
        if (!lngLat) {
          throw new Error('Invalid shape (A)');
        }
        shapeCoords.push(lngLat);
      } else {
        break;
      }
    }
    if (shapeCoords.length) {
      if (shapeCoords.length < 3) {
        throw new Error('Invalid shape (B)');
      }
      if (
        shapeCoords[0][0] != shapeCoords[shapeCoords.length - 1][0] ||
        shapeCoords[0][1] != shapeCoords[shapeCoords.length - 1][1]
      ) {
        shapeCoords.push(shapeCoords[0]);
      }
    }
    const field_shape: null | Polygon = shapeCoords.length ? {type: 'Polygon', coordinates: [shapeCoords]} : null;

    farm.fields.push(
      new ImportedField({
        external_field_id: String(row.external_field_id),
        field_location,
        field_shape,
        field_area: row.field_area ? {unit: 'hectares', val: row.field_area} : null,
        harvests: [
          new ImportedHarvest({
            crop_id: row.crop_id || null,
            harvest_year: String(row.harvest_year) as HarvestYear,
            organic: parseBool(row.organic),
            irrigated: parseBool(row.irrigated),
            variety: row.variety || null,
          }),
        ],
      }),
    );
  }

  return gtPack;
}

function parseBool(x: any): null | boolean {
  if (x == null) {
    return null;
  }

  if (typeof x == 'number' || typeof x == 'boolean') {
    return !!x;
  }

  if (typeof x != 'string') {
    throw new I18nError({type: 'InvalidBoolError', val: String(x)});
  }

  x = x.toLowerCase().trim();
  if (x == 'yes' || x == 'true' || x == '1') {
    return true;
  }
  if (x == 'no' || x == 'false' || x == '0') {
    return false;
  }

  throw new I18nError({type: 'InvalidBoolError', val: x});
}
