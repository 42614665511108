// @formatter:off
import {intl_int, intl_num, intl_num_2, intl_num_4} from './i18n-util';
import {I18nParametric, I18nSimpleKey} from './i18n';

const msgs = {
  '1990': '1990',
  '1991': '1991',
  '1992': '1992',
  '1993': '1993',
  '1994': '1994',
  '1995': '1995',
  '1996': '1996',
  '1997': '1997',
  '1998': '1998',
  '1999': '1999',
  '2000': '2000',
  '2001': '2001',
  '2002': '2002',
  '2003': '2003',
  '2004': '2004',
  '2005': '2005',
  '2006': '2006',
  '2007': '2007',
  '2008': '2008',
  '2009': '2009',
  '2010': '2010',
  '2011': '2011',
  '2012': '2012',
  '2013': '2013',
  '2014': '2014',
  '2015': '2015',
  '2016': '2016',
  '2017': '2017',
  '2018': '2018',
  '2019': '2019',
  '2020': '2020',
  '2021': '2021',
  '2022': '2022',
  '2023': '2023',
  '2024': '2024',
  '2025': '2025',
  '2026': '2026',
  '2027': '2027',
  '2028': '2028',
  '2029': '2029',
  '2030': '2030',
  '2031': '2031',
  '2032': '2032',
  '2033': '2033',
  '2034': '2034',
  '2035': '2035',
  'ARG': 'Argentine',
  'AUS': 'Australie',
  'AUT': 'Autriche',
  'Accept': 'Accepter',
  'AcceptOrDecline': 'Accepter/Refuser',
  'AddAnotherArea': 'Ajouter une zone',
  'AddAssignee': 'Ajouter un collaborateur',
  'AddAttachments': 'Ajouter des pièces jointes',
  'AddBeneficiary': 'Ajouter un bénéficiaire',
  'AddBroker': 'Ajouter un courtier',
  'AddCause': 'Ajouter une cause',
  'AddClaim': 'Ajouter un sinistre',
  'AddCost': 'Ajouter des frais',
  'AddCrop': 'Ajouter une culture',
  'AddEditSampleShape': 'Dessiner la surface affectée sur la carte',
  'AddEditor': 'Ajouter un éditeur',
  'AddEditors': 'Ajouter des éditeurs',
  'AddEditorsOneGroup': 'Prière d\'ajouter les éditeurs à des entités appartenant à un seul portefeuille',
  'AddFarm': 'Ajouter une exploitation',
  'AddFarmInfo': 'Veuillez noter que pour ajouter de nouvelles parcelles (ou des parcelles supplémentaires) à une exploitation agricole EXISTANTE, il est nécessaire de rechercher tout d\'abord cette dernière, soit par le nom, soit avec la référence de l\'exploitation dans les champs correspondants ci-dessous.',
  'AddField': 'Ajouter une parcelle',
  'AddFieldShape': 'ajouter un périmètre',
  'AddFieldShapes': 'Ajouter des périmètres',
  'AddFieldsHarvests': 'Ajouter des parcelles/campagnes',
  'AddFromFileManager': 'Ajouter depuis le gestionnaire de fichiers',
  'AddFromPhotos': 'Ajouter à partir de photos',
  'AddGuaranteedLossCause': 'Ajouter une perte garantie',
  'AddHarvest': 'Ajouter une campagne',
  'AddLossCause': 'Ajouter une raison de perte',
  'AddNewDamage': 'Ajouter de nouveaux dégâts',
  'AddNewHarvest': 'Créer une campagne',
  'AddNewHarvestFor': 'Créer une nouvelle campagne pour',
  'AddNewLoss': 'Ajouter une nouvelle perte',
  'AddNewSubparcel': 'Ajouter une nouvelle sous-parcelle',
  'AddOrEditFields': 'Ajouter/éditer les parcelles',
  'AddPhoto': 'Ajouter une photo',
  'AddPhotoError': 'Problème avec la prise de photo',
  'AddPolicy': 'Ajouter un contrat',
  'AddPreRisk': 'Ajouter un pré-risque',
  'AddRecipient': 'Ajouter un destinataire',
  'AddSample': 'Ajouter un échantillon',
  'AddSamplePhoto': 'Ajouter une photo à l\'échantillon',
  'AddSubplotId': 'Ajouter une sous-parcelle',
  'AddUnguaranteedLossCause': 'Ajouter une perte non-garantie',
  'AddUser': 'Ajouter un utilisateur',
  'AddVarietyHarvest': 'Ajouter de la variété',
  'AddedBy': 'Ajouté par',
  'AddedGroups': 'Groupes ajoutés',
  'AddedOn': 'Ajouté le',
  'AdditionalInfo': 'Information complémentaire',
  'AdditionalQuestions': 'Questions additionnelles',
  'Address': 'Adresse',
  'AdvancedOptions': 'Options avancées',
  'AffectedArea': 'Surface sinistrée',
  'AffectedCrop': 'Culture affectée',
  'AffectedCrops': 'Cultures sinistrées',
  'AffectedCropsRequiredToDeclareLoss': 'Vous devez sélectionner au moins une culture affectée afin d\'ajouter les pertes déclarées.',
  'AffectedField': 'Parcelle sinistrée',
  'All': 'Tous',
  'AllSynced': 'Synchronisation réussie',
  'AllTime': 'Tout les temps',
  'AlsoInheritedFrom': 'Également hérité de',
  'AlternativeName': 'Nom alternatif',
  'Amount': 'Montant (€)',
  'AntiSlugApplied': 'Anti-limaces appliqué',
  'AppVersionDeprecated': 'Version de l\'application obsolète',
  'AppVersionDeprecatedMessage': 'La version de votre application est obsolète. Vous devez actualiser la page pour obtenir la nouvelle version. Cliquez sur Actualiser pour actualiser la page. Si vous avez des données non enregistrées, cliquez sur Annuler et sauvegardez manuellement les données, puis actualisez la page.',
  'Area': 'Surface',
  'AreaCropTitle': 'Surface par culture',
  'Ascending': 'croissant',
  'Assign': 'Attribuer',
  'AssignExistingFields': 'Allouer des champs existants',
  'AssignedLossAdjusters': 'Experts en sinistres attitrés',
  'AssignedTo': 'Assigné à',
  'AssociatedEmails': 'E-mails additionnels',
  'Attachment': 'Pièce jointe',
  'Attachments': 'Pièces jointes',
  'AuxKey': 'Clé auxiliaire',
  'Average': 'Moyenne',
  'AverageFieldArea': 'Superficie moyenne des parcelles',
  'AvgBollsPerPlant': 'Nombre moyen de capsule par plante',
  'AvgEarsPerPlant': 'Nombre moyen d’épis par plante',
  'AvgGrainsPerEar': 'Nombre moyen de grain par épi',
  'AvgGrainsPerPod': 'Nombre moyen grains par silique',
  'AvgGrainsPerSilique': 'Nombre moyen de grains par silique',
  'AvgKernelsPerBoll': 'Nombre moyen de graines par capsules',
  'AvgPodsPerShaft': 'Nombre moyen de siliques par hampe',
  'AvgSiliquesPerShaft': 'Nombre moyen de siliques par hampe',
  'AwareOfZoagroMapa?': 'Connaissez-vous Zoagro MAPA ?',
  'BEL': 'Belgique',
  'BRA': 'Brésil',
  'Back': 'Retour',
  'BackToHome': 'Retour à la page principale',
  'BasicCoverage': 'Couverture de base',
  'Benchmark': 'Référence',
  'Beneficiaries': 'Bénéficiaires',
  'Biomass': 'Biomasse',
  'BirthDate': 'Date de naissance',
  'Brokers': 'Courtiers',
  'BunchWeight': 'Poids moyen d\'une grappe (en grammes)',
  'BunchesPerPlant': 'Nombre moyen de grappes par cep',
  'ByCrop': 'Par culture',
  'ByField': 'Par parcelle',
  'ByFieldArea': 'moyenne pondérée à la surface (par la surface des parcelles)',
  'ByFieldCount': 'moyenne arithmétique (par nombre de parcelles)',
  'CAN': 'Canada',
  'CHE': 'Suisse',
  'CZE': 'Tchéquie',
  'Calculate': 'Calculer',
  'CalculateAbbreviated': 'Calc.',
  'Cancel': 'Annuler',
  'CantSelectTooManyItems': 'Vous ne pouvez pas sélectionner plus de 50 éléments.',
  'CertifiedOrRegisteredSeeds?': 'Les semences sont-elles certifiées ou enregistrées ?',
  'Change': 'Modifier',
  'ChangeOverrideSample': 'Remplacer les valeurs d’échantillon',
  'ChangeOverrideSampleMsg': 'Êtes-vous sûr de vouloir écraser la valeur de rendement agrégée à la culture ?',
  'ChangeUserAccessRights': 'Modifier les droits d\'accès des utilisateurs',
  'CheckForCodepushUpdate': 'Vérifier les mises à jour',
  'CirclesArea': 'Zone du Cercle',
  'CirclesDiameter': 'Diamètre du cercle',
  'CirclesRadius': 'Rayon du cercle',
  'City': 'Ville',
  'Claim': 'Sinistre',
  'ClaimAssign': 'Attribuer un sinistre',
  'ClaimNumber': 'Numéro de sinistre',
  'ClaimProcessingStartEnd': 'Début et fin du traitement des sinistre',
  'ClaimRequests': 'Sinistres',
  'ClaimStatus_adjuster-accepted': 'Ajusteur accepté',
  'ClaimStatus_assessment-in-progress': 'Évaluation en cours',
  'ClaimStatus_awaiting-adjuster-acceptance': 'En attente de l\'acceptation de l\'expert',
  'ClaimStatus_completed': 'Complété',
  'ClaimStatus_created-unassigned': 'Créé et non attribué',
  'ClaimStatus_payment-proposal-sent': 'Proposition de paiement envoyée',
  'ClaimStatus_rejected': 'Rejeté',
  'ClaimStatus_visit-date-defined': 'Date de visite définie',
  'ClaimStatus_visit-report-created': 'Rapport de visite créé',
  'ClaimStatus_visit-samples-collected': 'Visite commencée',
  'ClaimYear': 'Année du sinistre',
  'Claims': 'Sinistres',
  'Clear': 'Effacer',
  'ClearAll': 'Tout effacer',
  'Clearing': 'Suppression',
  'ClickHereToAddFarmerEmail': 'Cliquez ici pour ajouter l\'email de l\'exploitant',
  'ClickUpload': 'Transmettre un fichier PAC',
  'ClimaticEventPast5Years?': 'Y a-t-il eu un événement climatique au cours des 5 dernières années ?',
  'Close': 'Fermer',
  'CloseVisit': 'Clôture de la visite',
  'Closed': 'Clôturée',
  'ClosedOn': 'Fermé le',
  'ClosedVisits': 'Visites clôturées',
  'CloudCover': 'Couverture nuageuse',
  'CloudCoverInfo': 'Cette colonne met en évidence les années trop nuageuses pour permettre une mesure fiable de la productivité (> 50 % de semaines obscurcies pendant le pic de végétation).',
  'CloudCoverWarning': 'Avertissement de couverture nuageuse',
  'Comment': 'Commentaire',
  'CommentInfoWarning': 'Attention! N\'apparaît pas sur les rapports.',
  'Comments': 'Commentaires',
  'CommodityPrice': 'Prix des matières premières',
  'CompassHeading': 'direction',
  'CompensableLosses': 'Pertes indemnisables',
  'Condition': 'Condition',
  'Configuration': 'Configuration',
  'Confirm': 'Confirmer',
  'Contact': 'Nous contacter',
  'ContainsCopernicus': 'Contient des données Copernicus Service (2017)',
  'ContinueWithErrorsAndWarnings': 'Continuer en ignorant les messages d\'erreurs et les avertissements ?',
  'ConversionRatio': 'Taux de conversion',
  'CopyCurrentHarvest': 'Copier la campagne actuelle',
  'CopyHarvestFromPreviousYears': 'Renouveler la campagne de l\'année passée',
  'CopyLink': 'Copier le lien',
  'CopySample': 'Copier l\'échantillon',
  'CopyVisit': 'Reprendre la mission',
  'CostArea': 'Surface',
  'CostPerArea': 'Coût à l\'hectare',
  'CostType': 'Type de frais',
  'Costs': 'Frais (€)',
  'CouldntDelete': 'Impossible de supprimer cet élément. Vous devez être en ligne pour pouvoir le supprimer.',
  'CoveragePerArea': 'Couverture par zone',
  'CreateNewVisit': 'Créer une nouvelle visite',
  'CropAlreadyDamaged?': 'La récolte est déjà endommagée ?',
  'CropAlreadyPlanted': 'Culture déjà plantée',
  'CropAlreadyPlanted?': 'La culture est-elle déjà plantée ?',
  'CropCondition': 'État des cultures',
  'CropConditionBreakdown': 'État des cultures',
  'CropCoverage': 'Couverture foliaire',
  'CropCoverageInfo': 'Cette colonne indique le pourcentage du champ couvert par la culture sélectionnée.',
  'CropType-s': 'Type(s) de culture',
  'CropVariety': 'Variété',
  'CropYieldAverageTitle': 'Rendement Moyen',
  'CurHarvest': 'Campagne actuelle',
  'CurrentFilters': 'Filtres activés',
  'CurrentVisit': 'Visite en cours',
  'CurrentVisits': 'Visites en cours',
  'Customer': 'Client',
  'DEU': 'Allemagne',
  'DamagedFields': 'Champs endommagés',
  'DamagedHarvest': 'Récolte endommagée',
  'Damages': 'Dégâts',
  'Dashboard': 'Tableau de bord',
  'Date': 'Date',
  'Date-s': 'Date(s)',
  'DateRange': 'Plage de dates',
  'DeadPlantsPct': 'Plantes mortes (%)',
  'DeadVinesPct': 'Ceps morts (%)',
  'DeclaredHazard': 'Aléa déclaré',
  'DeclaredLossCauses': 'Aléas déclarés',
  'Decline': 'Décliner',
  'Deductible': 'Franchise',
  'Deductible-s': 'Franchise(s)',
  'Delete': 'Effacer',
  'DeleteForEveryone': 'Supprimer pour tout le monde',
  'DeleteOfflineArea': 'Supprimer la zone hors ligne',
  'DeleteOverrideSampleMsg': 'Êtes-vous sûr de vouloir supprimer les valeurs de remplacement ? Si oui, nous utiliserons les échantillons pour obtenir les valeurs agrégées au niveau des cultures.',
  'DeleteUser': 'Supprimer l\'utilisateur',
  'DeleteUsers': 'Supprimer des utilisateurs',
  'Delimiter': 'Délimiteur',
  'Descending': 'décroissant',
  'DetachedSample-s': 'Observation(s) Seule(s)',
  'Details': 'Détails',
  'DetectDensity': 'Détecter la densité',
  'DisablePoiMode': 'Montrer toutes les exploitations',
  'DocumentNumber': 'Numéro de document',
  'Done': 'OK',
  'Download': 'Téléchargement',
  'DownloadFailed': 'Téléchargement échoué',
  'DownloadGeojson': 'Télécharger le fichier GeoJSON',
  'DownloadSuccess': 'Téléchargé avec succès',
  'DownloadXLSX': 'Télécharger le fichier excel',
  'Downloading': 'En cours de téléchargement',
  'DownloadingInitialData': 'Téléchargement des données initiales',
  'DrainageProblems?': 'Y a-t-il des problèmes de drainage?',
  'Draw': 'Dessiner',
  'DuplicateEntryWasRemoved': 'L\'élément suivant existe déjà et ne peut pas être importé.',
  'ESP': 'Espagne',
  'EarDensity': 'Densité d\'épis',
  'Edit': 'Modifier',
  'EditClaim': 'Modifier le sinistre',
  'EditFarm': 'Modifier une exploitation',
  'EditField': 'Modifier une parcelle',
  'EditFieldHarvests': 'Modifier les récoltes sur le terrain',
  'EditFieldShape': 'Editer le périmètre de la parcelle',
  'EditHarvest': 'Modifier la culture',
  'EditPolicy': 'Éditer le contrat',
  'EditSample': 'Modifier l\'échantillon',
  'Editors': 'Éditeurs',
  'Email': 'Email',
  'EmailMissing': 'Veuillez ajouter votre email s\'il vous plaît!',
  'Emergence': 'Émergence',
  'EmergenceDate': 'Date d\'émergence',
  'EnableNow': 'Activer maintenant',
  'EnablePoiMode': 'Montrer les exploitations d\'intérêt',
  'EnablePreciseLocation': 'Veuillez activer la localisation précise',
  'EndDate': 'Date de fin',
  'EndDateTime': 'Date et heure de fin',
  'Entity': 'Entité',
  'ErrMissingFields': 'Veuillez s\'il vous plait compléter les champs manquants.',
  'Error': 'Erreur',
  'EstimatedHarvestDate': 'Date de récolte estimée',
  'EstimatedLossAbbr': 'Perte est.',
  'EstimatedYield': 'Rendement résiduel estimé',
  'EstimatedYieldAbbr': 'Rend. est.',
  'EstimatedYieldAreaWeighted': 'Rendement estimé (superficie pondérée)',
  'EstimatedYieldArithmeticMean': 'Rendement estimé (moyenne arithmétique)',
  'EstimatedYieldLoss': 'Perte de rendement estimée',
  'Estimation': 'Estimation',
  'EventDate': 'date de l\'événement',
  'EventValidation': 'Validation d\'événement',
  'Existing': 'existant',
  'ExistingClaim': 'Sinistre existante',
  'ExistingClaimMsg': 'Il existe un sinistre pour cette exploitation. Souhaitez-vous poursuivre avec le sinistre existant?',
  'ExistingFarm': 'Cette exploitation existe déjà.',
  'ExoBinUnsupported': 'Cette fonctionnalité n\'est actuellement pas prise en charge sur cette version de l\'application. Veuillez mettre à jour l\'application avec la dernière version.',
  'ExoCamera': 'Caméra Exo',
  'ExoDetectionError': 'Impossible de détecter les épis. Réessayez en ajustant la position ou en capturant une nouvelle image.',
  'ExoModelNotDownloaded': 'Modèle Exo non téléchargé. Veuillez vous connecter à Internet pour télécharger le modèle pour une utilisation hors ligne.',
  'ExoNotEnabledForUser': 'Cette fonctionnalité n\'est pas activée pour votre entreprise ou votre groupe d\'utilisateurs. Veuillez contacter votre administrateur.',
  'ExoTutorialPanDescription': 'Déplacez l’image à l’aide d’un seul doigt.',
  'ExoTutorialPinchDescription': 'Pincez l\'image pour effectuer un zoom avant et arrière.',
  'ExoTutorialSearchDescription': 'Alignez la zone de détection avec la partie prévue de l\'image, jusqu\'à ce qu\'elle devienne verte.',
  'Expert': 'Expert',
  'Expert-s': 'Expert(s)',
  'ExpertName': 'Nom de l\'expert',
  'ExpertRanking': 'Classement des experts',
  'ExpertReport': 'Rapport d\'expert',
  'ExternalClaimId': 'Identifiant externe du sinistre',
  'ExternalCode': 'Code externe',
  'ExternalFieldId': 'Identifiant externe du champ',
  'ExternalPolicyId': 'ID de politique externe',
  'ExternalProposalId': 'ID de proposition externe',
  'ExtremePrecipitation': 'Précipitations extrêmes (>100 mm/sem.)',
  'ExtremelyHighTemperature': 'Vague de chaleur extrême (Tmax>38°C)',
  'ExtremelyLowTemperature': 'Risque de gel extrême (-15°C>Tmin)',
  'FRA': 'France',
  'FailedToSyncChanges': 'Échec de la synchronisation des modifications',
  'Farm': 'Exploitation',
  'FarmAddress': 'Adresse de l\'exploitation',
  'FarmAndHarvest': 'Info exploitation & campagne',
  'FarmDeletedPleaseStartOver': 'Ferme effacée! Prière de recommencer',
  'FarmFormComment': 'Information additionnelle sur l\'exploitation',
  'FarmHarvestLevel': 'à la culture',
  'FarmHarvestLevelObservation': 'Observation générale à la culture',
  'FarmHarvests': 'Campagnes',
  'FarmInfo': 'Information d\'exploitation',
  'FarmLocationPrompt': 'Appuyez fermement sur la carte pour localiser l\'exploitation ou appuyez sur le bouton bleu à gauche pour utiliser votre localisation actuelle',
  'FarmName': 'Nom de l\'exploitation',
  'FarmReference': 'Référence de la ferme',
  'Farmer': 'Exploitant',
  'FarmerName': 'Nom de l\'exploitant',
  'FarmerOrRepresentative': 'Exploitant ou son représentant',
  'FarmerRepresentative': 'Coordonnées de l\'exploitant',
  'FarmersEstimatedYieldLoss': 'Perte de rendement estimée par l\'agriculteur',
  'Farms': 'Exploitations',
  'FeasibleYield': 'Rendement techniquement réalisable',
  'FeasibleYieldEmpty': 'Le rendement réalisable est vide.',
  'Feedback': 'Commentaire',
  'FetchReportOfflineInfo': 'Il semble que vous soyez hors ligne, le rapport ne peut donc pas être récupéré.',
  'Field': 'Parcelle',
  'Field-s': 'Parcelle(s)',
  'FieldArea': 'Surface de la parcelle',
  'FieldAreaAndReference': 'Surface de la parcelle & référence',
  'FieldCultivatedArea': 'Superficie cultivée (champ)',
  'FieldFormComment': 'Information additionnelle sur la parcelle',
  'FieldHarvests': 'Assolements',
  'FieldInfo': 'Information parcelle',
  'FieldLevel': 'à la parcelle',
  'FieldLocationPrompt': 'Appuyez fermement sur la carte pour localiser la parcelle ou appuyez sur le bouton bleu à gauche pour utiliser votre localisation actuelle',
  'FieldManagement': 'Itinéraire cultural',
  'FieldMissingCurPolicyHarvest': 'Cette parcelle n\'a pas de contrat ou de culture associée; appuyer ici pour associer ces informations.',
  'FieldReference': 'Référence de la parcelle',
  'FieldScoring': 'Notation des parcelles',
  'FieldShapeAndFarm': 'Périmètre de parcelle & exploitation',
  'Fields': 'Parcelles',
  'FileActions': 'Actions sur les fichiers',
  'FilterList': 'Filtrer la liste',
  'Filters': 'Filtres',
  'FinalYields': 'Rendements finaux',
  'FirstAndLastName': 'Premier nom de famille',
  'FirstName': 'Prénom',
  'FloodOrWaterloggingPast5Years?': 'Y a-t-il eu une inondation ou un engorgement au cours des 5 dernières années ?',
  'FloodZone': 'Zone inondable',
  'ForgotPassword': 'Réinitialiser le mot de passe',
  'FormSubmitted': 'Sauvegardé!',
  'FrontPageReportDisclaimer': 'Sous réserve de la validité des garanties',
  'FungicideApplied': 'Fongicide appliqué',
  'GPSModuleUnavailable': 'Module GPS indisponible',
  'Gender': 'Genre',
  'Generate': 'Générer',
  'GenerateReport': 'Générer le rapport',
  'GeneratingReportPleaseWait': 'Rapport en cours de génération, merci de patienter…',
  'GeographicalArea': 'Surface graphique',
  'GetDirectionsField': 'Obtenir l\'itinéraire vers la parcelle',
  'GrainMoisture': 'Taux d\'humidité des grains',
  'GrainMoistureReduction': 'Corrections relatives au taux d\'humidité des grains',
  'GrantsTo': 'Accès à',
  'GrapeVariety': 'Cépage',
  'GrossSampleProduction': 'Production brute échantillonnée',
  'GuaranteedLosses': 'Pertes garanties',
  'HUN': 'Hongrie',
  'HailInfo': 'Cette couche montre le potentiel de dégâts dû à la grêle.',
  'Harvest': 'Culture',
  'HarvestArea': 'Surface de la culture',
  'HarvestAreaMustBePositiveAndLessThanFieldArea': 'La surface de récolte doit être positive et pas plus grande que la surface du champ!',
  'HarvestFormComment': 'Information additionnelle sur la campagne',
  'HarvestInfo': 'Informations sur la culture',
  'HarvestLoss': 'Perte de récolte',
  'HarvestModificationDisabledMsg': 'Cette récolte n\'est pas modifiable car elle a été créée par l\'assureur.',
  'HarvestPlotId': 'Référence de la sous-parcelle',
  'HarvestPractice': 'Type de culture',
  'HarvestReference': 'Référence',
  'HarvestYear': 'Année de récolte',
  'Harvester': 'Moissonneuse',
  'HarvesterDistanceHarvested': 'Distance récoltée',
  'HarvesterPlatformWidth': 'Largeur de la plateforme de la moissonneuse',
  'Harvests': 'Campagnes',
  'HarvestsWillNotBeUpdated': 'Les récoltes de ces champs ne seront pas mises à jour.',
  'HelloReportIssue': 'Bonjour, je voudrais reporter le problème suivant rencontré avec l’application GreenTriangle:',
  'HerbicideApplied': 'Herbicide appliqué',
  'HereAddAsNew': 'Cliquez ici pour ajouter une nouvelle exploitation à la place.',
  'HideDataFromMap': 'Masquer les données de la carte',
  'HighHailRisk': 'Fort potentiel de dégâts de grêle',
  'HighPWind': 'Vent tempêtueux (28.5-32.5 m/s)',
  'HighPrecipitation': 'Précipitations abondantes (30-50 mm/sem.)',
  'HighSoilMoisture': 'Légèrement plus humide que normal',
  'HighTemperature': 'Légère vague de chaleur (25°C<Tmax<29°C)',
  'HighVegetation': 'Santé végétative légèrement supérieure à la normale (+25%)',
  'Higher': 'Supérieur',
  'HigherSoilMoisture': 'Plus humide que normal',
  'HigherTemperature': 'Vague de chaleur modérée (29°C<Tmax<34°C)',
  'HigherVegetation': 'Santé végétative supérieure à la normale (+40%)',
  'Highest': 'Maximal',
  'HighestSoilMoisture': 'Largement plus humide que normal',
  'HighestTemperature': 'Forte vague de chaleur (34°C<Tmax<38°C)',
  'HighestVegetation': 'Santé végétative très supérieure à la normale (+55% ou plus)',
  'HistoricalDeclaredYields': 'Rendements historiques déclarés',
  'HistoricalObservedYields': 'Rendements historiques constatés',
  'HistoricalRealizedYields': 'Rendements historiques réalisés',
  'Homogeneity': 'Homogénéité',
  'HomogeneityInfo': 'Le score d\'homogénéité mesure l\'homogénéité historique du champ en termes de développement des cultures.',
  'ID': 'IDENTIFIANT',
  'ITA': 'Italie',
  'Ignore': 'Ignorer',
  'ImportData': 'Importer les données',
  'Impurities': 'Impuretés',
  'Info': 'Info',
  'InheritedFrom': 'Hérité de',
  'Initials': 'Initiales',
  'InsecticideApplied': 'Insecticide appliqué',
  'Insee': 'INSEE',
  'Install': 'Installer',
  'Insure harvest': 'Assurer la récolte',
  'InsureAnotherHarvest': 'Assurez une autre récolte',
  'InsureHarvest': 'Assurer la récolte',
  'Insured': 'Assuré',
  'InsuredArea': 'Surface assurée',
  'InsuredCropRequired': 'Sélectionnez au moins une culture assurée pour continuer.',
  'InsuredCrops': 'Cultures assurées',
  'InsuredFields': 'Domaines assurés',
  'InsuredHarvest': 'Récolte assurée',
  'InsuredHarvests': 'Récoltes assurées',
  'InsuredInvoiceRecipient?': 'Le destinataire de la facture est-il assuré ?',
  'InsuredPercent': 'Pourcentage assuré',
  'InsuredPrice': 'Prix assuré',
  'InsuredProduction': 'Production assurée',
  'InsuredYield': 'Rendement assuré',
  'InsuredYieldAbbr': 'Rend. Assuré',
  'InterfieldInfo': 'La couche “comparaison parcellaire” affiche les valeurs absolues de l’indice de végétation et permet la comparaison directe de l’état général de la végétation entre les différentes parcelles.',
  'InteryieldInfo': 'La prédiction de rendement affiche le rendement prédit sur la base des indicateurs satellite et des données météorologiques disponibles jusqu\'à la date sélectionnée.',
  'IntrafieldInfo': 'La couche de variabilité intra-parcellaire affiche les valeurs normalisées de l’indice de végétation pour la parcelle suivie. Celle-ci est subdivisée en 5 zones d’aires égales correspondantes au 20% (ou quintile) des valeurs de l’indice, des plus basses aux plus élevées.',
  'InvalidShapeTraced': 'Votre périmètre ne peut pas comporter de lignes qui se croisent. Veuillez dessiner une périmètre simple sans croisements.',
  'Irrigated': 'Irrigué',
  'IrrigatedQuestionMark': 'Irrigué ?',
  'IsBrokerLeader?': 'Le courtier est-il un leader ?',
  'IssuesWithSample': 'Problèmes avec l\'échantillon',
  'KernelsPerEar': 'Grains par épis',
  'KernerlsPerUnit': 'Grains par unité',
  'LTU': 'Lituanie',
  'LastChanged': 'Dernière modification',
  'LastName': 'Nom de famille',
  'Legend': 'Légende',
  'LessDetails': 'Moins de détails',
  'LinkCopiedToClipboard': 'Le lien a été copié dans le presse-papier',
  'LinkToThisLocation': 'Lien vers cet emplacement',
  'List': 'Liste',
  'LiveSampleFeed': 'Echantillons en direct',
  'Loading': 'Chargement',
  'Locale': 'Langue',
  'Location': 'Emplacement',
  'LocationAndFarm': 'Localisation & Exploitation',
  'LocationMocked': 'Il semblerait que votre position ait été bloquée ou modifiée. Veuillez activer votre position réelle pour continuer.',
  'LocationNotProvided': 'Votre position doit être activée pour utiliser cette fonction.',
  'Login': 'Se connecter',
  'Logout': 'Se déconnecter',
  'LossAdjuster': 'Expert',
  'LossCause': 'Raison de la perte',
  'LossCauseRecognized': 'Cause du sinistre reconnue',
  'LossEstimation': 'Estimation des pertes',
  'LossEventDate': 'Date du sinistre',
  'Losses': 'Pertes',
  'LowHailRisk': 'Léger potentiel de dégâts de grêle',
  'LowPrecipitation': 'Faibles précipitations (10-15 mm/sem.)',
  'LowSoilMoisture': 'Légèrement plus sec que normal',
  'LowTemperature': 'Faible risque de gel (-1°C<Tmin<2°C)',
  'LowVegetation': 'Santé végétative légèrement inférieure à la normale (-25%)',
  'LowWind': 'Vent modéré (17-24.5 m/s)',
  'Lower': 'Inférieur',
  'LowerSoilMoisture': 'Plus sec que normal',
  'LowerTemperature': 'Risque de gel modéré (-5°C<Tmin<-1°C)',
  'LowerVegetation': 'Santé végétative inférieure à la normale (-40%)',
  'Lowest': 'Minimal',
  'LowestSoilMoisture': 'Largement plus sec que normal',
  'LowestTemperature': 'Fort risque de gel (-15°C<Tmin<-5°C)',
  'LowestVegetation': 'Santé végétative très inférieure à la normale (-55% ou moins)',
  'MAR': 'Maroc',
  'MDA': 'Moldavie',
  'MEX': 'Mexique',
  'MachineryYield': 'Rendement des machines',
  'MachineryYieldAbbr': 'Rend. des machinerie',
  'ManageUserAccountAndSettings': 'Gérer vos comptes et paramètres',
  'Manager': 'Directeur',
  'Many': 'Beaucoup',
  'Map': 'Carte',
  'MaxSubsidiesPerMinute': 'Subventions maximales par minute',
  'MeasureDensity': 'Mesurer la densité',
  'Median': 'Médian',
  'Merge': 'fusionner',
  'Message': 'Message',
  'Method': 'Méthode',
  'MixedTemperature': 'Zone de forte amplitude',
  'ModerateHailRisk': 'Potentiel modéré de dégâts de grêle',
  'ModeratePrecipitation': 'Précipitations modérées (15-30 mm/sem.)',
  'ModerateWind': 'Vent fort (24.5-28.5 m/s)',
  'Months': 'Mois',
  'MoreDetails': 'Plus de détails',
  'MoreSpecificHarvestDetected': 'Récolte plus spécifique détectée et remplacera la récolte existante.',
  'MunicipalMedianVegetation': 'Végétation médiane de la municipalité',
  'Municipality': 'Commune',
  'MustAddCustomer': 'Doit ajouter au moins un client',
  'MustAddEmail': 'Doit fournir un email valide',
  'MustSelectACustomer': 'Vous devez sélectionner un client pour continuer',
  'MustSelectCropAndYear': 'Vous devez sélectionner une culture et une année',
  'NDVIRange': 'Plage NDVI',
  'Name': 'Nom',
  'NationalYield': 'Rendement national',
  'NeedCurrentHarvest': 'Vous devez ajouter une culture et une campagne pour cette saison avant de continuer',
  'NeedHelpContact': 'Des problèmes avec l\'accès? - Nous contacter',
  'Neighbourhood': 'Quartier',
  'New': 'Nouvelle',
  'NewValue': 'Nouvelle valeur',
  'NewVersionWarning': 'Une nouvelle version de l\'application est disponible.',
  'NewVisit': 'Nouvelle visite',
  'Next': 'Suivant',
  'No': 'Non',
  'NoClaims': 'Aucun sinistre',
  'NoComment': 'Aucun commentaire.',
  'NoData': 'Pas de données.',
  'NoDataFoundForYourFilters': 'Nous n\'avons pas trouvé de données qui répondent à vos critères de recherche.',
  'NoFarmEditors': 'Aucun éditeur n\'est attribué à cette exploitation',
  'NoFieldHarvest': 'Pas de récolte sur le terrain',
  'NoGpsSupport': 'Cet appareil n\'a pas de GPS.',
  'NoHailRisk': 'Pas de risque de dommages ou risque très faible',
  'NoOfflineFarms': 'Aucune exploitation enregistrée pour une utilisation hors ligne.',
  'NoOngoingVisit': 'Cliquez sur (+) ci-dessus pour commencer une nouvelle visite.',
  'NoPrecipitation': 'Cumul de précipitations très faible ou pas de précipitations (<5 mm/sem.)',
  'NoRainstorm': 'Pas de pluie (<15 mm/h)',
  'NoSampleDateSelectionWarning': 'Aucune date d\'échantillonnage n\'a été sélectionnée. Veuillez en sélectionner au moins une pour ne pas avoir de rapport vide.',
  'NoSheetDataError': 'Le fichier Excel importé ne comportait pas de feuille « Data ». Veuillez utiliser un modèle',
  'NoTelepacAvailable': 'Telepac indisponible',
  'NoVariety': 'Pas de variété',
  'NoWind': 'Faible vent ou pas de vent (<15 m/s)',
  'NonCompensableLosses': 'Pertes non indemnisables',
  'NonIrrigated': 'Non irrigué',
  'NonOrganic': 'Conventionnel',
  'NormalSoilMoisture': 'Situation normale',
  'NormalTemperature': 'Pas de risque de gel ou vague de chaleur (2°C<Tmin; Tmax<25°C)',
  'NormalVegetation': 'Santé végétative dans la norme',
  'NotAffected': 'Non sinistrée',
  'NotAvailable': 'Pas disponible',
  'Number': 'Nombre',
  'NumberMatchingFields': 'Nombre de parcelles correspondantes',
  'NumberMatchingSamples': 'Nombre d\'échantillons correspondants',
  'NumberOfFields': 'Nombre de parcelles',
  'NumberOfGrainsPerRow': 'Nombre de grains par rangée',
  'NumberOfInstallments': 'Nombre de versements',
  'NumberOfLossAdjusters': 'Nombre d\'experts',
  'NumberOfPolicies': 'Nombre de contrats',
  'NumberOfRowsPerHead': 'Parastiches',
  'NumberOfSamples': 'Nombre d\'échantillons',
  'Occurence': 'Survenance',
  'OfFieldArea': 'de la surface de la parcelle',
  'OfficialHarvestArea': 'Surface admissible (PAC)',
  'Offline': 'Hors ligne',
  'OfflineAreaMaxLimitReachedMsg': 'Limite maximale de zone hors ligne atteinte.',
  'OfflineAreaTutorialDownload': 'Appuyez sur le bouton de téléchargement pour enregistrer les tuiles de carte pour une utilisation hors ligne pour les zones sélectionnées.',
  'OfflineAreaTutorialPanMap': 'Déplacez la carte à l\'aide d\'un seul doigt.',
  'OfflineAreaTutorialPinchZoom': 'Pincez la carte pour zoomer et dézoomer.',
  'OfflineAreaTutorialSelectArea': 'Appuyez sur une zone pour la sélectionner ou la désélectionner.',
  'OfflineAreas': 'Zones hors ligne',
  'OfflineFarms': 'Exploitations hors ligne',
  'Ok': 'OK',
  'Ongoing': 'En cours',
  'OpenCamera': 'Ouvrir la caméra',
  'OpenFarm': 'Ouvrir l\'exploitation',
  'OpenPolicy': 'Ouvrir le contrat',
  'OpenVisit': 'Visite ouverte',
  'Or': 'Ou',
  'Organic': 'Bio',
  'OrganicQuestionMark': 'Bio?',
  'OtherCompensableLosses': 'Autres pertes indemnisables',
  'OtherFields': 'Autres champs',
  'OverrideCalculation': 'Corriger le rendement agrégé à la culture',
  'OverrideCost': 'Frais non engagés',
  'OverrideEstimatedYield': 'Rendement résiduel à la culture estimé',
  'OverrideLoss': 'Pertes non garanties à retenir',
  'Overview': 'Vue d\'ensemble',
  'PER': 'Pérou',
  'POL': 'Pologne',
  'PRY': 'Paraguay',
  'Page': 'Page',
  'Password': 'Mot de passe',
  'PasswordMissing': 'Veuillez ajouter votre mot de passe s\'il vous plaît!',
  'PastMonth': 'Le mois dernier',
  'PastReportsClickToRefresh': 'Archive - cliquer pour mettre à jour la liste:',
  'PastWeek': 'La semaine dernière',
  'PastYear': 'L\'année dernière',
  'Pause': 'Pause',
  'Paused': 'En pause',
  'PeakVegetationDate': 'Date du pic de végétation',
  'Pending': 'En attente',
  'PendingDownload': 'En attente de téléchargement',
  'PendingUpload': 'En attente de téléchargement',
  'PerCropSignature': 'Signature par culture',
  'Percent': 'Pour cent',
  'Phone': 'Téléphone',
  'Photo': 'Photo',
  'Photos': 'Photos',
  'PhotosAndReferences': 'Photos & Références',
  'PixelResolution': 'Résolution en pixels',
  'PlanetTiles': 'Images Planet',
  'PlanetTilesBoundsWarning': 'Aucune vignette n\'est disponible pour la date et la zone sélectionnées. Veuillez sélectionner une date ou une zone différente.',
  'PlantDensity': 'Densité des plantes',
  'PlantsCollected': 'Nombre de pieds comptés sur la longueur',
  'PleaseAddFields': 'Ajoutez des parcelles pour continuer',
  'PleaseConnect': 'Pas de connexion internet. Pour vous connecter, veuillez vous connecter à internet!',
  'PleaseEnableLocation': 'Veuillez activer l\'emplacement précis pour GreenTriangle.',
  'PleaseEnablePhotoPermissions': 'Pour continuer, prière d\'autoriser l\'appareil photo dans vos réglages',
  'PleaseEnterEmail': 'Veuillez entrer votre email.',
  'PleaseEnterValue': 'Entrez une valeur s\'il vous plaît',
  'PleaseReviewReportBeforeSigning': 'Veuillez passer le rapport en revue avant la signature',
  'PleaseSelectClaim': 'Veuillez sélectionner un sinistre',
  'PleaseSelectDate': 'Veuillez sélectionner une date.',
  'PleaseSelectFarm': 'Sélectionnez une exploitation pour continuer',
  'PleaseSelectField': 'Prière de sélectionner un champ pour continuer',
  'PleaseSelectLocation': 'Sélectionnez une localisation pour continuer.',
  'PleaseSelectPolicy': 'Veuillez sélectionner une seule politique',
  'PleaseUpgradeLatest': 'Votre version de GreenTriangle n\'est pas à jour. Merci d\'effectuer la mise à jour.',
  'PointOrZone': 'Point/Zone',
  'Policies': 'Contrats',
  'Policy': 'Contrat',
  'PolicyFormComment': 'Information additionnelle sur le contrat',
  'PolicyNumber': 'Numéro de contrat',
  'PolicyProcessingStartEnd': 'Début et fin du traitement des politiques',
  'Polygon': 'Polygone',
  'Portfolio': 'Portefeuille',
  'PortfolioReport': 'Rapport de portefeuille',
  'PotentialDuplicates': 'Les entités suivantes semblent identiques. Veuillez sélectionner l\'une des options suivantes',
  'PoweredBy': 'Produit par',
  'Practice': 'Pratique',
  'PreRisk': 'Pré-risque',
  'PreRiskPercentage': 'Pourcentage de pré-risque',
  'PrecipitationInfo': 'Les précipitations hebdomadaires totales désignent l\'eau liquide et gelée accumulée, y compris la pluie et la neige, qui tombe sur la surface de la Terre dans les 7 jours. Cela n\'inclut pas le brouillard, ni la rosée ou les précipitations qui s\'évaporent dans l\'atmosphère avant d\'atterrir sur la surface de la Terre.',
  'PredictedYield': 'Rendement prévu',
  'PredictedYieldAbbr': 'Rend. prévu',
  'PreexistingPolicy?': 'Une politique existe déjà pour ce domaine ?',
  'Premium': 'Prime',
  'PremiumRatePercent': 'Taux de prime en pourcentage',
  'Preview': 'Aperçu',
  'PreviewAndSend': 'Aperçu & envoi',
  'Previous': 'Précédent',
  'PreviousDate-s': 'Date(s) précédente(s)',
  'PriceRetainedByExpert': 'Prix retenu par l’expert',
  'Primary': 'Principale',
  'PrimaryYieldLoss': 'Perte de rendement principale',
  'PrivacyPolicy': 'politique de confidentialité',
  'Processing': 'Traitement en cours',
  'ProductType': 'Type de produit',
  'Productivity': 'Productivité',
  'ProductivityInfo': 'Le score de productivité estime la productivité historique du champ.',
  'Proposal': 'Proposition',
  'QualityLosses': 'Pertes de qualité',
  'QuoteProcessingStartEnd': 'Début et fin du traitement du devis',
  'ROU': 'Roumanie',
  'RUS': 'Russie',
  'RainstormInfo': 'Précipitations horaires maximales au cours de cette semaine.',
  'RainstormLevel1': 'Pluie torrentielle degré 1 (15-25 mm/h)',
  'RainstormLevel2': 'Pluie torrentielle degré 2 (25-35 mm/h)',
  'RainstormLevel3': 'Pluie torrentielle degré 3 (35-50 mm/h)',
  'RainstormLevel4': 'Pluie torrentielle degré 4 (> 50 mm/h)',
  'RealizedYield': 'Rendement réalisé',
  'RealizedYieldAbbr': 'Rend. réalisé',
  'Recipients': 'Destinataires',
  'RecognizedHazard': 'Aléa reconnu',
  'Reference': 'Référence',
  'ReferenceYield': 'Rendement de référence',
  'Refresh': 'Rafraîchir',
  'Region': 'Région',
  'RegionLevel': 'Niveau régional',
  'RegionLevel_commune': 'Commune',
  'RegionLevel_country': 'Pays',
  'RegionLevel_state': 'État',
  'RegionalStatistic': 'Statistiques régionales',
  'Regions': 'Régions',
  'Reject': 'Rejeter',
  'RelativeScoring': 'Score relatif',
  'RelativeScoringInfo': 'Les scores relatifs reflètent la différence entre le score d\'un champ et le score médian des champs d\'une région (en utilisant un échantillon d\'environ 100 champs). Le score %-ile positionne le score de la parcelle parmi les champs de la région (5 étant le meilleur centile, 1 le pire, 3 le médian)',
  'Remove': 'Retirer',
  'RemoveCrop': 'Effacer le type de culture',
  'RemoveFarm': 'supprimer une exploitation',
  'RemoveField': 'supprimer une parcelle',
  'RemoveHarvest': 'supprimer une campagne',
  'RemoveLoss': 'Supprimer la perte',
  'RemovedGroups': 'Groupes supprimés',
  'RenewHarvests': 'Renouveller des cultures',
  'ReplantingCoverage': 'Couverture de replantation',
  'ReportDate': 'Date du rapport',
  'ReportDoesntExist': 'Le rapport n\'existe pas',
  'ReportGenerationPageComment': 'Commentaire général sur le rapport',
  'ReportNoFields': 'Cette exploitation n\'a pas de parcelles sur lesquelles créer un rapport!',
  'ReportOverview': 'Aperçu du rapport',
  'Reset': 'Ré-initialiser',
  'ResetLink': 'Envoyer email de réinitialisation',
  'Restore': 'Restaurer',
  'Resume': 'Reprendre',
  'Risks': 'Risques',
  'RowDistanceCollected': 'Longueur de comptage (m)',
  'RowsAndWidth': 'Rangées et distance inter-rang',
  'SRB': 'Serbie',
  'Sample': 'Echantillon',
  'Sample-s': 'Echantillon(s)',
  'SampleCropTitle': 'Nombre d\'échantillons par culture',
  'SampleDate': 'Date de l\'échantillon',
  'SampleDate-s': 'Date(s) d\'échantillonnage',
  'SampleFormComment': 'Commentaire / Observation',
  'SampleFormLossesWarning': 'Voulez-vous corriger les pertes ?',
  'SampleFormLossesWarningDescription': 'Il y a une différence entre les pertes et le rendement estimé.',
  'SampleFormMissingImagesWarning': 'Vous n\'avez pas pris de photo pour cet échantillon. Veuillez en ajouter un pour continuer.',
  'SampleLocation': 'Position de l\'échantillon',
  'SampleLocationInaccurate': 'Emplacement inexact - Veuillez activer l\'emplacement précis, si vous ne l\'avez pas déjà fait. Cliquez ensuite à nouveau sur "Utiliser ma localisation".',
  'SampleLocationOutsideField': 'L\'échantillon est positionné à l\'extérieur de la parcelle sélectionnée',
  'SampleLocationPrompt': 'Choisissez la parcelle correspondante sur la carte, puis appuyez fermement sur la carte pour localiser l\'échantillon ou appuyez sur le bouton bleu à gauche pour utiliser votre localisation actuelle.',
  'SampleType': 'Type d\'échantillon',
  'SampledArea': 'Surface échantillonnée',
  'SampledBy': 'Échantillonné par',
  'Samples': 'Echantillons',
  'SamplingDate-s': 'Date(s) d\'échantillonnage',
  'Save': 'Sauvegarder',
  'SaveApprove': 'Enregistrer et approuver',
  'SaveOffline': 'Enregistrer hors ligne',
  'Saving': 'sauvegarde en cours...',
  'Score': 'Score',
  'Search': 'Chercher',
  'SearchAddTipLabel': 'Combiner les termes de recherche',
  'SearchExampleDescAddedBy': 'Entrez une adresse mail pour montrer les échantillons ajoutés par cet utilisateur, ainsi que les parcelles et exploitations échantillonnées',
  'SearchExampleDescAddress': 'Entrez une adresse pour y recentrer la carte',
  'SearchExampleDescCropCondition': 'Entrez une condition de culture pour afficher les échantillons correspondants',
  'SearchExampleDescFarm': 'Trouvez une exploitation, ses champs et ses échantillons, par son nom ou son adresse',
  'SearchExampleDescHarvest': 'Trouvez tous les éléments d\'une campagne en entrant un type de culture, une saison ou une année de récolte. Si vous entrez un type de culture, les résultats seront limités à la campagne en cours. Vous pouvez faire une nouvelle recherche pour afficher le résultat des années précédentes.',
  'SearchExampleDescPolicy': 'Trouvez tous les éléments se rapportant à un numéro de contrat spécifique',
  'SearchExampleLabelAddedBy': 'Trouvez des échantillons ajoutés par une adresse mail spécifique',
  'SearchExampleLabelAddress': 'Ville, adresse ou pays',
  'SearchExampleLabelCropCondition': 'Trouvez des échantillons selon la condition de la culture renseignée (p.e. "poor")',
  'SearchExampleLabelFarm': 'Cherchez par nom et adresse de l\'exploitation',
  'SearchExampleLabelHarvest': 'Cherchez par campagne, p.e. "blé"',
  'SearchExampleLabelPolicy': 'Cherchez par numéro de contrat',
  'SearchExampleLabelUserGroup': 'Trouvez des éléments d\'un portefeuille',
  'SearchForClaimNumber': 'Chercher un numéro de sinistre',
  'SearchVisit': 'Trouver une mission non-assignée',
  'Secondary': 'Secondaire',
  'SecondaryYieldLoss': 'Perte secondaire',
  'SeeDetailsBelow': 'Voir les détails ci-dessous',
  'SeedWeightPerHead': 'Poids moyen des grains par tête (en grammes)',
  'Select': 'Sélection',
  'SelectAll': 'Tout sélectionner',
  'SelectAppellation': 'Sélectionner l\'appellation',
  'SelectCostType': 'Sélectionner un type de frais',
  'SelectCropCondition': 'Sélectionner la condition de la culture',
  'SelectCropType': 'Sélectionner un type de culture',
  'SelectCurHarvest': 'Sélectionner la culture actuelle',
  'SelectDate': 'Sélectionner une date',
  'SelectFarm': 'Sélectionner une exploitation',
  'SelectFarmsForOfflineAccess': 'Sélectionnez les exploitations pour un accès hors ligne',
  'SelectField': 'Sélectionner une parcelle',
  'SelectFieldCurHarvest': 'Sélectionner l\'année de récolte',
  'SelectFieldCurPolicy': 'Sélectioner le contrat de la parcelle',
  'SelectHarvest': 'Choisir une campagne',
  'SelectHarvestYear': 'Sélectionner l\'année de récolte',
  'SelectLossCause': 'Choisir la raison de la perte',
  'SelectOneGroup': 'Sélectionner des éléments dans un seul portefeuille à la fois!',
  'SelectPac': 'Veuillez sélectionner un fichier valide (.ZIP, .XML, .KML, GeoJSON). Si vous êtes sûr que ce fichier est correct, veuillez nous contacter à support@green-triangle.com.',
  'SelectPolicy': 'Sélectionner un contrat',
  'SelectPortfolio': 'Sélectionner le portefeuille',
  'SelectRegions': 'Sélectionnez les régions',
  'SelectReportType': 'Sélectionner le type de rapport',
  'SelectSampleType': 'Sélectionner le type d\'échantillon',
  'SelectSubplotId': 'Sélectionner ou ajouter une sous-parcelle',
  'SelectVariety': 'Sélectionner la variété',
  'SelectVegetationStage': 'Stade de la culture (BBCH)',
  'SelectVisitType': 'Sélectionner le type de visite',
  'SelectWillOverwriteShape': 'Êtes-vous sûr de vouloir choisir ce périmètre? Le périmètre actuel va être écrasé.',
  'SelectYear': 'Sélectionner l\'année',
  'SelectedAppellation': 'Appellation sélectionnée',
  'SelectedCrops': 'Cultures sélectionnées',
  'SelectedField': 'Champ sélectionné',
  'Send': 'Envoyer',
  'SendFeedback': 'Envoyer un commentaire',
  'SendTo': 'Envoyer à',
  'Sending': 'En cours d\'envoi',
  'Sent': 'Envoyé',
  'Settings': 'Paramètres',
  'SettlementYield': 'Rendement de règlement',
  'SettlementYieldAbbr': 'Rend. de règlement',
  'SevereHailRisk': 'Très fort potentiel de dégâts de grêle',
  'SeverePrecipitation': 'Fortes précipitations (75-100 mm/sem.)',
  'SevereWind': 'Ouragan (>32.5 m/s)',
  'ShapeTracerAddPoint': 'Appuyez sur la carte pour ajouter un point ou appuyez sur un point pour le modifier ou le supprimer.',
  'ShapeTracerEditPoint': 'Bougez le point à l\'aide de l\'épingle ou appuyez n\'importe où ailleurs pour ajouter de nouveaux points.',
  'Share': 'Partager',
  'ShowAll': 'Afficher tout',
  'ShowClosed': 'Clôturées',
  'ShowDataOnMap': 'Afficher les données sur la carte',
  'ShowLastAdded': 'Afficher les derniers ajouts',
  'ShowOnMap': 'Montrer sur la carte',
  'ShowSamplesAddedIn': 'Afficher les échantillons ajoutés en dernier:',
  'SignAndClose': 'Signature & Clôture',
  'SignatoryName': 'Nom du signataire',
  'SignatoryNames': 'Noms des signataires',
  'Signature': 'Signature',
  'Signatures': 'Signatures',
  'SignedByFarmer': 'Signé par l\'exploitant',
  'SimplifiedClaimStatus_Done': 'Clôturé',
  'SimplifiedClaimStatus_New': 'Nouveau',
  'SimplifiedClaimStatus_Ongoing': 'En cours',
  'SnowIce': 'Neige/Glace',
  'SoilMoistureInfo': 'L’indice d’anomalie de l’humidité des sols quantifie l’état hydrique de la couche située à 40 cm de profondeur relativement à la moyenne des dernières années (10 années) pour la même période.',
  'SomethingWentWrongError': 'Quelque chose s\'est mal passé.',
  'SowingDate': 'Date de semis',
  'SowingDensity': 'Densité des semis',
  'SpaceBetweenPlants': 'Espace inter-plante',
  'SpaceBetweenRows': 'Espace inter-rang',
  'SquareArea': 'Superficie de la place',
  'SquareMeterCut': 'Coupe sur un mètre carré / Densité des plantes',
  'SquareSide': 'Côté Place',
  'SquareSize': 'Taille du carré',
  'Stability': 'Stabilité',
  'StabilityInfo': 'Le score de stabilité mesure la stabilité de la productivité déduite d\'un champ au fil du temps.',
  'StartDate': 'Date de début',
  'StartDateTime': 'Date et heure de début',
  'StartingYield': 'Base de calcul (rendement assuré)',
  'State': 'État',
  'Status': 'Statut',
  'SubjectToValidationOfReseedingByExpert': 'Sous réserve de validation du ressemis par l’expert',
  'Submitted': 'Soumis',
  'Subplots': 'Sous-parcelles',
  'Subsidy': 'Subvention',
  'SubsidyError_INSURED_ADDRESS_MISSING': 'L\'adresse de l\'assuré est manquante.',
  'SubsidyError_INSURED_DATA_MISSING': 'Les données concernant l\'assuré sont manquantes.',
  'SubsidyError_INSURED_EXTERNAL_ID_MISSING': 'Le numéro de document de l\'assuré est manquant.',
  'SubsidyError_INSURED_ZIP_CODE_MISSING': 'Le code postal de l\'assuré est manquant.',
  'SubsidyError_VALID_FROM_MISSING': 'La date est manquante.',
  'SubsidyError_VALID_TO_MISSING': 'La date est manquante.',
  'SubsidySettings': 'Paramètres de subvention',
  'SubsidyStatus': 'Statut de subvention',
  'SumInsured': 'Somme assurée',
  'Summary': 'Résumé',
  'SupportedSurface': 'Surface prise en charge',
  'SureAddSampleWithoutField': 'Êtes-vous sûr de vouloir ajouter un échantillon sans parcelle?',
  'SureCancel': 'Êtes-vous sûrs de vouloir annuler avant de sauvegarder les données?',
  'SureCloseVisit': 'Êtes-vous sûr de vouloir clôturer la visite? Le rapport n\'a pas été signé.',
  'SureCloseVisitPartial': 'Êtes-vous sûr de vouloir clôturer la visite? Le rapport n\'a été que partiellement signé.',
  'SureDeleteFarm': 'Êtes-vous sûr de vouloir supprimer cette exploitation?',
  'SureDeleteField': 'Êtes-vous sûr de vouloir supprimer cette parcelle?',
  'SureDeleteHarvest': 'Etes-vous sûr de vouloir supprimer cette campagne?',
  'SureDeleteSample': 'Êtes-vous sûr de vouloir supprimer cet échantillon?',
  'SureDeleteVisit': 'Êtes-vous sûr de vouloir supprimer cette mission?',
  'SureReset': 'Êtes-vous sûr de vouloir réinitialiser ? Les données enregistrées en mode hors ligne seront perdues en cas de déconnection.',
  'SureSelectObject': 'Êtes-vous sûr de vouloir sélectionner ceci à la place?',
  'SureSignOut': 'Êtes-vous sûr de vouloir vous déconnecter? Les données enregistrées en mode hors ligne seront perdues en cas de déconnection.',
  'SurfacePAC': 'Surface PAC',
  'SurfaceTemperatureInfo': 'Cette couche affiche les minima et maxima de température de l\'air au sol sur une semaine et signale le risque de gel ou de vague de chaleur.',
  'Syncing': 'Synchronisation en cours',
  'TableNoRowsFound': 'Aucune ligne trouvée',
  'TableOf': 'de',
  'TablePage': 'Page',
  'TableRows': 'Ligne',
  'TelepacAvailableFor': 'Telepac chargé pour',
  'TelepacNr': 'Numéro Télépac',
  'TelepacReport': 'Import PAC',
  'TemperatureInfo': 'Cette couche affiche les minima et maxima de température de l\'air à une hauteur de 2m sur une semaine et signale le risque de gel ou de vague de chaleur.',
  'Tertiary': 'Tertiaire',
  'TertiaryYieldLoss': 'Perte tertiaire',
  'ThousandKernelWeightGrams': 'Poids de mille grains',
  'TopNCropsByHarvestArea': 'Principales cultures par zone de récolte',
  'Total': 'Total',
  'TotalArea': 'Surface totale',
  'TotalCultivatedArea': 'Superficie cultivée (totale)',
  'TotalEarsDetected': 'Total des épis détectées',
  'TotalHarvestArea': 'Surface totale des sous-parcelles',
  'TotalLoss': 'Perte totale',
  'TotalYieldPctLoss': 'Perte totale (%)',
  'UKR': 'Ukraine',
  'URY': 'Uruguay',
  'USA': 'États-Unis',
  'UnableToUpload': 'échec du chargement',
  'UnassignMyself': 'Me désattribuer',
  'Uncategorized': 'Non classé',
  'Undo': 'Annuler',
  'UnguaranteedLosses': 'Pertes non-garanties',
  'Unit': 'Unité',
  'UnitsPerPlant': 'Unités par plante',
  'UnknownErrorOccurred': 'Une erreur inconnue est survenue.',
  'Update': 'Mise à jour',
  'UpdateMemberships': 'Mettre à jour les adhésions',
  'UploadPACFile': 'Transmettre le fichier PAC',
  'Usage': 'Usage',
  'UseMyLocation': 'Utiliser ma localisation',
  'UsedYieldUnit': 'Unité de rendement utilisée',
  'User': 'Utilisateur',
  'UserAdminNoAccess': 'Il n\'y a aucun chevauchement entre les clients utilisateurs sélectionnés et vos droits d\'utilisateur-administrateur.',
  'UserAdminNoCommon': 'Les utilisateurs sélectionnés n\'ont pas d\'entreprise commune.',
  'UserAdministration': 'Gestion des utilisateurs',
  'UserGroup': 'Portefeuille',
  'Validating': 'Validation',
  'Validation': 'Validation',
  'Validity': 'Validité',
  'VegetationInfo': 'L’anomalie de vitalité des plantes offre une mesure alternative de l’état relatif des cultures comparé aux années précédentes (depuis 2001). Cet indicateur peut être utilisé pour suivre les zones où la végétation est stressée ou comme paramètre pour détecter une vague de chaleur ou une sécheresse potentielle.',
  'VegetationStage': 'Stade de la culture',
  'VeryHighPrecipitation': 'Précipitations très abondantes (50-75 mm/sem.)',
  'VeryLowPrecipitation': 'Précipitations très faibles (5-10 mm/sem.)',
  'ViewThisInstead': 'Souhaitez-vous quitter ce formulaire et ouvrir celui-ci à la place?',
  'VinificationRatio': 'Ratio de vinification (kg/hl)',
  'Visit': 'Mission',
  'VisitAssign': 'Attribuer une Visite',
  'VisitCandidateStatus_accepted': 'Accepté',
  'VisitCandidateStatus_assigned': 'Attribué',
  'VisitCandidateStatus_rejected': 'Rejeté',
  'VisitDate': 'Date de la visite',
  'VisitEmailBody': 'Bonjour,\n\nVeuillez trouver ci-joint le rapport d\'expertise concernant votre sinistre climatique/grêle.\n\nPour toute question relative à ce dossier, veuillez contacter l\'expert en charge du dossier.\n\nMerci,\n\nL\'équipe GreenTriangle',
  'VisitList': 'Mes missions',
  'VisitMode': 'Ma mission',
  'VisitReport': 'Rapport de visite',
  'VisitReportEmailInfoMsg': 'Cela enverra le rapport de visite signé aux adresses e-mail sélectionnées.',
  'VisitType': 'Type de visite',
  'VisitValidation': 'Validation de la visite',
  'VisitYear': 'Année de visite',
  'Visited': 'Visité(e)s',
  'Visits': 'Visites',
  'Volatility': 'Volatilité',
  'WebApp': 'Web App',
  'WeighedYield': 'Rendement pondéré',
  'WeighedYieldAbbr': 'Rend. pondéré',
  'WeightPerPlant': 'Poids moyen par plante',
  'WeightPerUnit': 'Poids par unité',
  'WeightedAverage': 'Moyenne pondérée',
  'WindInfo': 'La couche de rafale de vent hebdomadaire maximale représente la rafale de vent maximale enregistrée au cours des 7 derniers jours à une hauteur de dix mètres au-dessus de la surface de la Terre.',
  'Withdrawal': 'Désistement',
  'Withdrawn': 'Désisté',
  'WouldLikeToRestore': 'Nous avons identifié des données pour ce formulaire qui n\'ont pas été sauvegardées à cause d\'un crash ou d\'une mise à jour. Souhaitez-vous les récupérer?',
  'Year': 'Année',
  'YearEg': 'Année (p.e. 2019)',
  'YearsOfAgriculture?': 'Depuis combien d’années travaillez-vous dans l’agriculture ?',
  'Yes': 'Oui',
  'YieldAndLoss': 'Rendement & Perte',
  'YieldCalcUnsupportedCrop': 'Le calculateur n\'est actuellement pas disponible pour cette nature de culture. En attendant, il est possible de renseigner la densité des plantes.',
  'YieldEstimationMethod': 'Méthode d\'estimation du rendement',
  'YieldLoss': 'Perte de rendement',
  'YieldLossInputHidden': 'La valeur de la perte est désactivée car la cause de perte est non reconnue.',
  'YieldRatio': 'Ratio de rendement',
  'YieldTooLarge': 'Valeur de rendement trop élevée',
  'YouMayTryAgain': 'Essayer encore une fois',
  'ZAF': 'Afrique du Sud',
  'ZipCode': 'Code postal',
  'ZoomInViewLayer': 'Pour voir cette couche, veuillez zoomer.',
  'ZoomOutViewLayer': 'Pour voir cette couche, veuillez dézoomer.',
  'additional-costs': 'Frais suppl. (€)',
  'agroforestry': 'Agroforestrie',
  'alfalfa': 'Luzerne fourragère',
  'allSynced': 'Tout a été synchronisé avec la base de données',
  'almond': 'Amandes',
  'ananas': 'Ananas',
  'angelica': 'Angélique',
  'anise': 'Anis',
  'apples': 'Pommes',
  'apples-cider': 'Pommes à cidre',
  'apples-pre-prod': 'Pommes (verger de 5 ans ou moins)',
  'apricots': 'Abricots',
  'apricots-pre-prod': 'Abricots (verger de 5 ans ou moins)',
  'aromatic-plants': 'Plantes aromatiques',
  'artichoke': 'Artichauts',
  'asparagus': 'Asperges',
  'avocado': 'Avocats',
  'bamboo': 'bambou',
  'bananas': 'Bananes',
  'barley': 'Orge',
  'barley-6-row': 'Escourgeon',
  'barley-malting': 'Orge brassicole',
  'barley-seeds': 'Orge (Semences)',
  'base': 'Base',
  'basic': 'Basique',
  'basil': 'Basilic',
  'bbch-00-grapes': 'A. Bourgeon d\'hiver',
  'bbch-05-grapes': 'B. Bourgeon dans le coton',
  'bbch-09-grapes': 'C. Pointe verte',
  'bbch-11-grapes': 'D. Sortie des feuilles',
  'bbch-13-grapes': 'E. 2 à 3 feuilles étalées',
  'bbch-53-grapes': 'F. Grappes visibles',
  'bbch-55-grapes': 'G. Boutons floraux agglomérés',
  'bbch-57-grapes': 'H. Boutons floraux séparés',
  'bbch-64-grapes': 'I. Floraison',
  'bbch-71-grapes': 'J. Nouaison',
  'bbch-75-grapes': 'K. Petits pois',
  'bbch-77-grapes': 'L. Fermeture de la grappe',
  'bbch-81-grapes': 'M. Véraison',
  'bbch-89-grapes': 'N. Maturité',
  'bbch-93-grapes': 'O. Chute des feuilles',
  'beans': 'Fèves',
  'beans-dry': 'Haricots secs',
  'beans-green': 'Haricots verts',
  'beans-red': 'Haricots rouges',
  'beans-seeds': 'Fèves et féveroles (Semences)',
  'beans-white': 'Haricots blancs',
  'beetroot': 'Betteraves rouges',
  'benchmark-yield': 'Rendement de référence',
  'black-medick': 'Luzerne lupuline',
  'blackberry': 'Mûres',
  'blackcurrant': 'Cassis',
  'blueberry': 'Myrtilles',
  'broccoli': 'Brocolis',
  'bromes': 'Brôme',
  'buckwheat': 'Sarrasin',
  'burdock': 'Bardane',
  'cabbages': 'Choux',
  'cabbages-feed': 'Choux fourragers',
  'cabbages-red': 'Chou rouge',
  'camelina': 'Cameline',
  'caraway': 'Carvi',
  'cardoon': 'Cardons',
  'carob': 'Caroube',
  'carrots': 'Carottes',
  'carrots-feed': 'Carottes fourragères',
  'carrots-young': 'Jeunes carottes',
  'cassava': 'Manioc',
  'castor-bean': 'Ricin',
  'castration-costs': 'Frais de castration (€)',
  'cauliflowers': 'Choux-fleur',
  'cedrate': 'Cédrat',
  'celeri-leaves': 'Céleri à côtes',
  'celeri-root': 'Céleri rave',
  'celery': 'Céleri rave',
  'cereals': 'Céréales',
  'chamomile': 'Camomille',
  'chard': 'Bettes à cardes',
  'cherries': 'Cerises',
  'chervil': 'Cerfeuil',
  'chestnut': 'Châtaigne',
  'chia': 'Chia',
  'chickpeas': 'Pois chiche',
  'chicory': 'Chicorée',
  'chilli': 'Piments',
  'chives': 'Ciboulette',
  'citrus': 'Agrumes',
  'claim-all': 'Définitive cloture mission',
  'claim-delivery': 'Définitive cloture résultats récolte',
  'claim-some': 'Définitive cloture culture',
  'claims-visit': 'Visite définitive',
  'claims-visit-file': 'estimation',
  'clementines': 'Clémentines',
  'clover': 'Trèfle',
  'cocoa': 'Cacao',
  'coffea': 'Café',
  'coffee': 'café',
  'cold': 'Coup de froid',
  'compensation-costs': 'Frais non-engagés (€)',
  'connected': 'L\'application est connectée à internet',
  'coriander': 'Coriandre',
  'corn': 'Maïs',
  'corn-grain': 'Maïs (Grain)',
  'corn-popcorn': 'Mais popcorn',
  'corn-seeds': 'Maïs (Semences)',
  'corn-seeds-fertile': 'Mais variétés fertiles',
  'corn-seeds-sterile': 'Mais variétés stériles',
  'corn-silage': 'Maïs (Ensilage)',
  'corn-sweet': 'Mais doux',
  'corn-sweet-seeds-fertile': 'Mais doux variétés fertiles',
  'corn-sweet-seeds-sterile': 'Mais doux variétés stériles',
  'corn-waxy': 'Mais waxy',
  'cornsalad': 'Mâche, doucette',
  'costing': 'Coût',
  'cotton': 'Coton',
  'cover-crop': 'Culture dérobée',
  'cranberry': 'Canneberge',
  'cress': 'Cresson',
  'crop-mon': 'Suivi de cultures',
  'crop_condition': 'Etat des cultures',
  'crosne': 'Crosne',
  'cucumbers': 'Concombres',
  'cumin': 'Cumin',
  'cynara': 'Cynara',
  'dactylis': 'Dactyle',
  'damson': 'Quetsches',
  'declared-pre-risk': 'Déclaré pré-risque',
  'decree': 'Arrêté préfectoral',
  'development': '4. Développement des parties végétales de récolte',
  'dill': 'Aneth',
  'disease': 'Maladie',
  'downpour': 'Pluie violente',
  'drought': 'Sécheresse',
  'eggplant': 'Aubergines',
  'elder': 'Sureau',
  'endives': 'Endives',
  'excess-water': 'Excès d’eau',
  'excessive-air-humidity': 'Humidité excessive',
  'expected-loss': 'Coûts moyens d\'indemnités',
  'fallow-land-perennial': 'Jachère fixe',
  'fallow-land-spring': 'Jachère temporaire',
  'false': 'Non',
  'female': 'Femelle',
  'fennel': 'Fenouil',
  'fenugreek': 'Fenugrec',
  'festuca': 'Fétuque',
  'field-beans': 'Féveroles',
  'field-beans-seeds': 'Féveroles (Semences)',
  'field-crops': 'Grandes Cultures',
  'field-mustard': 'Navette',
  'figs': 'Figues',
  'fire': 'Feu',
  'first-crop': 'Première récolte',
  'flageolet-bean': 'Flageolets',
  'flood': 'Inondation',
  'flood-risk': 'Zones inondables',
  'flowering': '6. Floraison',
  'fodder-legumes': 'Légumineuses fourragères',
  'forest': 'Forêt',
  'foxtail-millet': 'Moha',
  'frost': 'Gel',
  'fruit-development': '7. Développement des grains / fruits',
  'full': 'Accès total',
  'garlic': 'Ail',
  'gentiana': 'Gentiane',
  'germination': '0. Germination / développement des cotylédons / bourgeonnement',
  'gherkins': 'Cornichons',
  'ginger': 'Gingembre',
  'go': 'Go!',
  'good': 'Bon',
  'gooseberries': 'Groseilles à maquereau',
  'gourd': 'Courge',
  'grapefruit': 'Pamplemousse',
  'grapes': 'Vigne',
  'grapes-food': 'Raisin de table',
  'grapes-juice': 'Jus de raisin',
  'grapes-must': 'Moût de raisin',
  'grapes-nursery': 'Pépinière de vigne',
  'grapes-table': 'Raisin de table',
  'grassland': 'Prairies',
  'grassland-perennial': 'Prairies (pérennes)',
  'green-peas': 'Petits pois',
  'group-allocator': 'Accès limité',
  'hail': 'Grêle',
  'harvest': 'Récolté',
  'harvest_crop': 'Type de culture',
  'harvested': 'Récolté',
  'harvesting-costs': 'Frais de récolte (€)',
  'hazelnuts': 'Noisettes',
  'heat-wave': 'Vague De Chaleur',
  'heatstroke': 'Coup de chaleur',
  'heavy-rainfall': 'Fortes précipitations',
  'hemp': 'Chanvre',
  'hemp-paper': 'Chanvre papier',
  'hemp-seeds': 'Chanvre pour graine (chènevis)',
  'high-res-mapbox': 'Haute Rés.',
  'historical-yield': 'Ratio de rendement historique',
  'hop': 'Houblon',
  'hyssop': 'Hysope',
  'inflorescence': '5. Apparition de l\'inflorescence / épiaison',
  'interfield': 'Comparaison parcellaire',
  'interfield-a': 'Pas de végétation',
  'interfield-b': 'Végétation éparse',
  'interfield-c': 'Végétation inférieure',
  'interfield-d': 'Végétation modérée',
  'interfield-e': 'Végétation modérée à dense',
  'interfield-f': 'Végétation dense',
  'interfield-g': 'Forte activité végétative',
  'interfield-h': 'Très forte activité végétative',
  'interfield-i': 'Activité végétative maximale',
  'interyield': 'Prédiction de rendement',
  'interyield-a': 'Rendement extrêmement bas',
  'interyield-b': 'Rendement très bas',
  'interyield-c': 'Rendement bas',
  'interyield-d': 'Rendement bas à modéré',
  'interyield-e': 'Rendement modéré',
  'interyield-f': 'Rendement modéré à élevé',
  'interyield-g': 'Rendement élevé',
  'interyield-h': 'Rendement très élevé',
  'interyield-i': 'Rendement maximal',
  'intrafield': 'Variabilité intra-parcellaire',
  'itemsToUpload': 'Il reste des éléments à transemttre à la base de données.',
  'jerusalem-artichoke': 'Topinambours',
  'kale': 'Choux verts, frisés, rouges',
  'kiwi': 'Kiwis',
  'lack-of-sunlight': 'Manque de rayonnement',
  'lavandin': 'Lavandin',
  'lavender': 'Lavande',
  'leaf-development': '1. Développement des feuilles',
  'leek': 'Poireaux',
  'lemon-balm': 'Mélisse',
  'lemons': 'Citrons',
  'lentils': 'Lentilles',
  'lentils-berry': 'Lentille verte du Berry',
  'lentils-puy': 'Lentille verte du Puy',
  'lettuce': 'Laitue',
  'linden': 'Tilleul',
  'lineseed-textile-seeds': 'Lin textile (Semences)',
  'linseed': 'Lin',
  'linseed-oilseed': 'Lin oléique',
  'linseed-textile': 'Lin textile',
  'linseed-textile-fiber': 'Lin textile (fibres)',
  'linseed-textile-seeds': 'Lin textile (semences)',
  'linseed-textile-straw': 'Lin textile (paille)',
  'lotus': 'Lotier corniculé',
  'lupin': 'Lupin',
  'lupin-sweet': 'Lupin doux',
  'male': 'Mâle',
  'mandarins': 'Mandarines',
  'mangos': 'Mangues',
  'meadow-grass': 'Pâturin',
  'medicinal-plants': 'Plantes médicinales',
  'melilotus': 'Mélilot',
  'melons': 'Melons',
  'membership_type': 'Type d\'accès',
  'meslin': 'Méteil',
  'millet': 'Millet',
  'mint': 'Menthe',
  'mirabelles': 'Mirabelles',
  'miscanthus': 'Miscanthus',
  'mixed-cereal': 'Autres céréales mélangées',
  'monitoring-visit': 'Visite de suivi',
  'monitoring-visit-file': 'surveillance',
  'monitoring-with-review': 'suivi avec gestion',
  'monitoring-with-review-provisioning': 'provisionnement avec gestion',
  'monitoring-without-review': 'suivi sans gestion',
  'monitoring-without-review-provisioning': 'provisionnement sans gestion',
  'month_0': 'Jan',
  'month_1': 'Fév',
  'month_10': 'Nov',
  'month_11': 'Déc',
  'month_2': 'Mar',
  'month_3': 'Avr',
  'month_4': 'Mai',
  'month_5': 'Jun',
  'month_6': 'Jul',
  'month_7': 'Aoû',
  'month_8': 'Sep',
  'month_9': 'Oct',
  'most-recent': '+ récente',
  'mustard': 'Moutarde',
  'nashi-pear': 'Nashis',
  'nectarines': 'Nectarines',
  'noOfflineAreas': 'Pour utiliser GreenTriangle hors ligne, il est indispensable de télécharger les cartes sur votre appareil. Pressez Oui pour le faire maintenant.',
  'none': 'Aucune',
  'normal': 'Moyen',
  'notConnected': 'L\'application n\'est pas connectée à internet',
  'not_available': 'Non disponible',
  'not_planted': 'Non semé',
  'nursery': 'Pépinière',
  'oats': 'Avoine',
  'oats-seeds': 'Avoine (Semences)',
  'oilseeds': 'Oléagineux',
  'olives': 'Olives',
  'onions': 'Oignons',
  'oranges': 'Oranges',
  'orchard': 'Verger de plus de 5 ans',
  'orchard-pre-prod': 'Verger de 5 ans ou moins',
  'orchards': 'Vergers',
  'oregano': 'Origan',
  'other': 'autre',
  'other-aromatic-medicinal': 'Autres plantes aromatiques ou médicinales',
  'other-berries': 'Autres baies',
  'other-cereals': 'Autres céréales',
  'other-feed': 'Autres fourrages',
  'other-fruits': 'Autres fruits',
  'other-industry': 'Autres cultures industrielles',
  'other-legumes': 'Autres protéagineux',
  'other-loss': 'Autres (pertes)',
  'other-oilseed': 'Autres oléagineux',
  'other-perennial-fruits': 'Autres fruits (vivaces)',
  'other-textile': 'Autres plantes textile',
  'other-vegetables': 'Autres légumes',
  'parnsip': 'Panais',
  'parsley': 'Persil',
  'peach-blood': 'Pêche sanguine',
  'peach-flat': 'Pêche plate',
  'peaches': 'Pêches',
  'peaches-pre-prod': 'Pêches (verger de 5 ans ou moins)',
  'peanuts': 'Cacahuètes',
  'pears': 'Poires',
  'pears-pre-prod': 'Poires (verger de 5 ans ou moins)',
  'peas': 'Pois',
  'peas-feed': 'Pois fourrager',
  'peas-seeds': 'Pois (Semences)',
  'peas-split': 'Pois cassés',
  'pepper': 'Poivrons',
  'perennial': 'Pérenne',
  'perennial-crops': 'Cultures pérennes',
  'persimmon': 'Kakis',
  'pest': 'Ravageurs',
  'pickle': 'Cornichons',
  'pineapples': 'Ananas',
  'pistachio': 'Pistache',
  'pleaseCheckForm': 'Veuillez vérifier les champs du formulaire et réessayer.',
  'plum': 'Prunes',
  'policy': 'Politique',
  'polyculture': 'Cultures multiples',
  'poor': 'Mauvais',
  'poppy': 'Pavot',
  'potatoes': 'Pommes de terre',
  'potatoes-industry': 'Pomme de terre d’industrie',
  'potatoes-seeds': 'Pommes de terres (Semences)',
  'potatoes-starch': 'Pomme de terre féculière',
  'potatoes-ware': 'Pommes de terre de conservation',
  'precipitation': 'Précipitations totales',
  'predicted-yield': 'Ratio de rendement prédit',
  'preinspection-none': 'Evaluation des risques',
  'preinspection-visit': 'Pré-inspection',
  'preinspection-visit-file': 'préinspection',
  'production-costs': 'Frais de production (€)',
  'productivity': 'Productivité',
  'pulses': 'Légumineuses',
  'pumpkin': 'Citrouilles',
  'purification-costs': 'Frais d\'épuration (€)',
  'quinces': 'Coings',
  'quinoa': 'Quinoa',
  'quote_accepted': 'Proposition acceptée',
  'quote_created': 'Proposition créée',
  'quote_rejected': 'Proposition rejetée',
  'radishes': 'Radis',
  'rainstorm': 'Pluie torrentielle',
  'rapeseed': 'Colza',
  'rapeseed-feed': 'Colza fourrager',
  'rapeseed-seeds': 'Colza (Semences)',
  'raspberry': 'Framboises',
  'ray-grass': 'Ray-grass',
  'redcurrant': 'Grosseillier à grappes',
  'remaining-crops': 'Autres cultures',
  'replanting': 'Base + Replantation',
  'resowing-costs': 'Frais de re-semis (€)',
  'rhubarb': 'Rhubarbe',
  'rice': 'Riz',
  'ripening': '8. Maturation des grains / fruits',
  'rocket': 'Roquette',
  'root-crops': 'Tubercules',
  'rosemary': 'Romarin',
  'rutabaga': 'Rutabaga',
  'rye': 'Seigle',
  'rye-seeds': 'Seigle (Semences)',
  'safflower': 'Carthame',
  'sage': 'Sauge',
  'sainfoin': 'Sainfoin',
  'salad': 'Salade',
  'salsify': 'Salsifis',
  'salvia': 'Sauge',
  'sandstorm': 'Tempête de sable',
  'satellite': 'Satellite',
  'satureja': 'Sarriette',
  'second-crop': 'Seconde récolte',
  'senescence': '9. Sénescence ou début de la période de dormance',
  'shallots': 'Echalotes',
  'side-shoot': '2. Formation des pousses secondaires / tallage',
  'snow': 'Poids de la neige/givre',
  'snow-peas': 'Pois mange-tout',
  'soil-moisture': 'Humidité du sol',
  'sorghum': 'Sorgho (Grain)',
  'sorghum-feed': 'Sorgho fourrager',
  'sorghum-seeds': 'Sorgho (Semences)',
  'sorghum-silage': 'Sorgho (Ensilage)',
  'sorrel': 'Oseille',
  'soybeans': 'Soja',
  'soybeans-seeds': 'Soja (Semences)',
  'spelt': 'Épeautre',
  'spinach': 'Epinards',
  'spring': 'Printemps',
  'sprouts': 'Choux de Bruxelles',
  'squash': 'Courge',
  'squash-max': 'Potiron',
  'static-data': 'Données du pays',
  'stevia': 'Stevia',
  'storm': 'Tempête',
  'strawberry': 'Fraises',
  'sugar-beet': 'Betterave',
  'sugar-beet-feed': 'Betteraves fourragères',
  'sugar-beet-seeds': 'Betteraves porte graine',
  'sugar-cane': 'Canne à sucre',
  'sugar-cane-feed': 'Canne à sucre fourragère',
  'sunburn': 'Coup de soleil',
  'sunflower': 'Tournesol',
  'sunflower-oilseed': 'Tournesol oléique',
  'sunflower-seeds': 'Tournesol (Semences)',
  'surface-temperature': 'Risque de gel et chaleur (sol)',
  'switchgrass': 'Switchgrass',
  'syncDataDb': 'Synchronisation des données',
  'tarragon': 'Estragon',
  'temperature': 'Risque de gel et chaleur (air)',
  'thistle': 'Chardon',
  'thyme': 'Thym',
  'timothy-grass': 'Fléole',
  'tobacco': 'Tabac',
  'tobacco-blond': 'Tabac blond',
  'tobacco-brown': 'Tabac brun',
  'tobacco-burley': 'Tabac variété Burley',
  'tobacco-virginie': 'Tabac variété Virginie',
  'tomatoes': 'Tomates',
  'tomatoes-processing': 'Tomate de transformation',
  'tons': 't',
  'tornado': 'Tourbillon',
  'triticale': 'Triticale',
  'triticale-seeds': 'Triticale (Semences)',
  'true': 'Oui',
  'turmeric': 'Curcuma',
  'turnip': 'Navets',
  'turnip-feed': 'Navets fouragers',
  'undeclared-pre-risk': 'Pré-risque non déclaré',
  'undeclared-risk': 'Aléa climatique non déclaré',
  'unguaranteed-risk': 'Aléa climatique non garanti',
  'unit': 'unité',
  'unknown': 'Inconnue',
  'urtica': 'Ortie',
  'user-admin': 'Gestionnaire des utilisateurs',
  'valerian': 'Valériane',
  'validation-visit': 'Visite initiale / de survenance',
  'validation-visit-file': 'occurrence',
  'validation-with-review': 'Survenance avec gestion',
  'validation-without-review': 'Survenance sans gestion',
  'vanilla': 'Vanille',
  'vegetables': 'Légumes',
  'vegetation': 'Anomalie de vitalité des plantes',
  'vegetative-growth': '3. Élongation de la tige / formation de la rosette',
  'verbena': 'Verveine',
  'very_good': 'Très bon',
  'very_poor': 'Très mauvais',
  'vetch': 'Vesce',
  'vin-de-table': 'Vin de table',
  'waiting-period-d3-pre-risk': 'Délai d\'attente (« D3 »)',
  'walnuts': 'Noix',
  'watermelon': 'Pastèques',
  'weeds': 'Adventices',
  'wheat': 'Blé (Tendre)',
  'wheat-einkorn': 'Engrain',
  'wheat-feed': 'Blé fourrager',
  'wheat-hard': 'Blé (dur)',
  'wheat-hard-seeds': 'Blé dur (Semences)',
  'wheat-seeds': 'Blé tendre (Semences)',
  'wheat-top': 'Blé (Tendre améliorant)',
  'wild-animals': 'Gibier',
  'wind': 'Vent',
  'winter': 'Automne/Hiver',
  'yams': 'Patates douces',
  'zucchini': 'Courgette',
  '-': '-',
  'BRL': 'R$',
  'CropTypesSigned': 'Type(s) de culture (Signé par l\'exploitant)',
  'EUR': '€',
  'Telepac': 'Télépac',
  'UnknownLoss': 'Inconnue (Raison de la perte)',
  'acres': 'ac',
  'added_by': 'Ajouté par',
  'added_on': 'Ajouté le',
  'arrobas-bra': '@/ha',
  'bags': 'sc',
  'bags-per-hectare': 'sc/ha',
  'bales-per-acre': 'bales/ac',
  'bra_agree_with_result': 'Você concorda com o resultado apurado?',
  'bra_average': 'Média (tipo 2)',
  'bra_clay': 'Argiloso (tipo 3)',
  'bra_insured_facilitated': 'O segurado facilitou o processo de amostragem? (Se não, explique nos comentários)',
  'bra_insured_implemented_zarc_window': 'O segurado implantou a cultura dentro da janela de indicação do ZARC? (Se parcialmente sim, por favor, explique nos comentários)',
  'bra_last_liming_plastering_date': 'Quando foi feita a última calagem e gessagem na área segurada? (descrever a frequência da análise do solo, qual o perfil da camada do solo é trabalhada, tipo de calcário utilizado, faz uso de AP, já utilizou gesso na lavoura, etc)',
  'bra_losses_from_rnc': 'O perito constatou prejuízos por RNC? Explique os percentuais?',
  'bra_manual': 'Manual',
  'bra_mechanized': 'Mecanizada',
  'bra_necessary_to_subdivide': 'Foi necessário a subdivisão dos itens segurados? (Se sim, explique nos comentários)',
  'bra_planned_location': 'A lavoura foi implantada em local previsto? (Se não, explique nos comentários)',
  'bra_recommended_risk_location': 'A cultivar/variedade semeada é recomendada para o local do risco, conforme ZARC? (Se parcialmente sim, por favor, explique nos comentários)',
  'bra_sampling_type': 'Qual o tipo de amostragem utilizada para estimativa de produtividade da lavoura?',
  'bra_sandy': 'Arenoso (tipo 1)',
  'bra_soil_type': 'Tipo de solo',
  'bra_treatments_effective': 'Os tratos culturais foram efetivos no manejo da cultura?',
  'bra_uniform_management': 'O manejo empregado na lavoura segurada é igual das áreas de recurso próprio? (Se não, explique nos comentários)',
  'bra_visual': 'Visual',
  'brl': 'R$',
  'brl-per-bag': 'R$/sc',
  'brl-per-hectare': 'R$/ha',
  'bushels-per-acre': 'bu/ac',
  'centimeters': 'cm',
  'chf': 'Fr.',
  'claim_id': 'Sinistre',
  'corn-grain-white': 'Maïs (Grain) (white)',
  'corn-grain-yellow': 'Maïs (Grain) (yellow)',
  'corn-silage-white': 'Maïs (Ensilage) (white)',
  'corn-silage-yellow': 'Maïs (Ensilage) (yellow)',
  'crop_id': 'Type de culture',
  'custom-a': 'Végétation médiane de la municipalité',
  'decitons-per-hectare': 'qx/ha',
  'development-cereals': '4. Gonflement',
  'development-sorghum': '4. La feuille étendard est visible',
  'development-sugar-beet': '4. Croissance de la partie racinaire',
  'eur': '€',
  'eur-per-hectare': '€/ha',
  'eur-per-hectoliter': '€/hL',
  'eur-per-ton': '€/t',
  'farm_id': 'Exploitation',
  'feet': 'ft',
  'flowering-cereals': '6. Floraison',
  'flowering-corn': '6. Floraison',
  'flowering-fruits': '6. Floraison',
  'flowering-grapes': '6. Floraison',
  'flowering-legumes': '6. Floraison',
  'flowering-rapeseed': '6. Floraison',
  'flowering-sorghum': '6. 50% de floraison',
  'flowering-soybeans': '6. Phase de croissance et de reproduction (floraison et première gousse)',
  'flowering-sunflower': '6. Floraison',
  'fruit-development-cereals': '7. Développement des graines',
  'fruit-development-corn': '7. Développement du fruit',
  'fruit-development-fruits': '7. Développement des fruits',
  'fruit-development-grapes': '7. Développement des fruits',
  'fruit-development-legumes': '7. Développement du fruit',
  'fruit-development-rapeseed': '7. Développement des siliques',
  'fruit-development-sorghum': '7. Stade grain tendre',
  'fruit-development-soybeans': '7. Première graine',
  'fruit-development-sunflower': '7. Développement du fruit',
  'germination-cereals': '0. Germination, levée',
  'germination-corn': '0. Germination',
  'germination-fruits': '0. Développement des bourgeons',
  'germination-grapes': '0. Bourgeonnement ou débourrement',
  'germination-legumes': '0. Germination',
  'germination-rapeseed': '0. Germination',
  'germination-sorghum': '0. Emergence',
  'germination-soybeans': '0. Germination, levée',
  'germination-sugar-beet': '0. Germination, levée',
  'germination-sunflower': '0. Germination',
  'grams': 'gr',
  'harvest_year': 'Année de récolte',
  'hectares': 'ha',
  'hectoliters-per-hectare': 'hl/ha',
  'images': 'Photos',
  'inches': 'in',
  'inflorescence-cereals': '5. Epiaison',
  'inflorescence-corn': '5. Apparition de l\'inflorescence',
  'inflorescence-fruits': '5. Apparition de l\'inflorescence',
  'inflorescence-grapes': '5. Apparition des inflorescences',
  'inflorescence-legumes': '5. Apparition de l\'inflorescence',
  'inflorescence-rapeseed': '5. Apparition de l\'inflorescence',
  'inflorescence-sorghum': '5. Stade d\'épiaison',
  'inflorescence-sunflower': '5. Apparition de l\'inflorescence',
  'kilograms': 'kg',
  'kilograms-per-hectare': 'kg/ha',
  'leaf-development-cereals': '1. Développement des feuilles',
  'leaf-development-corn': '1. Développement des feuilles',
  'leaf-development-fruits': '1. Développement des feuilles',
  'leaf-development-grapes': '1. Développement des feuilles',
  'leaf-development-legumes': '1. Développement des feuilles',
  'leaf-development-rapeseed': '1. Développement des feuilles',
  'leaf-development-sorghum': '1. Stade trois feuilles',
  'leaf-development-soybeans': '1. Phase végétative active (premier noeud)',
  'leaf-development-sugar-beet': '1. Développement des feuilles',
  'leaf-development-sunflower': '1. Développement des feuilles',
  'loss_cause': 'Raison de la perte',
  'meters': 'm',
  'ounces': 'oz',
  'percent': '%',
  'pln': 'zł',
  'policy_id': 'Numéro de contrat',
  'policy_number': 'Numéro de contrat',
  'ripening-cereals': '8. Maturation des graines',
  'ripening-corn': '8. Maturation des graines',
  'ripening-fruits': '8. Maturation des fruits et graines',
  'ripening-grapes': '8. Maturation des baies',
  'ripening-legumes': '8. Maturation des graines',
  'ripening-rapeseed': '8. Maturation des graines',
  'ripening-sorghum': '8. Stade grain dur',
  'ripening-soybeans': '8. Phase de maturation, grossissement des graines',
  'ripening-sunflower': '8. Maturation des graines',
  'senescence-fruits': '9. Sénescence, début de la phase de repos ou dormance',
  'senescence-grapes': '9. Sénéscence ou début du repos végétatif',
  'senescence-sorghum': '9. Maturité physiologique',
  'senescence-soybeans': '9. Maturité',
  'senescence-sunflower': '9. Sénescence',
  'side-shoot-cereals': '2. Tallage',
  'side-shoot-sorghum': '2. Stade cinq feuilles',
  'square-centimeters': 'cm²',
  'square-feet': 'ft²',
  'square-inches': 'in²',
  'square-meters': 'm²',
  'thousand-kernel-weight-grams': 'Poids de mille grains',
  'ton': 't',
  'tons-per-acre': 't/ac',
  'tons-per-hectare': 't/ha',
  'units-per-acre': '/ac',
  'units-per-ft2': '/ft²',
  'units-per-hectare': '/ha',
  'units-per-m2': '/m²',
  'usd': '$',
  'user-admin-static-data': 'Données du pays',
  'user_group': 'Portefeuille',
  'vegetative-growth-cereals': '3. Montaison, élongation de la tige principale',
  'vegetative-growth-corn': '3. Elongation de la tige principale',
  'vegetative-growth-fruits': '3. Développement des pousses',
  'vegetative-growth-legumes': '3. Elongation de la tige principale',
  'vegetative-growth-rapeseed': '3. Elongation de la tige principale',
  'vegetative-growth-sorghum': '3. Différenciation du point de croissance',
  'vegetative-growth-soybeans': '3. Phase végétative active à partir du deuxième noeud',
  'vegetative-growth-sugar-beet': '3. Croissance de la rosette, couverture du sol',
  'visit_type': 'Type de visite'
} as const;

export default function t(k: I18nSimpleKey|I18nParametric): string {
  if (typeof k == 'string' || typeof k == 'boolean') { return msgs[k]; }
  if (k === null || k == undefined) { console.error('t called with null or undefined!'); return '(∅)'; }
  switch (k.type) {
    case 'AddFarmTimeseriesMaxLimitError': return `Vous avez atteint la limite de ${k.count} exploitations mises en cache hors ligne. Veuillez en supprimer un pour continuer.`;
    case 'AdjusterSampledFarm': return `${k.added_by} exploitation échantillonnée ${k.farm_name}`;
    case 'AllocateRemainingHarvestArea': return `Vous pouvez allouer un autre ${intl_num_2(k.remainingArea)}` + t(k.unit) + `.`;
    case 'AreaIsKm2': return `Cette surface est de ${intl_num(k.area)} km² (circa ${intl_int(k.mb)}Mo).`;
    case 'CurFieldPolicyDesc': return `Contrat actuel de la parcelle: ${k.policy_number}`;
    case 'CurPortfolio': return `Portefeuille: ${k.user_group}`;
    case 'DeleteAlreadyAssignedVisitWarning': return `Vous essayez également de supprimer une visite attribuée à ${k.count} autres LA.`;
    case 'DeletedUserX': return `Utilisateur supprimé "${k.user_name}"`;
    case 'DistKmAway': return `A ${intl_num(k.dist_km)}km`;
    case 'DownloadExplain': return `Sélectionner une zone à télécharger pour utilisation hors ligne. Aire maximale: ${intl_int(k.limit_km_2)}km². Si besoin, veuillez télécharger plusieurs zones.`;
    case 'DuplicateFieldId': return `La parcelle ${k.external_field_id} a déjà été ajoutée.`;
    case 'DuplicateSubplotWarning': return `Il existe déjà un Référence de la sous-parcelle : ${k.external_harvest_id} pour cette ferme et Année de récolte. Pour y ajouter un échantillon, veuillez modifier votre sélection sur la première page du formulaire d'échantillon ou choisir un autre identifiant de sous-parcelle.`;
    case 'EarsDetectedCount': return `${k.count} épis détectées`;
    case 'ErrorDeletingUserXY': return `Erreur lors de la suppression de "${k.user_name}: ${k.error_message}`;
    case 'EstimatedYieldOverrideInfo': return `Le rendement estimé a été remplacé pour la dernière fois le ${k.date}. Notez que cette action remplace le rendement estimé agrégé des échantillons ci-dessous.`;
    case 'EstimatedYieldValueUnit': return `Résiduel: ${intl_num_2(k.val)}` + t(k.unit);
    case 'ExistingHarvestContainsSamples': return `La récolte existante contient ${k.count} échantillon(s).`;
    case 'ExoCropUnsupported': return `Cette fonctionnalité ne prend actuellement pas en charge ` + t(k.crop) + `.`;
    case 'FeasibleYieldValueUnit': return `Réalisable: ${intl_num_2(k.val)}` + t(k.unit);
    case 'FieldFormPickedOneOfMany': return `Attention: cet échantillon va être ajouté uniquement à la première parcelle des ${intl_int(k.numFields)}.`;
    case 'FileSizeTooBig': return `Ce fichier est trop lourd. Veuillez sélectionner un fichier de moins de ${k.file_size}Mo.`;
    case 'ImageLocationTooFar': return `L'image #${k.imageIdx} a été prise ${intl_num(k.distanceMeters)}` + t(k.unit) + ` à partir de l'emplacement de l'échantillon.`;
    case 'InsuredYieldValueUnit': return `Garanti: ${intl_num_2(k.val)}` + t(k.unit);
    case 'InvalidBoolError': return `Cette valeur n'a pas pu être analysée comme une valeur booléenne : ${k.val}`;
    case 'InvalidColumnsError': return `Les colonnes suivantes ne sont pas autorisées : ${k.columns}`;
    case 'LossValue': return `Perte: ${intl_num(k.yield_loss_pct)}%`;
    case 'LossesMismatchWarning': return `La somme des pertes est trop importante: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` sur l'estimation.`;
    case 'LossesToAllocate': return `Perte à affecter: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` de ${intl_num_2(k.total)}` + t(k.unit) + ` perte totale.`;
    case 'LossesToAllocateShortMsg': return `Perte à affecter : ${intl_num_2(k.deltaVal)}` + t(k.unit);
    case 'NFarmsSelected': return `${intl_int(k.numFarms)} exploitations sélectionnées`;
    case 'NPoliciesSelected': return `${intl_int(k.numPolicies)} contrats sélectionnés`;
    case 'NoAppInstalledToViewFile': return `Aucune application installée pour afficher ${k.file_name}`;
    case 'NoDeleteClaim': return `Avant de supprimer cette sinistre, vous devez supprimer ses ${intl_int(k.visitCount)} visites.`;
    case 'NoDeleteFarm': return `Avant de supprimer cette batterie de serveurs, vous devez supprimer ses champs ${intl_int(k.fieldCount)}, ses échantillons ${intl_int(k.sampleCount)} et ses sinistre ${intl_int(k.claimCount)}.`;
    case 'NoDeleteField': return `Avant de supprimer cette parcelle, vous devez d'abord supprimer ses ${intl_int(k.sampleCount)} échantillons.`;
    case 'NoDeleteHarvest': return `Avant de supprimer cette campagne, vous devez supprimer ${intl_int(k.sampleCount)} échantilllon(s).`;
    case 'NoDeletePolicy': return `Avant de supprimer cette politique, vous devez supprimer ses ${intl_int(k.claimCount)} sinistres.`;
    case 'NonUniqueFarm': return `Deux fermes détectées pour ${k.key}, mais avec des détails différents : « ${k.x1} » contre « ${k.x2} ».`;
    case 'NumPhotos': return `${intl_int(k.num_photos)} Photos`;
    case 'OfflineAreaDownloadMore': return `Il reste ${k.count} zones hors ligne.`;
    case 'OfflineAreaMaxLimit': return `Vous avez atteint la limite de ${k.count} zones. Veuillez désélectionner certaines zones pour continuer.`;
    case 'OverrideSampleInfo': return `Cette substitution a été mise à jour pour la dernière fois le ${k.date}. Notez que les valeurs de substitution remplaceront les valeurs des échantillons réguliers pour obtenir les valeurs agrégées au niveau des cultures.`;
    case 'PasswordResetSent': return `Un email de réinitialisation a été envoyé à ${k.email}`;
    case 'PlantDensityValueUnit': return `Densité des plantes: ${intl_num(k.val)}` + t(k.unit);
    case 'PolicyDesc': return `Contrat ${k.policy_number}`;
    case 'PredictedYieldValueUnit': return `Prédit : ${intl_num_2(k.val)}` + t(k.unit);
    case 'ReportContainsTooManyFields': return `Ce rapport est trop volumineux, avec ${intl_int(k.numFields)} parcelles. Veuillez procéder à une sélection parmi les dates de visites et les types de culture.`;
    case 'ReportNoHarvests': return `Cette exploitation a ${intl_int(k.numFields)} parcelles, mais n'a pas d'information sur les cultures!`;
    case 'ResetMsg': return `Un email vous a été envoyé à ${k.email}`;
    case 'SampleAbrv': return `${intl_int(k.count)} s.`;
    case 'SampleLocationTooFar': return `L'emplacement de l'échantillon est ${intl_num_2(k.val)}` + t(k.unit) + ` à partir de votre emplacement de départ. Si vous souhaitez utiliser votre position, appuyez sur "Utiliser ma localisation" ci-dessus.`;
    case 'SampleLossesWarning': return `Les pertes actuelles de ce champ sont de ${k.current}. Avec cet échantillon, il aura des pertes de ${k.new}.`;
    case 'SampleYieldWarning': return `Merci d’entrer la valeur ${k.column1} ou la valeur ${k.column2}, si possible.`;
    case 'SearchAddTipDesc': return `Si vous voulez cherchez avec plusieurs critères (p.e. parcelles de blé issues du portefeuille ${k.firstUserGroup}), vous pouvez appuyez sur l'icône ⊕ pour ajouter un critère.`;
    case 'SearchExampleDescUserGroup': return `Entrez le nom d'un portefeuille (e.g. ${k.firstUserGroup}) pour afficher tous les éléments de ce portefeuille`;
    case 'SearchFoundCount': return `${intl_int(k.farmCount)} exploitations, ${intl_int(k.fieldCount)} parcelles, et ${intl_int(k.sampleCount)} échantillons identifiés.`;
    case 'SearchInfoText': return `Vous pouvez trouver des exploitations, parcelles, échantillons et contrats en cherchant ici. Si vous voulez chercher plusieurs termes (p.ex. parcelle de blé dans le portefeuille ${k.firstUserGroup}) vous pouvez appuyer sur l'icône ⊕. Chaque recherche aura un effet de filtre qui réduit le nombre de résultats. Veuillez consulter les exemples de recherche ci-dessous.`;
    case 'SearchResultAddedBy': return `échantillons ajoutés par ${k.id}`;
    case 'SearchResultAddress': return `Centrer la carte sur ${k.address}`;
    case 'SearchResultCropCondition': return `échantillons avec la condition ${k.id}`;
    case 'SearchResultFarm': return `exploitation ${k.farmDesc}`;
    case 'SearchResultHarvestCrop': return `éléments pour ${k.id}`;
    case 'SearchResultHarvestSeason': return `éléments pour la campagne de ` + t(k.id);
    case 'SearchResultHarvestYear': return `éléments pour la campagne de ${k.id}`;
    case 'SearchResultUserGroup': return `Portefeuille ${k.id}`;
    case 'SelectCount': return `${k.count} sélectionnés`;
    case 'SortBy': return `Trier par ${k.column} ` + t(k.id);
    case 'SubsidyError_SUBSIDY_CONFIG_NOT_FOUND': return `La configuration de subvention pour le portefeuille ${k.user_group} est manquante.`;
    case 'SureAddAnotherHarvest': return `Êtes-vous sûr d'ajouter une autre campagne à cette parcelle? , en plus de ${k.harvestDesc}?`;
    case 'SureDeleteFile': return `Êtes-vous sûr de vouloir supprimer ${k.file_name}?`;
    case 'SureDeletePolicy': return `Êtes-vous sûr de vouloir supprimer ce contrat? La référence de ce contrat va être supprimée de ${intl_int(k.fieldCount)} parcelles et de ${intl_int(k.sampleCount)} échantillons, mais les parcelles et les échantillons ne seront pas supprimés.`;
    case 'SureRemoveFarm': return `Êtes-vous sûr de vouloir supprimer cette exploitation et ses ${intl_int(k.fieldCount)} parcelles?`;
    case 'SureRemoveSelectedOfflineFarms': return `Voulez-vous vraiment supprimer l'accès hors connexion pour ${k.count} exploitations sélectionnées?`;
    case 'TelepacReportEmail': return `Un rapport d'importation TelePAC vous a été envoyé à ${k.email}, vous pouvez aussi le télécharger ci-dessous.`;
    case 'TotalAffectedAreaHarvest': return `La superficie totale affectée pour cette récolte sera de ${intl_num_2(k.newArea)}` + t(k.unit) + ` sur une superficie de récolte de ${intl_num_2(k.harvestArea)}` + t(k.unit) + `.`;
    case 'TotalAffectedAreaHarvestPrompt': return `La zone actuellement affectée (échantillons au cours des deux dernières semaines) pour cette récolte est: ${intl_num_2(k.curArea)}` + t(k.unit) + `.`;
    case 'TotalAffectedAreaHarvestPrompt2': return `Voici toutes les zones concernées pour cette récolte: ${k.arr}.`;
    case 'TotalLossPct': return `Perte totale: ${intl_num(k.totalLossPct)}%`;
    case 'TotalSubplotAreaBiggerThanField': return `La superficie totale de la sous-parcelle (${intl_num_2(k.total_subplot_area)} ha) est plus grande que la superficie du champ (${intl_num_2(k.field_area)} ha).`;
    case 'UpdateHarvestAreaMsg': return `Cela mettra à jour la Surface de la culture de la récolte actuelle (${k.desc}) à ${intl_num_2(k.val)}` + t(k.unit) + `.`;
    case 'UpgradeFieldHarvest': return `Ce champ sera attribué à la récolte agricole « ${k.upgradedHarvest} ».`;
    case 'WarnHarvestAlreadyInUse': return `L'enregistrement de vos modifications sur cette culture existante affecterait ${intl_int(k.num_samples)} échantillon(s) déjà existant(s).`;
    case 'WarningDupePolicyNumber': return `Attention: il existe déjà un contrat avec le numéro ${k.policy_number}`;
    case 'YieldMismatchWarning': return `Toutes les pertes ne sont pas prises en compte : ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` manquant.`;
    case 'YourAreGoingToDeleteUsers': return `Vous allez supprimer ${intl_int(k.num_users)} utilisateurs:`;
    case 'downloadingLayers': return `Téléchargement des ${intl_int(k.count)} images satellite pour une utilisation hors ligne.`;
    case 'uploadingImages': return `Envoi des photos vers la base de donnée  (${intl_int(k.left)} left).`;
    case 'AreaUnit': return `${intl_num_2(k.val)}` + t(k.unit);
    case 'AreaUnit4': return `${intl_num_4(k.val)}` + t(k.unit);
    case 'FarmDesc': return `${k.farm_name}, ${k.address}`;
    case 'FarmDesc_reference': return `${k.farm_name}, ${k.address} (${k.external_farm_id})`;
    case 'SampleDesc': return `${k.sample_date}: ${intl_num(k.estimated_yield_val)}` + t(k.estimated_yield_unit) + ` ` + t(k.crop_condition);
    case 'ValueUnit': return `${intl_num(k.val)}` + t(k.unit);
    case 'ValueUnitRange': return `${intl_num(k.min_val)}-${intl_num(k.max_val)}` + t(k.unit);
    case 'YieldUnit': return `${intl_num_2(k.val)}` + t(k.unit);
    case 'YieldUnit4': return `${intl_num_4(k.val)}` + t(k.unit);
  }
}