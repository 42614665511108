import {Tag} from 'antd';
import fontColorContrast from 'font-color-contrast';
import React, {useContext, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {CROP_COLORS} from '../../../src/constants/colors';
import {getBaseCrop, getCrops} from '../../../src/selectors/crops';
import {cropDesc} from '../../../src/text/desc';
import cmp from '../../../src/util/cmp';
import {ApisContext} from '../apis/ApisContext';
import './CropTags.css';

interface CropTags {
  cropIds: null | (null | string)[];
  minimized?: boolean;
}

export interface CropTag {
  cropId: null | string;
  color: undefined | string;
  label: string;
  minimized?: boolean;
}

export const CropTag: React.FC<CropTag> = ({color, label, minimized}) => {
  return (
    <Tag color={color} style={{color: fontColorContrast(color || '#fff')}} className="crop-tag" title={label}>
      {!minimized ? label : '\u00A0'}
    </Tag>
  );
};

// Returns a list of CropTag (props) for a given list of cropIds. To be used to create custom crop lists.
export const useCropTags: (cropIds: CropTags['cropIds']) => Readonly<CropTag[]> = cropIds => {
  const {t} = useContext(ApisContext);
  const crops = useSelector(getCrops);
  return useMemo(
    () =>
      (cropIds || [])
        .map<CropTag>(cropId => ({
          label: cropDesc(t, crops, cropId) || '',
          cropId: cropId ?? 'unknown',
          color: CROP_COLORS[getBaseCrop(crops, cropId)!],
        }))
        .sort(sortByLabel), // Sort by localized crop description.
    [crops, cropIds, t],
  );
};

// Converts a list of cropIds into Tags. Taking care of translating the cropIds and colorizing the tags according to
// the harvestId.
export const CropTags: React.FC<CropTags> = React.memo(props => {
  try {
    const cropTags = useCropTags(props.cropIds);
    return (
      <span className="crop-tags">
        {cropTags.map(t => (
          <CropTag key={t.cropId} {...t} minimized={props.minimized} />
        ))}
      </span>
    );
  } catch (e) {
    console.error('Could not render CropTags for %o', props);
    return null;
  }
});

function sortByLabel(a: CropTag, b: CropTag) {
  return cmp(a.label, b.label);
}
