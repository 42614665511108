// The SubsidyConfiguration interface is used in processBrazilSubsidies.ts to control when and how subsidies for claims,
// policies, and quotes are processed. Config is stored in the request.request column of data.request table with
// type 'subsidy-config' and 'admin' user group.

export interface SubsidyConfiguration {
  //  The config contains ISO timestamp and information about opening (period, datetime from and datetime to).
  //  The process goes like this: Brazil government sends out an email saying the quote processing API will be
  //  open from 21. November 09:00 until 22. November 15:00.
  //  Everytime this happens, it will need to be changed in GT. I believe that ideally the MGA manager
  //  (person that will be approving and rejecting the proposals) can enter/modify this in GT.
  //  From Fabio: The start and end date changes frequently, the government sends communications by email,
  //  saying when the system for award reservation will be opened. The window is usually during Brazilian business hours.
  //  The opening of the window depends on the resources available by the government for the subsidy project,
  //  it may happen that the window does not have a regular frequency. Example: this week, the window will be closed
  //  and will open as soon as the government approves, via the chamber of deputies, resources for subsidy,
  //  in this example, the approved window can be opened on Thursday. The period is usually a time period within the same day,
  //  but the option of having a range between date and time I think is good considering flexibility.
  // Start date time in ISO timestamp for claim processing.
  claim_processing_date_from: string;
  // End date time in ISO timestamp for claim processing.
  claim_processing_date_to: string;
  // Allowed requests per minute for claim processing.
  claim_processing_per_minute: string;

  // Start date time in ISO timestamp for policy processing.
  policy_processing_date_from: string;
  // End date time in ISO timestamp for policy processing.
  policy_processing_date_to: string;
  // Allowed requests per minute for policy processing.
  policy_processing_per_minute: string;

  // Start date time in ISO timestamp for quote processing.
  quote_processing_date_from: string;
  // End date time in ISO timestamp for quote processing.
  quote_processing_date_to: string;
  // Allowed requests per minute for quote processing.
  quote_processing_per_minute: string;
}

export const subsidyConfigRequestType = 'subsidy-config';
