import {EnvironmentOutlined, WarningOutlined} from '@ant-design/icons';
import {ColumnDef, ColumnHelper, ColumnSort, createColumnHelper} from '@tanstack/react-table';
import {Progress, Select} from 'antd';
import React, {useCallback, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import 'react-table/react-table.css';
import {FetcherFunc} from '../../../src/FetcherFunc';
import {PALETTE_COLORS} from '../../../src/constants/colors';
import fastDeepEqual from '../../../src/fast-deep-equal';
import {LngLat} from '../../../src/geo';
import {getLatestSentinelCanonicalDate} from '../../../src/layers/canonical-date';
import {FieldScoringItem, toFieldScoreItem} from '../../../src/models/field-scores';
import {Farm, GetFieldScoringRowsRow, getFieldScoringRows} from '../../../src/models/interfaces';
import {AreaValue} from '../../../src/models/types';
import {IndexedCrops} from '../../../src/redux/reducers/crops';
import {DbFilterState} from '../../../src/redux/reducers/filters';
import {getCrops} from '../../../src/selectors/crops';
import {cropDesc, farmDesc} from '../../../src/text/desc';
import cmp from '../../../src/util/cmp';
import {filtersToRequest} from '../../../src/util/req-util';
import {isAreaValue} from '../../../src/validator-constraints';
import {useApis} from '../apis/ApisContext';
import {Area} from '../components/Area';
import {DownloadButton} from '../components/DownloadButton';
import {InfinityTable, InfinityTableProps, defaultColumnSizes} from '../components/InfinityTable';
import {ErrorBoundary} from '../util/ErrorBoundary';
import './Farms.css';
import './ListView.css';

type SortableColumn = string;
const initialSorting: ColumnSort = {
  id: 'added_on',
  desc: true,
};
const fetchSetSize = 100;

// Make sure to never return undefined as it will eventually trigger an Error in InfinityTable itself, when loading
// data for a different filter set.
const getSortValue: (
  row: FieldScoringItem,
  columnId: SortableColumn,
) => null | boolean | number | string | AreaValue = (row, columnId) => {
  if (columnId === 'added_on') return row.field.added_on;
  if (columnId === 'farm_name') return row.farm.farm_name;
  if (columnId === 'cloud_cover_warning') return getCloudCoverWarning(row);
  const [tableKey, columnKey, yearKey] = columnId.split('/');
  const optional = tableKey === 'cropCoverage' || tableKey === 'regional';
  if (optional && (!row.hasOwnProperty(tableKey) || row[tableKey as keyof FieldScoringItem] === undefined)) {
    return null;
  }
  if (!row.hasOwnProperty(tableKey)) {
    throw new Error('Unsupported sort column (table): ' + columnId);
  }
  const table = row[tableKey as keyof FieldScoringItem];
  if (typeof table === 'string' || table === undefined || !table.hasOwnProperty(columnKey)) {
    throw new Error('Unsupported sort column (column): ' + columnId);
  }
  return (
    (!yearKey ? table[columnKey as keyof typeof table] : table[columnKey as keyof typeof table]?.[yearKey]) ?? null
  );
};
const getRowId = (row: FieldScoringItem): string => row.field.field_id;
const getFieldReference: (row: FieldScoringItem) => null | string = row => row.field.external_field_id;
const getFieldArea: (row: FieldScoringItem) => null | AreaValue = row => row.field.field_area;
const getFieldLocation: (row: FieldScoringItem) => null | LngLat = row => row.field.field_location;
const getFarmName: (row: FieldScoringItem) => Farm = row => row.farm;
const getStabilityScore: (row: FieldScoringItem) => null | number = row => row.stability.score;
const getStabilityVolatility: (row: FieldScoringItem) => null | number = row => row.stability.volatility;
const getRegion: (row: FieldScoringItem) => null | string = row => row.regional?.region ?? null;
const getRelativeProductivity: (row: FieldScoringItem) => null | number = row =>
  row.regional?.relative_productivity ?? null;
const getRelativeStability: (row: FieldScoringItem) => null | number = row => row.regional?.relative_stability ?? null;
const getPercentileProductivity: (row: FieldScoringItem) => null | number = row =>
  row.regional?.percentile_productivity ?? null;
const getPercentileStability: (row: FieldScoringItem) => null | number = row =>
  row.regional?.percentile_stability ?? null;
const getProductivityScore: (row: FieldScoringItem) => null | number = row => row.productivity.score;
const getProductivityHistogram: (row: FieldScoringItem) => null | Record<string, null | number> = row =>
  row.productivity.history;
const getHomogeneityScore: (row: FieldScoringItem) => null | number = row => row.homogeneity.score;
const getHomogeneityHistogram: (row: FieldScoringItem) => null | Record<string, null | number> = row =>
  row.homogeneity.history;
const getCropCoverageHistogram: (row: FieldScoringItem) => null | Record<string, null | number> = row =>
  row.cropCoverage ?? null;
const getCloudCoverWarning: (row: FieldScoringItem) => null | boolean = row =>
  Object.values(row.cloudCover).some(cloudCoverPct => cloudCoverPct !== null && cloudCoverPct > 0.5);
const getCloudCoverHistogram: (row: FieldScoringItem) => null | Record<string, null | number> = row => row.cloudCover;

const columnHelper: ColumnHelper<FieldScoringItem> = createColumnHelper<FieldScoringItem>();

interface FieldScoresState {
  cropId: null | string;
  cropIds: string[];
  hasData: boolean;
  hasCoverage: boolean;
  hasRegional: boolean;
  dataYears: string[];
  coverageYears: string[];
}

const metricColumnWidth = defaultColumnSizes.xs;

interface FetchedData {
  data: FieldScoringItem[];
  coverageYears: string[];
  dataYears: string[];
  hasCoverage: boolean;
  hasRegional: boolean;
  cropIds: string[];
}

async function fetchData(authedFetcher: FetcherFunc, filters: DbFilterState): Promise<FetchedData> {
  const fieldScores: FieldScoringItem[] = [];
  let current: GetFieldScoringRowsRow[],
    val: null | string = null,
    id: null | string = null;
  while (
    (current = await getFieldScoringRows(authedFetcher, {
      ...filtersToRequest(filters),
      ordering: 'added_on-desc',
      continue_from_val: val,
      continue_from_id: id,
      row_count: 1000,
    })).length > 0
  ) {
    fieldScores.push(...current.flatMap(x => toFieldScoreItem(x)));
    if (current.length < 1000) break;
    const last = current[current.length - 1];
    val = last.field!.added_on;
    id = last.field!.field_id;
  }
  const dataYears = new Set<string>();
  const coverageYears = new Set<string>();
  const cropIds = new Set<string>();
  let hasCoverage = false;
  let hasRegional = false;

  fieldScores.forEach(d => {
    Object.keys(d.productivity.history).forEach(dataYears.add, dataYears);
    Object.keys(d.homogeneity.history).forEach(dataYears.add, dataYears);
    Object.keys(d.cloudCover).forEach(dataYears.add, dataYears);
    if (d.cropCoverage) {
      hasCoverage = true;
      Object.keys(d.cropCoverage).forEach(coverageYears.add, coverageYears);
    }
    if (d.regional) {
      hasRegional = true;
    }
    cropIds.add(d.cropId);
  });

  return {
    data: fieldScores,
    dataYears: Array.from(dataYears).sort(),
    coverageYears: Array.from(coverageYears).sort(),
    hasCoverage,
    hasRegional,
    cropIds: Array.from(cropIds),
  };
}

function getSortBy(orderBy: ColumnSort): (a: FieldScoringItem, b: FieldScoringItem) => number {
  if (orderBy.desc) {
    return (a, b) => compare(b, a);
  } else {
    return (a, b) => compare(a, b);
  }
  // We provide a stable sort order by falling back to sort by the unique field_id if the current
  // sort column has identical values which can lead to unstable sort order on subsequent sorts.
  function compare(a: FieldScoringItem, b: FieldScoringItem): number {
    const _a = getSortValue(a, orderBy.id),
      _b = getSortValue(b, orderBy.id);
    if (_a === _b) return sortById(a, b);
    if (_a == null) {
      // Explicitly use == to catch both null and undefined.
      return -1;
    } else if (b == null) {
      return 1;
    }
    // We can safely assume that _a and _b have the same type.
    if (typeof _a === 'number') {
      return _a - (_b as number);
    } else if (typeof _a === 'boolean') {
      return _a === (_b as boolean) ? 0 : _a ? 1 : -1;
    } else if (isAreaValue(_a)) {
      if (!_a) {
        if (!_b) return sortById(a, b);
        return -1;
      } else if (!_b) return 1;
      const compResult = _a.val - (_b as AreaValue).val;
      return compResult !== 0 ? compResult : sortById(a, b);
    } else {
      const compResult = cmp(_a, _b as string);
      return compResult !== 0 ? compResult : sortById(a, b);
    }
  }

  function sortById(a: FieldScoringItem, b: FieldScoringItem): number {
    return getSortBy({id: 'field/field_id', desc: false})(a, b);
  }
}

export default function FieldScores() {
  const {authedFetcher, t} = useApis();
  const crops: IndexedCrops = useSelector(getCrops);
  const [state, setState] = useState<FieldScoresState>({
    cropIds: [],
    cropId: null,
    hasData: false,
    hasCoverage: false,
    hasRegional: false,
    dataYears: [],
    coverageYears: [],
  });
  const data = useRef<{
    raw: FieldScoringItem[];
    filtered?: FieldScoringItem[];
    cropId?: string;
    orderBy?: ColumnSort;
    filters?: DbFilterState;
  }>({
    raw: [],
    filtered: [],
  });

  // Provide sorted and paged data to InfinityTable.
  const fetchDataFromApi: InfinityTableProps<FieldScoringItem>['fetchData'] = useCallback(
    async ({orderBy, pageParam, filters}): Promise<FieldScoringItem[]> => {
      return new Promise(resolve => {
        if (fastDeepEqual(filters, data.current.filters)) {
          // Used cached data.
          resolve(filterSortAndPaginate(state.cropId));
        } else {
          fetchData(authedFetcher, filters).then(fetched => {
            data.current = {
              raw: fetched.data,
              filters,
              // Force sort+filter in paginate().
              filtered: undefined,
              cropId: undefined,
              orderBy: undefined,
            };
            const cropId = state.cropId && fetched.cropIds.includes(state.cropId) ? state.cropId : fetched.cropIds[0];
            setState(state => ({
              ...state,
              hasData: fetched.data.length > 0,
              cropIds: fetched.cropIds,
              cropId: cropId,
              hasCoverage: fetched.hasCoverage,
              hasRegional: fetched.hasRegional,
              dataYears: fetched.dataYears,
              coverageYears: fetched.coverageYears,
            }));
            resolve(filterSortAndPaginate(cropId));
          });
        }
      });

      function filterSortAndPaginate(cropId: null | string): FieldScoringItem[] {
        const orderByOrDefault = orderBy ?? initialSorting;
        // Update filter when missing or cropId changed.
        if ((cropId && data.current.cropId !== cropId) || !data.current.filtered) {
          data.current.filtered = data.current.raw.filter(i => i.cropId === cropId);
          data.current.cropId = cropId ?? undefined;
        }
        // Update sort when orderBy has changed.
        if (!fastDeepEqual(orderByOrDefault, data.current.orderBy)) {
          data.current.filtered.sort(getSortBy(orderByOrDefault));
          data.current.orderBy = orderByOrDefault;
        }
        // Calculate index for pagination.
        const continueFromIndex = pageParam?.continue_from_id
          ? data.current.filtered.findIndex(
              i =>
                i.field.field_id === pageParam.continue_from_id &&
                getSortValue(i, orderByOrDefault.id) === pageParam.continue_from_val,
            ) + 1
          : 0;
        // Return the requested portion of the filtered + sorted data.
        return data.current.filtered.slice(continueFromIndex, continueFromIndex + fetchSetSize);
      }
    },
    [authedFetcher, state.cropId],
  );

  // Dynamically build the columns, based on the data. Some columns are optional,
  // other depend on the number of years we provide data for.
  const columns: ColumnDef<FieldScoringItem>[] = React.useMemo(() => {
    const columns = [
      columnHelper.group({
        id: 'field',
        header: t('Field'),
        enableSorting: false,
        enableResizing: false,
        columns: [
          columnHelper.accessor<typeof getFieldLocation, ReturnType<typeof getFieldLocation>>(getFieldLocation, {
            id: 'field/field_location',
            header: '',
            cell: ({getValue, row}) =>
              getValue() ? (
                <Link
                  to={{pathname: '/map/base', search: '?field_id=' + row.original.field?.field_id!}}
                  title={t('ShowOnMap')}>
                  <EnvironmentOutlined style={{color: PALETTE_COLORS.secondary}} />
                </Link>
              ) : null,
            enableHiding: false,
            enableSorting: false,
            size: defaultColumnSizes.xxs,
            meta: {
              isFixedWidthColumn: true,
            },
          }),
          columnHelper.accessor<typeof getFarmName, ReturnType<typeof getFarmName>>(getFarmName, {
            id: 'farm_name',
            header: t('Farm'),
            cell: ({getValue}) => <span className="mask">{farmDesc(t, getValue())}</span>,
            enableHiding: false,
            enableSorting: true,
            size: defaultColumnSizes.s,
          }),
          columnHelper.accessor<typeof getFieldReference, ReturnType<typeof getFieldReference>>(getFieldReference, {
            id: 'field/external_field_id',
            header: t('Reference'),
            cell: ({getValue}) => <span className="mask">{getValue()}</span>,
            enableHiding: false,
            enableSorting: true,
            size: defaultColumnSizes.s,
          }),
          columnHelper.accessor<typeof getFieldArea, ReturnType<typeof getFieldArea>>(getFieldArea, {
            id: 'field/field_area',
            header: t('FieldCultivatedArea'),
            cell: React.memo(({getValue}) => (
              <span className="mask numbers">
                <Area value={getValue()} />
              </span>
            )),
            enableHiding: false,
            enableSorting: true,
            size: defaultColumnSizes.s,
          }),
        ],
      }),
    ];
    if (state.hasRegional) {
      columns.push({
        id: 'regional',
        header: t('RelativeScoring'),
        enableSorting: false,
        enableResizing: false,
        meta: {
          columnInfo: {
            description: t('RelativeScoringInfo'),
            message: t('RelativeScoring'),
          },
        },
        columns: [
          columnHelper.accessor<typeof getRegion, ReturnType<typeof getRegion>>(getRegion, {
            id: 'regional/region',
            header: t('Municipality'),
            cell: React.memo(({getValue}) => <span className="mask">{getValue()}</span>),
            enableHiding: false,
            enableSorting: true,
            size: defaultColumnSizes.s,
          }),
          columnHelper.accessor<typeof getRelativeProductivity, ReturnType<typeof getRelativeProductivity>>(
            getRelativeProductivity,
            {
              id: 'regional/relative_productivity',
              header: t('Productivity'),
              cell: React.memo(({getValue}) => <Score value={getValue()} />),
              enableHiding: true,
              enableSorting: true,
              size: defaultColumnSizes.xxs,
            },
          ),
          columnHelper.accessor<typeof getRelativeStability, ReturnType<typeof getRelativeStability>>(
            getRelativeStability,
            {
              id: 'regional/relative_stability',
              header: t('Stability'),
              cell: React.memo(({getValue}) => <Score value={getValue()} />),
              enableHiding: true,
              enableSorting: true,
              size: defaultColumnSizes.xxs,
            },
          ),
          columnHelper.accessor<typeof getPercentileProductivity, ReturnType<typeof getPercentileProductivity>>(
            getPercentileProductivity,
            {
              id: 'regional/percentile_productivity',
              header: '%-ile ' + t('Productivity'),
              cell: React.memo(({getValue}) => <Score value={getValue()} />),
              enableHiding: true,
              enableSorting: true,
              size: defaultColumnSizes.xxs,
            },
          ),
          columnHelper.accessor<typeof getPercentileStability, ReturnType<typeof getPercentileStability>>(
            getPercentileStability,
            {
              id: 'regional/percentile_stability',
              header: '%-ile ' + t('Stability'),
              cell: React.memo(({getValue}) => <Score value={getValue()} />),
              enableHiding: true,
              enableSorting: true,
              size: defaultColumnSizes.xxs,
            },
          ),
        ],
      });
    }
    columns.push({
      id: 'productivity',
      header: t('Productivity'),
      enableSorting: false,
      enableResizing: false,
      meta: {
        columnInfo: {
          description: t('Productivity'),
          message: t('ProductivityInfo'),
        },
      },
      columns: [
        columnHelper.accessor<typeof getProductivityScore, ReturnType<typeof getProductivityScore>>(
          getProductivityScore,
          {
            id: 'productivity/score',
            header: t('Score'),
            cell: React.memo(({getValue}) => <Score value={getValue()} />),
            size: defaultColumnSizes.xxs,
            enableHiding: false,
            enableSorting: true,
          },
        ),
        ...state.dataYears.map(year =>
          columnHelper.accessor<typeof getProductivityHistogram, ReturnType<typeof getProductivityHistogram>>(
            getProductivityHistogram,
            {
              id: 'productivity/history/' + year,
              header: year,
              cell: React.memo(({getValue, row}) => {
                const percent = (100 * ((getValue()?.[year] ?? 0) - 0.25)) / 0.7;
                const strokeColor = percent > 70 ? 'green' : percent > 50 ? 'orange' : 'red';
                return (
                  <span className="mask">
                    <Metric
                      value={percent}
                      style="bar"
                      fieldId={row.id}
                      color={strokeColor}
                      year={year}
                      layer="interfield"
                      withLink={true}
                    />
                  </span>
                );
              }),
              size: metricColumnWidth,
              enableHiding: true,
              enableSorting: true,
              meta: {
                initiallyHidden: true,
              },
            },
          ),
        ),
      ],
    });
    columns.push({
      id: 'stability',
      header: t('Stability'),
      enableSorting: false,
      enableResizing: false,
      meta: {
        columnInfo: {
          description: t('Stability'),
          message: t('StabilityInfo'),
        },
      },
      columns: [
        columnHelper.accessor<typeof getStabilityScore, ReturnType<typeof getStabilityScore>>(getStabilityScore, {
          id: 'stability/score',
          header: t('Score'),
          cell: React.memo(({getValue}) => <Score value={getValue()} />),
          size: defaultColumnSizes.xxs,
          enableHiding: false,
          enableSorting: true,
        }),
        columnHelper.accessor<typeof getStabilityVolatility, ReturnType<typeof getStabilityVolatility>>(
          getStabilityVolatility,
          {
            id: 'stability/volatility',
            header: t('Volatility'),
            cell: React.memo(({getValue}) => <Percentage value={getValue()} />),
            size: defaultColumnSizes.xxs,
            enableHiding: true,
            enableSorting: true,
            meta: {
              initiallyHidden: true,
            },
          },
        ),
      ],
    });
    columns.push({
      id: 'homogeneity',
      header: t('Homogeneity'),
      enableSorting: false,
      enableResizing: false,
      meta: {
        columnInfo: {
          description: t('Homogeneity'),
          message: t('HomogeneityInfo'),
        },
      },
      columns: [
        columnHelper.accessor<typeof getHomogeneityScore, ReturnType<typeof getHomogeneityScore>>(getHomogeneityScore, {
          id: 'homogeneity/score',
          header: t('Score'),
          cell: React.memo(({getValue}) => <Score value={getValue()} />),
          size: defaultColumnSizes.xxs,
          enableHiding: false,
          enableSorting: true,
        }),
        ...state.dataYears.map(year =>
          columnHelper.accessor<typeof getHomogeneityHistogram, ReturnType<typeof getHomogeneityHistogram>>(
            getHomogeneityHistogram,
            {
              id: 'homogeneity/history/' + year,
              header: year,
              cell: React.memo(({getValue, row}) => {
                return (
                  <span className="mask numbers">
                    <Metric
                      value={getValue()?.[year]}
                      fieldId={row.id}
                      year={year}
                      layer="interfield"
                      withLink={true}
                    />
                  </span>
                );
              }),
              size: metricColumnWidth,
              enableHiding: true,
              enableSorting: true,
              meta: {
                initiallyHidden: true,
              },
            },
          ),
        ),
      ],
    });
    if (state.coverageYears && state.coverageYears.length > 0) {
      columns.push({
        id: 'cropCoverage',
        header: t('CropCoverage'),
        enableSorting: false,
        enableResizing: false,
        meta: {
          columnInfo: {
            description: t('CropCoverage'),
            message: t('CropCoverageInfo'),
          },
        },
        columns: state.coverageYears.map((year, ix, all) =>
          columnHelper.accessor<typeof getCropCoverageHistogram, ReturnType<typeof getCropCoverageHistogram>>(
            getCropCoverageHistogram,
            {
              id: 'cropCoverage/' + year,
              header: year,
              cell: React.memo(({getValue, row}) => {
                return (
                  <span className="mask numbers">
                    <Metric value={getValue()?.[year]} fieldId={row.id} year={year} layer="satellite" withLink={true} />
                  </span>
                );
              }),
              size: metricColumnWidth,
              enableHiding: ix !== all.length - 1, // Hide all but the last.
              enableSorting: true,
              meta: {
                initiallyHidden: ix !== all.length - 1, // Hide all but the last.
              },
            },
          ),
        ),
      });
    }
    columns.push({
      id: 'cloud_cover',
      header: t('CloudCover'),
      enableSorting: false,
      enableResizing: false,
      columns: [
        columnHelper.accessor<typeof getCloudCoverWarning, ReturnType<typeof getCloudCoverWarning>>(
          getCloudCoverWarning,
          {
            id: 'cloud_cover_warning',
            header: t('CloudCoverWarning'),
            size: metricColumnWidth,
            enableHiding: false,
            enableSorting: true,
            cell: React.memo(({getValue}) => {
              return <span className="mask">{getValue() ? <WarningOutlined /> : null}</span>;
            }),
            meta: {
              columnInfo: {
                description: t('CloudCover'),
                message: t('CloudCoverInfo'),
              },
            },
          },
        ),
        ...state.dataYears.map(year =>
          columnHelper.accessor<typeof getCloudCoverHistogram, ReturnType<typeof getCloudCoverHistogram>>(
            getCloudCoverHistogram,
            {
              id: 'cloudCover/' + year,
              header: year,
              cell: React.memo(({getValue, row}) => {
                return (
                  <span className="mask numbers">
                    <Metric
                      value={getValue()?.[year]}
                      fieldId={row.id}
                      year={year}
                      layer="interfield"
                      withLink={true}
                    />
                  </span>
                );
              }),
              size: metricColumnWidth,
              enableHiding: true,
              enableSorting: true,
              meta: {
                initiallyHidden: true,
              },
            },
          ),
        ),
      ],
    });
    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify([state.dataYears, state.coverageYears, state.hasCoverage, state.hasRegional]), t]);

  return (
    <span className="list-view list-scoring">
      <ErrorBoundary>
        <div className="list-view-action-buttons">
          <DownloadButton
            label={'DownloadXLSX'}
            downloadType="field-scoring"
            params={{cropId: state.cropId}}
            enabled={state.hasData}
          />
          <Select
            options={state.cropIds.map(cropId => ({value: cropId, label: cropDesc(t, crops, cropId)}))}
            value={state.cropId}
            onChange={cropId => setState(dataState => ({...dataState, cropId}))}
            notFoundContent={t('NotAvailable')}
          />
        </div>
        <InfinityTable<FieldScoringItem, [null | string]>
          columns={columns}
          initialSorting={initialSorting}
          fetchData={fetchDataFromApi}
          fetchSetSize={fetchSetSize}
          additionalQueryKeys={[state.cropId]}
          tableId={`list/scoring`}
          getRowId={getRowId}
          getSortValue={getSortValue as (row: FieldScoringItem, columnId: SortableColumn) => null | string}
          indentDetails={1}
        />
      </ErrorBoundary>
    </span>
  );
}

interface MetricProps {
  value: null | undefined | number;
  style?: 'bar' | 'column' | 'text';
  color?: string;
  layer: 'interfield' | 'satellite';
  year: string;
  fieldId: string;
  withLink: boolean;
}

const Metric: React.FC<MetricProps> = ({value, style = 'text', color, layer, year, fieldId, withLink}) => {
  if (value === undefined || value === null) return <>-</>;
  let inner = null;
  switch (style) {
    case 'bar':
      inner = <Progress percent={value} showInfo={false} strokeColor={color} />;
      break;
    case 'text':
      inner = <Percentage value={value} />;
  }
  if (!withLink) return inner;
  const date = layer === 'satellite' ? year + '-02-01' : getLatestSentinelCanonicalDate(new Date(year + '-02-15'));
  const to = {pathname: `/map/${layer}/${date}`, search: `?field_id=${fieldId}`};
  return <Link to={to}>{inner}</Link>;
};

const Score: React.FC<{value: null | number}> = ({value}) => {
  return <span className="mask numbers">{value?.toFixed(1) ?? '-'}</span>;
};

const Percentage: React.FC<{value: null | undefined | number}> = ({value}) => {
  return (
    <span className="mask numbers">
      {value !== null && value !== undefined && value !== 0 ? (value * 100).toFixed(1) + '%' : '-'}
    </span>
  );
};
