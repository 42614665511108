import {
  CloseOutlined,
  DeleteOutlined,
  FormOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {Button, Col, Divider, PageHeader, Row, Tooltip} from 'antd';
import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {ClockI} from '../../../src/Clock';
import {FormyErrors, FormyI} from '../../../src/Formy';
import {useFormyValue, useSyncFormy} from '../../../src/Formy/hooks';
import {CROP_COLORS} from '../../../src/constants/colors';
import {getCustomLossCauses} from '../../../src/custom/losses';
import {I18nParametric, I18nSimpleKey} from '../../../src/i18n/i18n';
import {ClaimDamageData} from '../../../src/models/data';
import {
  Claim,
  ClaimDamage,
  CoverageType,
  Harvest,
  HarvestYear,
  Loss,
  LossCause,
  VegetationStage,
  YieldUnit,
} from '../../../src/models/interfaces';
import {OmitDbColumns, Uuid} from '../../../src/models/types';
import {
  deleteClaimDamage,
  insertClaim,
  insertClaimDamage,
  updateClaim,
  updateClaimDamage,
} from '../../../src/redux/actions/db';
import {IndexedCrops} from '../../../src/redux/reducers/crops';
import {getBaseCrop} from '../../../src/selectors/crops';
import {getCountryCodeGroups} from '../../../src/selectors/units';
import {getCurYear} from '../../../src/selectors/year';
import {harvestDesc, userEntityDesc} from '../../../src/text/desc';
import {remove, unique} from '../../../src/util/arr-util';
import {
  createVisitCandidates,
  extractAssignedTo,
  extractAssignedToEmails,
  getUpdatedClaimAssignedTo,
} from '../../../src/util/claims';
import cmp from '../../../src/util/cmp';
import {fetchEntitiesBy, fetchEntity} from '../../../src/util/fetchEntity';
import {useAsyncMemo} from '../../../src/util/hooks';
import {Nullable} from '../../../src/util/types';
import {isArr, isEmail, isLossCause, isStr} from '../../../src/validator-constraints';
import {FormyDatePicker} from '../Formy/FormyDatePicker';
import FormyEntitySelector from '../Formy/FormyEntitySelector';
import {FormyEnum, FormyMultiEnum} from '../Formy/FormyEnum';
import {Label} from '../Formy/FormyLabel';
import {FormySubmit} from '../Formy/FormySubmit';
import {FormyTextStr, FormyUnit} from '../Formy/FormyText';
import {useApis} from '../apis/ApisContext';
import {useUserEntitiesByEmail} from '../queries/options';
import {State} from '../redux';
import {deleteClaim} from '../redux/actions/db';
import './add-or-edit-claim.css';
import {commitMutations} from './commit';

interface ClaimDamageForm {
  harvest_id: ClaimDamage['harvest_id'];
  losses: Loss[]; // insurance_loss_estimation
  vegetation_stage: ClaimDamage['vegetation_stage'];
  claim_damage_id: null | ClaimDamage['claim_damage_id'];
}

class ClaimForm implements Nullable<OmitDbColumns<Claim>, 'claim_id' | 'farm_id'> {
  claim_id: null | Uuid;
  assigned_to: Claim['assigned_to'];
  comments: Claim['comments'];
  contact_email: Claim['contact_email'];
  contact_first_name: Claim['contact_first_name'];
  contact_last_name: Claim['contact_last_name'];
  contact_phone: Claim['contact_phone'];
  coverage_type: Claim['coverage_type'];
  external_claim_id: Claim['external_claim_id'];
  manager_email: Claim['manager_email'];
  manager_first_name: Claim['manager_first_name'];
  manager_last_name: Claim['manager_last_name'];
  manager_phone: Claim['manager_phone'];
  policy_id: Claim['policy_id'];
  farm_id: null | Claim['farm_id'];
  status: Claim['status'];
  closed_on: Claim['closed_on'];
  metadata: Claim['metadata'];
  custom_columns: Claim['custom_columns'];
  harvest_year: HarvestYear;
  user_assigned_to: string[];
  claimDamages: ClaimDamageForm[];

  constructor(
    clock: ClockI,
    claim: undefined | Claim,
    existingClaimDamages: undefined | ClaimDamage[],
    user: {
      email: string;
      name?: string;
      locale?: string;
    },
  ) {
    this.claim_id = claim?.claim_id ?? null;
    this.assigned_to = claim?.assigned_to ?? [];
    this.user_assigned_to = extractAssignedToEmails(claim ?? null, null);
    this.comments = claim?.comments ?? null;
    this.contact_email = claim?.contact_email ?? null;
    this.contact_first_name = claim?.contact_first_name ?? null;
    this.contact_last_name = claim?.contact_last_name ?? null;
    this.contact_phone = claim?.contact_phone ?? null;
    this.coverage_type = claim?.coverage_type ?? null;
    this.external_claim_id = claim?.external_claim_id ?? null;
    this.manager_email = claim ? claim.manager_email : user.email;
    this.manager_first_name = claim ? claim.manager_first_name : (user.name?.split(' ')[0] ?? null);
    this.manager_last_name = claim ? claim.manager_last_name : (user.name?.split(' ')[1] ?? null);
    this.manager_phone = claim?.manager_phone ?? null;
    this.policy_id = claim?.policy_id ?? null;
    this.status = 'created-unassigned';
    this.closed_on = null;
    this.metadata = null;
    this.custom_columns = null;

    // TODO(kristjan): policy will most likely be connected to farm (after integration with quotation tool).
    // When that happens, we should default to policy's farm and possibly also crop_ids and harvest_year.
    // It may be possible that one policy will have multiple farms.
    this.farm_id = claim?.farm_id ?? null;
    // all claim damages should have the same harvest_year, so we take the first one
    this.harvest_year = claim?.harvest_year ?? getCurYear(clock);
    this.claimDamages =
      existingClaimDamages?.map(claimDamage => ({
        harvest_id: claimDamage.harvest_id,
        losses: claimDamage.insurance_loss_estimation ?? [],
        vegetation_stage: claimDamage.vegetation_stage,
        claim_damage_id: claimDamage.claim_damage_id,
      })) ?? [];
  }
}

const maxWidthResponsiveHeight = {width: '100%', height: 'inherit'};
const errorColor = {color: '#C23B22'};

const validateClaimDamageForm = (x: ClaimDamageForm): FormyErrors<ClaimDamageForm> => {
  return {
    harvest_id: !x.harvest_id,
    losses:
      !x.losses ||
      !isArr(x.losses) ||
      x.losses.map(y => ({
        loss_cause: !y.loss_cause || !isLossCause(y.loss_cause),
      })),
  };
};

function validateClaimForm(x: ClaimForm): FormyErrors<ClaimForm> {
  return {
    farm_id: !x.farm_id || !isStr(x.farm_id),
    contact_email: !isEmail(x.contact_email),
    manager_email: !isEmail(x.manager_email),
    claimDamages: !x.claimDamages.length || x.claimDamages.map(y => validateClaimDamageForm(y)),
  };
}

const createClaimDamage = (claimDamageForm: ClaimDamageForm, currentClaimId: string): ClaimDamageData => {
  return {
    claim_id: currentClaimId,
    harvest_id: claimDamageForm.harvest_id,
    vegetation_stage: claimDamageForm.vegetation_stage,
    // TODO(kristjan): refactor the below to claimDamage.loss, and specify the source (e.g. from the insurance).
    insurance_loss_estimation: claimDamageForm.losses,
  };
};

export const AddOrEditClaim: React.FC = () => {
  const apis = useApis();
  const location = useLocation();

  const claimId = useMemo(() => new URLSearchParams(location.search).get('claim_id') ?? '', [location]);
  const form = useAsyncMemo(async () => {
    if (!claimId) {
      return new ClaimForm(apis.clock, undefined, undefined, apis.getUser());
    }
    const claim = await fetchEntity(apis.authedFetcher, 'claim', claimId);
    const claimDamage = await fetchEntitiesBy(apis.authedFetcher, 'claim_damage', {
      column: 'claim_id',
      operator: 'eq',
      value: claimId,
    });
    return new ClaimForm(apis.clock, claim, claimDamage, apis.getUser());
  }, [apis, claimId]);
  return form ? <AddOrEditClaimForm claimForm={form} /> : null;
};

function getClaimDamageUpdates(claimDamageForm: ClaimDamageForm): Partial<ClaimDamageData> {
  return {
    vegetation_stage: claimDamageForm.vegetation_stage,
    // TODO(kristjan): refactor the below to claimDamage.loss, and specify the source (e.g. from the insurance).
    insurance_loss_estimation: claimDamageForm.losses,
    harvest_id: claimDamageForm.harvest_id,
  };
}

interface ClaimDamageFormProps {
  formy: FormyI<ClaimDamageForm>;
  deleteClaimDamageForm: () => void;
  harvests?: Harvest[];
  lossCauses: undefined | LossCause[];
}

const ClaimDamageForm: React.FC<ClaimDamageFormProps> = ({formy, harvests, deleteClaimDamageForm, lossCauses}) => {
  const {t, clock} = useApis();
  const allCrops: IndexedCrops = useSelector((state: State) => state.crops.crops);
  const countryGroups: string[] = useSelector(getCountryCodeGroups);

  const deleteLoss = useCallback(
    (lossIdx: number) => {
      const newLosses = formy.getValue('losses').filter((_, index) => index != lossIdx);
      formy.getChangeHandler('losses')(newLosses);
    },
    [formy],
  );

  const addLoss = useCallback(() => {
    const losses = formy.getValue('losses');
    const newLosses = [
      ...losses,
      {
        loss_cause: null,
        loss: null,
        loss_event_date: new Date(clock.now()).toISOString(),
        loss_recognized: null,
        loss_quality: null,
      },
    ];
    formy.getChangeHandler('losses')(newLosses);
  }, [clock, formy]);

  const harvest_id = useFormyValue(formy, 'harvest_id');
  const selectedHarvest = useMemo(
    () => harvests?.find(harvest => harvest.harvest_id == harvest_id),
    [harvest_id, harvests],
  );
  const borderColor = CROP_COLORS[getBaseCrop(allCrops, selectedHarvest?.crop_id)!] ?? 'transparent';

  return (
    <div
      style={{
        borderLeft: `5px solid ${borderColor}`,
        marginTop: '0.5rem',
        borderRadius: '0.25rem',
      }}>
      <Row gutter={16}>
        <Col span={7} xs={11} lg={7} xl={6}>
          <Label>{t('DamagedHarvest')}:</Label>
          <FormyEnum
            required
            formy={formy}
            field={'harvest_id'}
            options={
              harvests?.map(harvest => {
                return [harvest.harvest_id, harvestDesc(t, allCrops, harvest, null, countryGroups, null, true)];
              }) ?? []
            }
            selectMsg={'Select'}
          />
        </Col>
        <Col span={7} xs={11} lg={7} xl={6}>
          <Label>{t('VegetationStage')}:</Label>
          <FormyEnum
            formy={formy}
            field={'vegetation_stage'}
            selectMsg={'Select'}
            options={Array.from(VegetationStage)
              .sort((a, b) => cmp(t(a), t(b)))
              .map(vegetationStage => [vegetationStage, t(vegetationStage)])}
          />
        </Col>
        <Col>
          <Button
            className="row-item-margin-top"
            icon={<DeleteOutlined />}
            onClick={deleteClaimDamageForm}
            size="small"
            danger
            type="ghost"
          />
        </Col>
      </Row>
      <Row>
        <Col span={2} xxl={1}>
          <h4 className="losses-heading">{t('Losses')}</h4>
        </Col>
        <Col span={22} xxl={23}>
          <Row gutter={16}>
            {formy.getValue('losses').map((_, lossIndex) => {
              const lossFormy = formy.getSectionFormy('losses').getSectionFormy(lossIndex);
              return (
                <Col span={24} key={'loss-' + lossIndex}>
                  <Row gutter={16} style={{maxWidth: 'inherit'}}>
                    <Col span={6} xl={3}>
                      <Label>{t('LossCause')}:</Label>
                      <FormyEnum
                        formy={lossFormy}
                        field={'loss_cause'}
                        selectMsg={'SelectLossCause'}
                        disabled={!lossCauses}
                        options={
                          lossCauses?.map(lossCause => {
                            return [lossCause, t(lossCause)];
                          }) ?? []
                        }
                      />
                    </Col>
                    <Col span={8} xl={5}>
                      <Label>{t('EstimatedYieldLoss')}:</Label>
                      <FormyUnit units={YieldUnit} formy={lossFormy} field={'loss'} inline={true} />
                    </Col>
                    <Col span={8} xl={3}>
                      <FormyDatePicker
                        formy={lossFormy}
                        field={'loss_event_date'}
                        label={'LossEventDate'}
                        placeholder={'PleaseEnterValue'}
                      />
                    </Col>
                    <Col span={2}>
                      <Tooltip title={t('RemoveLoss')}>
                        <Button
                          icon={<MinusCircleTwoTone twoToneColor={'red'} />}
                          title={t('AddLossCause')}
                          onClick={() => deleteLoss(lossIndex)}
                          type="text"
                          danger
                          className="row-item-margin-top"
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
          <Tooltip title={t('AddNewLoss')}>
            <Button
              data-testid="add-new-loss"
              icon={<PlusCircleTwoTone twoToneColor="#4CAF50" />}
              onClick={addLoss}
              type="text"
            />
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
};

const AddOrEditClaimForm: React.FC<{claimForm: ClaimForm}> = ({claimForm}) => {
  const apis = useApis();
  const history = useHistory();
  const {data: userEntitiesByEmail} = useUserEntitiesByEmail();
  const [error, setError] = React.useState<null | I18nParametric | I18nSimpleKey>(null);

  const onSubmit = useCallback(
    async (claimForm: ClaimForm) => {
      // NOTE: be careful with extracting values out of claim, since we save `claim` as is.
      const {claim_id, farm_id, user_assigned_to, claimDamages, ...claim} = claimForm;

      if (!farm_id) {
        // this shouldn't happen, as formy validation will fail for empty farm_id
        throw new Error('onSubmit must not be called with empty farm_id.');
      }

      claim.assigned_to = getUpdatedClaimAssignedTo(
        claim.assigned_to,
        createVisitCandidates(user_assigned_to, 'assigned'),
      );

      if (claim.status === 'created-unassigned' && extractAssignedTo(claim.assigned_to, ['assigned']).length > 0) {
        claim.status = 'awaiting-adjuster-acceptance';
      }

      const currentClaimId = apis.store.dispatch(
        claim_id ? updateClaim(claim_id, {...claim, farm_id}) : insertClaim(apis, {...claim, farm_id}),
      );

      const existingClaimDamages = await fetchEntitiesBy(apis.authedFetcher, 'claim_damage', {
        column: 'claim_id',
        operator: 'eq',
        value: currentClaimId,
      });

      for (const oldClaimDamage of existingClaimDamages) {
        const lossForClaimDamage = claimDamages.find(cd => cd.claim_damage_id === oldClaimDamage.claim_damage_id);
        // no claimDamageForm was found for existing claim damage, delete it
        if (!lossForClaimDamage) {
          apis.store.dispatch(deleteClaimDamage(oldClaimDamage.claim_damage_id));
        } else {
          // else update
          apis.store.dispatch(
            updateClaimDamage(oldClaimDamage.claim_damage_id, getClaimDamageUpdates(lossForClaimDamage)),
          );
        }
      }

      claimDamages
        .filter(claimDamageForm => claimDamageForm.claim_damage_id == null)
        .forEach(claimDamageForm => {
          apis.store.dispatch(insertClaimDamage(apis, createClaimDamage(claimDamageForm, currentClaimId!)));
        });

      if (await commitMutations(apis)) {
        history.goBack();
      } else {
        setError('FailedToSyncChanges');
      }
    },
    [apis, history],
  );

  const formy = useSyncFormy(
    claimForm.claim_id ? 'edit' : 'new',
    () => claimForm,
    apis.t,
    onSubmit,
    validateClaimForm,
    null,
  );
  const harvestYear = useFormyValue(formy, 'harvest_year');
  const claimDamages = useFormyValue(formy, 'claimDamages', true);
  const farmId = useFormyValue(formy, 'farm_id');
  const policyId = useFormyValue(formy, 'policy_id');
  const availableFarmLevelHarvests = useAsyncMemo(async () => {
    if (!farmId || !harvestYear) {
      return [];
    }
    const harvests = await fetchEntitiesBy(apis.authedFetcher, 'harvest', {
      and: [
        {
          column: 'farm_id',
          operator: 'eq',
          value: farmId,
        },
        {
          column: 'harvest_year',
          operator: 'eq',
          value: harvestYear,
        },
      ],
    });
    // For now only a single customer will have policies with insured harvests. Other customers won't have policies
    // with insured harvests. These are the possible cases:
    // a) customer opens a claim without selecting a policy => show all available harvests
    // b) customer opens a claim by selecting a policy without insured harvests => show all available harvests
    // c) customer opens a claim for policy with insured harvests => show only harvests for the policy (insured harvests)
    // We don't know in advance if the policy has any insured harvests, so we fetch all the harvests for given farm
    // and year and filter afterward.
    const policyHarvests = harvests.filter(harvest => harvest.policy_id === policyId);
    return policyHarvests.length > 0 ? policyHarvests : harvests;
  }, [apis, farmId, harvestYear, policyId]);
  const lossAdjusters = useMemo((): [string, string][] => {
    return Object.values(userEntitiesByEmail ?? {})
      .filter(userEntity => userEntity.email)
      .map(userEntity => {
        const status = claimForm?.assigned_to?.find(
          assignee => assignee?.email === userEntity.email,
        )?.visit_candidate_status;
        return [
          userEntity.email!, // TS doesn't detect that we filtered by null already, so we use !
          userEntityDesc(userEntity.email, userEntitiesByEmail) +
            (status ? '(' + apis.t(('VisitCandidateStatus_' + status) as I18nSimpleKey) + ')' : ''),
        ];
      });
  }, [apis, claimForm?.assigned_to, userEntitiesByEmail]);
  formy.watchValue('farm_id', () => {
    formy.getChangeHandler('claimDamages')([]);
  });
  // If a policy is selected, we try to deduce the farm for it.
  formy.watchValue('policy_id', async () => {
    const currentPolicyId = formy.getValue('policy_id');
    if (!currentPolicyId) {
      return;
    }
    const policyHarvestFarmIds = unique(
      (
        await fetchEntitiesBy(apis.authedFetcher, 'harvest', {
          and: [
            {
              column: 'policy_id',
              operator: 'eq',
              value: currentPolicyId,
            },
            {
              column: 'harvest_year',
              operator: 'eq',
              value: harvestYear,
            },
          ],
        })
      )
        .map(harvest => harvest.farm_id)
        .filter(remove.nulls),
    );
    // Relation between a policy and a farm should be one-to-one in practice, however the database model allows for
    // harvests from different farms to point to the same policy and for that do the check below.
    const policyFarmId: undefined | Uuid = policyHarvestFarmIds[0];
    if (policyFarmId && policyFarmId !== farmId) {
      formy.getChangeHandler('farm_id')(policyFarmId);
    }
  });
  const deleteClaimAction = useCallback(async () => {
    if (claimForm.claim_id) {
      const visits = await fetchEntitiesBy(apis.authedFetcher, 'visit', {
        column: 'claim_id',
        operator: 'eq',
        value: claimForm.claim_id,
      });
      if (visits.length) {
        setError({
          type: 'NoDeleteClaim',
          visitCount: visits.length,
        });
        return;
      }
      await apis.store.dispatch(deleteClaim(apis.authedFetcher, claimForm.claim_id));
      if (await commitMutations(apis)) {
        history.goBack();
      } else {
        setError('FailedToSyncChanges');
      }
    }
  }, [apis, claimForm, history]);

  const handleAddClaimDamage = useCallback(() => {
    const currentClaimDamages = formy.getValue('claimDamages');
    const updatedClaimDamages: ClaimDamageForm[] = [
      ...currentClaimDamages,
      {
        harvest_id: '',
        losses: [],
        vegetation_stage: null,
        claim_damage_id: null,
      },
    ];
    formy.getChangeHandler('claimDamages')(updatedClaimDamages);
  }, [formy]);

  const handleDeleteClaimDamage = useCallback(
    (formIdx: number) => {
      const updatedClaimDamages = formy.getValue('claimDamages').filter((_, index) => index != formIdx);
      formy.getChangeHandler('claimDamages')(updatedClaimDamages);
    },
    [formy],
  );
  const lossCauses = useAsyncMemo(async () => {
    if (!farmId) {
      return [];
    }
    const farm = await fetchEntity(apis.authedFetcher, 'farm', farmId);
    return getCustomLossCauses(farm.user_group).guaranteed;
  }, [apis.authedFetcher, farmId]);

  return (
    <div className={'claims-form'}>
      <PageHeader
        title={claimForm.claim_id ? apis.t('EditClaim') : apis.t('AddClaim')}
        className="no-print" //Hide header when printing
        avatar={{icon: <FormOutlined />}}
      />
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('Policy')}:</Label>
          <FormyEntitySelector entityType={'policy'} onNewEntity={null} formy={formy} field={'policy_id'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('Farm')}:</Label>
          <FormyEntitySelector field="farm_id" formy={formy} entityType="farm" onNewEntity={null} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr
            formy={formy}
            field={'external_claim_id'}
            placeholder={'PleaseEnterValue'}
            label={'ExternalClaimId'}
          />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={formy} field={'comments'} placeholder={'PleaseEnterValue'} label={'Comments'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('CropCoverage')}:</Label>
          <FormyEnum
            formy={formy}
            field={'coverage_type'}
            selectMsg={'Select'}
            options={CoverageType.map(coverageType => [coverageType, apis.t(coverageType)])}
          />
        </Col>
        <Col span={6}>
          <Label>{apis.t('HarvestYear')}:</Label>
          <FormyEnum
            formy={formy}
            field={'harvest_year'}
            selectMsg={'Select'}
            options={HarvestYear.map(harvestYear => {
              return [harvestYear, apis.t(harvestYear)];
            })}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('AssignedLossAdjusters')}:</Label>
          <FormyMultiEnum
            style={maxWidthResponsiveHeight}
            formy={formy}
            maxTagCount={20}
            field={'user_assigned_to'}
            selectMsg={'Select'}
            options={lossAdjusters ?? []}
          />
        </Col>
      </Row>
      <h3>{apis.t('Damages')}</h3>
      {Object.values(claimDamages ?? []).map((_, claimDamageFormIndex) => {
        const sectionFormy = formy.getSectionFormy('claimDamages').getSectionFormy(claimDamageFormIndex);
        // Note that claim editor logic is currently not synced with the policy editor logic. On the
        //  policy editor user first selects a farm-level harvest and then selects which fields (field-level harvests)
        //  will be insured for that farm-level harvest. In the claim editor, the user can currently pick only farm-level
        //  harvests directly. As far as I'm aware, claim damages with field-level harvests are not used anywhere in GT.
        //  Additional context:
        //  - In the importers (src/gt-pack/save.ts:208), claim damages are created only for farm-level harvests.
        //  - In the visit config screen (app/visit/VisitConfigScreen.tsx:101) affected_harvests contain only
        //  farm-level harvests (which can be preselected based on existing claim damage).
        return (
          <ClaimDamageForm
            key={'claim-damage-' + claimDamageFormIndex}
            formy={sectionFormy}
            deleteClaimDamageForm={() => handleDeleteClaimDamage(claimDamageFormIndex)}
            harvests={availableFarmLevelHarvests}
            lossCauses={lossCauses}
          />
        );
      })}
      {farmId && (
        <Row gutter={16}>
          <Col>
            <Button type="default" icon={<PlusOutlined />} onClick={handleAddClaimDamage}>
              {apis.t('AddNewDamage')}
            </Button>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <h3>{apis.t('Manager')}</h3>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr
            formy={formy}
            field={'manager_first_name'}
            placeholder={'PleaseEnterValue'}
            label={'FirstName'}
          />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={formy} field={'manager_last_name'} placeholder={'PleaseEnterValue'} label={'LastName'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr formy={formy} field={'manager_email'} placeholder={'PleaseEnterValue'} label={'Email'} />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={formy} field={'manager_phone'} placeholder={'PleaseEnterValue'} label={'Phone'} />
        </Col>
      </Row>
      <Divider />
      <h3>{apis.t('FarmerRepresentative')}</h3>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr
            formy={formy}
            field={'contact_first_name'}
            placeholder={'PleaseEnterValue'}
            label={'FirstName'}
          />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={formy} field={'contact_last_name'} placeholder={'PleaseEnterValue'} label={'LastName'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr formy={formy} field={'contact_email'} placeholder={'PleaseEnterValue'} label={'Email'} />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={formy} field={'contact_phone'} placeholder={'PleaseEnterValue'} label={'Phone'} />
        </Col>
      </Row>
      <div className="add-farms-buttons">
        <Button type="default" icon={<CloseOutlined />} onClick={() => history.goBack()}>
          {apis.t('Back')}
        </Button>
        <FormySubmit
          type="primary"
          icon={<SaveOutlined />}
          formy={formy}
          label="Save"
          doNotConfirmSubmissionToUser={true}
        />
        {claimForm.claim_id && (
          <Button icon={<DeleteOutlined />} onClick={deleteClaimAction}>
            {apis.t('Delete')}
          </Button>
        )}
      </div>
      <div style={errorColor}>{error && apis.t(error)}</div>
    </div>
  );
};
