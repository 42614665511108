import {BranchesOutlined, EditOutlined, ImportOutlined, WarningOutlined} from '@ant-design/icons';
import {Alert, Button, Col, Input, Modal, Row, Space, Table, type TableColumnsType, Tooltip} from 'antd';
import {TableRowSelection} from 'antd/lib/table/interface';
import React, {FC, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {
  UserEntity,
  UserGroup,
  UserGroupMembership,
  grantUserAccess,
  revokeUserAccess,
} from '../../../src/models/interfaces';
import {getUserGroups} from '../../../src/selectors/userGroups';
import cmp from '../../../src/util/cmp';
import {useApis} from '../apis/ApisContext';
import {ErrorBoundary} from '../util/ErrorBoundary';
import {
  AdminUserEntity,
  MembershipUpdates,
  toName,
  useAdminUserGroupFilter,
  useFetchAll,
  useTransitiveUserGroups,
} from '../util/admin-util';
import {AddKeycloakUser} from './AddKeycloakUser';
import {AdminMembershipTags} from './AdminMembershipTags';
import {AdminUserEntityEdit} from './AdminUserEntityEdit';
import {RemoveKeycloakUser} from './RemoveKeycloakUser';
import {UserEntityUpdates} from './UserEntityUpdates';

export const AdminUserEntityTable: FC = () => {
  const {t} = useApis();
  const user_groups = useSelector(getUserGroups);
  const [search, setSearch] = useState<string>('');
  const {grantors} = useTransitiveUserGroups();
  const {
    entities: userGroupsMemberships,
    loading: loadingGroupMemberships,
    refetch: refetchMemberships,
  } = useFetchAll('user_group_membership', ['email', 'user_group', 'membership_type']);
  const {
    entities: userEntities,
    loading: loadingUserEntities,
    refetch: refetchEntities,
  } = useFetchAll('user_entity', ['user_id']);
  const isLoading = loadingGroupMemberships || loadingUserEntities;
  const data: AdminUserEntity[] = useMemo(() => {
    return toUserMemberships(userEntities, user_groups, userGroupsMemberships, grantors);
  }, [grantors, userEntities, userGroupsMemberships, user_groups]);

  const parts = search.split(' ');
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedUserEntity, setSelectedUserEntity] = useState<AdminUserEntity[]>([]);
  // Don't select users directly, as the selected object might be outdated after we re-fetched data after granting/revoking.
  const selectedUserMemberships = data?.filter(u => selectedEmails.includes(u.email)) ?? [];
  const handleOpenEditModal = (memberships: AdminUserEntity[]) => {
    setSelectedUserEntity(memberships);
    setIsEditModalOpen(true);
  };
  const handleEditModalOk = () => {
    refetchMemberships();
    setIsEditModalOpen(false);
  };

  const handleEditModalCancel = () => {
    setIsEditModalOpen(false);
  };

  const columns: TableColumnsType<AdminUserEntity> = useMemo(() => {
    return [
      {
        key: 'user',
        title: t('User'),
        sorter: (a, b) => cmp(getUserName(a), getUserName(b)),
        render: (_, record) => <span>{getUserName(record)}</span>,
      },
      {
        key: 'email',
        dataIndex: 'email',
        title: t('Email'),
        sorter: (a, b) => cmp(a.email, b.email),
        defaultSortOrder: 'ascend',
      },
      {
        key: 'customers',
        dataIndex: 'customers',
        title: t('Customer'),
        sorter: (a, b) => cmp(a.customers.join(', '), b.customers.join(', ')),
        render: (value: AdminUserEntity['customers']) => {
          const transitiveGroups = value
            .flatMap(c => grantors[c.user_group] ?? [])
            .map(g => toName(g))
            .sort()
            .join(', ');
          return (
            <Tooltip title={transitiveGroups}>
              <Space>
                {value.map(g => toName(g)).join(', ')}
                {transitiveGroups.length > 0 ? <BranchesOutlined /> : null}
              </Space>
            </Tooltip>
          );
        },
      },
      {
        key: 'memberships',
        dataIndex: 'memberships',
        title: t('user_group') + ': ' + t('membership_type'),
        sorter: (a, b) => a.memberships.length - b.memberships.length,
        render: (_, record) => {
          return <AdminMembershipTags user={record} highlight={parts} />;
        },
      },
      {
        key: 'actions',
        title: '',
        render: (_, record) => {
          return (
            <Button
              type="ghost"
              icon={<EditOutlined />}
              aria-label={t('Edit') + ': ' + record.email}
              onClick={() => {
                setSelectedEmails([record.email]);
                handleOpenEditModal([record]);
              }}
            />
          );
        },
      },
    ];
  }, [t, grantors, parts]);

  const rowSelection: TableRowSelection<AdminUserEntity> = useMemo(
    () => ({
      type: 'checkbox',
      onChange: (_: unknown, selectedRows: AdminUserEntity[]) => setSelectedEmails(selectedRows.map(r => r.email)),
      selectedRowKeys: selectedEmails,
    }),
    [selectedEmails],
  );
  const filteredData = data?.filter(u => {
    return parts.some(
      p =>
        u.email.includes(p) ||
        u.first_name?.includes(p) ||
        u.last_name?.includes(p) ||
        u.customers.some(c => c.user_group.includes(p)) ||
        u.memberships.some(m => parts.some(p => toName(m.user_group).includes(p) || m.membership_type.includes(p))),
    );
  });

  const handleOnAddUsers = async (addedEmails: string[]) => {
    if (!addedEmails.length) {
      // Do nothing if no users were added.
      return;
    }
    const {data: memberships} = await refetchMemberships();
    const {data: entities} = await refetchEntities();
    if (entities && memberships) {
      setSelectedEmails(addedEmails);
      handleOpenEditModal(
        toUserMemberships(entities, user_groups, memberships, grantors).filter(u => addedEmails.includes(u.email)) ??
          [],
      );
    }
  };

  return (
    <ErrorBoundary>
      <UserEditModal
        isModalOpen={isEditModalOpen}
        selected={selectedUserEntity}
        onOk={handleEditModalOk}
        onCancel={handleEditModalCancel}
      />
      <Row gutter={[0, 24]}>
        <Col xs={{order: 1, span: 24}} sm={{order: 1, span: 24}} md={{order: 0, span: 12}} lg={{order: 0, span: 12}}>
          <div className="button-bar">
            <Space>
              <AddKeycloakUser onAdded={handleOnAddUsers} />
              <Button
                type="primary"
                icon={<EditOutlined />}
                disabled={selectedUserMemberships.length == 0}
                aria-label={t('Edit')}
                onClick={() => handleOpenEditModal(selectedUserMemberships)}>
                {t('Edit')}
              </Button>
              <RemoveKeycloakUser userEntities={selectedUserMemberships} onDeleted={refetchMemberships} />
              <Link to="/gt-internal/bulk-import">
                <Button type="default" icon={<ImportOutlined />} aria-label={t('ImportData')}>
                  {t('ImportData')}
                </Button>
              </Link>
            </Space>
          </div>
        </Col>
        <Col xs={{order: 0, span: 24}} sm={{order: 0, span: 24}} md={{order: 1, span: 12}} lg={{order: 1, span: 12}}>
          <Input.Search
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
            value={search}
            enterButton
            allowClear={true}
          />
        </Col>
        <Col order={2} span={24}>
          <Table<AdminUserEntity>
            dataSource={filteredData}
            loading={isLoading}
            columns={columns}
            rowSelection={rowSelection}
          />
        </Col>
      </Row>
    </ErrorBoundary>
  );
};

const initialUpdates: Readonly<MembershipUpdates> = {grant: [], revoke: []};

const UserEditModal: React.FC<{
  selected: AdminUserEntity[];
  isModalOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
}> = ({selected, isModalOpen, onOk, onCancel}) => {
  const {authedFetcher, t} = useApis();
  const [updates, setUpdates] = useState<MembershipUpdates>(initialUpdates);
  const transitiveUserAdminGroups = useAdminUserGroupFilter(selected);
  const canEdit = transitiveUserAdminGroups.length > 0;
  const cleanupOnCancel = useCallback(() => {
    setUpdates(initialUpdates);
    onCancel();
  }, [onCancel]);
  return (
    <Modal
      open={isModalOpen}
      onOk={
        canEdit
          ? async () => {
              try {
                // Postgres has an issue with concurrent updates on data.memberships.
                // One workaround is to serialize the requests.
                for (const u of updates.revoke) {
                  await revokeUserAccess(authedFetcher, {
                    param_email: u.email,
                    param_user_group: u.user_group.user_group,
                    param_membership_type: u.membership_type,
                  });
                }
                for (const u of updates.grant) {
                  await grantUserAccess(authedFetcher, {
                    param_email: u.email,
                    param_user_group: u.user_group.user_group,
                    param_membership_type: u.membership_type,
                  });
                }
              } finally {
                setUpdates(initialUpdates); // Reset update state.
                onOk();
              }
            }
          : cleanupOnCancel
      }
      onCancel={cleanupOnCancel}
      destroyOnClose={true}
      width="80%"
      title={
        <span>
          <span>{t('ChangeUserAccessRights')}</span>
          {selected.length == 1 ? (
            <>
              {': '}
              <span style={{fontWeight: 'bold'}}>{selected[0].email}</span>
            </>
          ) : null}
        </span>
      }>
      <div data-testid="user-edit-modal">
        <EditWarning type={canEdit ? 'none' : selected.length > 1 ? 'UserAdminNoCommon' : 'UserAdminNoAccess'} />
        <AdminUserEntityEdit selected={selected} onChange={setUpdates} />
        {canEdit ? <UserEntityUpdates updates={updates} /> : null}
      </div>
    </Modal>
  );
};

const EditWarning: FC<{type: 'none' | 'UserAdminNoAccess' | 'UserAdminNoCommon'}> = ({type}) => {
  const {t} = useApis();
  if (type == 'none') {
    return null;
  }
  return <Alert style={{marginBottom: 10}} type="error" icon={<WarningOutlined />} message={t(type)} />;
};

function getUserName(record: AdminUserEntity) {
  if (record.first_name && record.last_name) {
    return `${record.first_name} ${record.last_name}`;
  }
  if (record.last_name) {
    return record.last_name;
  }
  return record.first_name ?? '';
}

function toUserMemberships(
  memberships: UserEntity[],
  userGroups: UserGroup[],
  userGroupMemberships: UserGroupMembership[],
  grantors: Record<string, UserGroup[]>,
): AdminUserEntity[] {
  return Object.values(
    memberships.reduce<Record<string, AdminUserEntity>>((users, u) => {
      if (u.email && !users[u.email]) {
        const customers: UserGroup[] = (u.customers ?? []).sort().map(c => userGroups?.find(g => g.user_group == c)!);
        users[u.email] = {
          user_id: u.user_id,
          key: u.email,
          selectable: true,
          customers,
          email: u.email,
          first_name: u.first_name ?? undefined,
          last_name: u.last_name ?? undefined,
          accessible_user_groups: customers.flatMap(c => grantors[c.user_group]),
          memberships: (userGroupMemberships ?? [])
            .filter(m => m.email == u.email)
            .map(m => ({
              email: u.email!,
              membership_type: m.membership_type,
              user_group: userGroups.find(u => u.user_group == m.user_group)!,
            }))
            .sort((a, b) => {
              if (a.membership_type == b.membership_type) {
                return cmp(toName(a.user_group), toName(b.user_group));
              }
              return cmp(toName(a.user_group), toName(b.user_group));
            }),
        };
      }
      return users;
    }, {}),
  );
}
