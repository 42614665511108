import {QueryClientProvider, useQuery} from '@tanstack/react-query';
import {Form, Radio, Table} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import mapboxgl, {MapboxGeoJSONFeature} from 'mapbox-gl';
import * as React from 'react';
import {RefObject, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider, useSelector} from 'react-redux';
import {Store} from 'redux';
import {getEnabledFlags} from '../../../src/feature-flags';
import {LngLat, getBoundingBox} from '../../../src/geo';
import {I18nSimpleKey} from '../../../src/i18n/i18n';
import {LayerParams, LayerParamsCropMon} from '../../../src/layers/layer-params';
import {MapLayers} from '../../../src/layers/map-layers';
import {MapNavFocus} from '../../../src/map/map-focus';
import {MonitoredCrop} from '../../../src/models/crop-mon';
import {CropMonGeoProps} from '../../../src/models/geojson';
import {HarvestSeason} from '../../../src/models/interfaces';
import {IndexedCrops} from '../../../src/redux/reducers/crops';
import {getBaseCrop, getCrops} from '../../../src/selectors/crops';
import {convertArea, convertYield, getUnitSystem, getYieldUnits} from '../../../src/selectors/units';
import cmp from '../../../src/util/cmp';
import {ApisContext, useApis} from '../apis/ApisContext';
import {appQueryClient} from '../apis/appQueryClient';
import {CropTag, CropTags, useCropTags} from '../components/CropTags';
import {YieldTHa} from '../components/Yield';
import {reportErr} from '../util/err';
import BoxPlot from './BoxPlot';
import {cropMonitoringRegionAvailability, fetchCropMonSeries, getCropMonRegions} from './crop-mon-util';
import {MapStat, SelectableMapStatValues, mapStatsLayerId} from './useMapStatsLayer';

export function useShowPopup(
  map: RefObject<undefined | mapboxgl.Map>,
  layer: MapLayers,
  canonical_date: null | string,
  layerParams: null | LayerParams,
  mapFocus: null | MapNavFocus,
) {
  const apis = useContext(ApisContext);
  const {authedFetcher} = apis;
  // Maps ids to regions' names. Loaded when props.layer changes to crop-mon.
  // undefined means that we haven't started loading; null means either loading or error.
  const regionNames = useRef<null | undefined | {[region_id: string]: string}>();
  const popup = useRef<mapboxgl.Popup>();

  // TODO(savv): Remove pop up when the triggering feature (or layer) gets removed.
  useEffect(() => {
    if (layer == 'crop-mon' && regionNames.current === undefined) {
      regionNames.current = null;
      getCropMonRegions(authedFetcher)
        .then(x => (regionNames.current = x))
        .catch(reportErr);
    }
  }, [layer, canonical_date, layerParams, mapFocus, authedFetcher]);

  // remove popup only if parameters actually change
  let layerParamsJson = JSON.stringify(layerParams);
  useEffect(() => {
    popup.current?.remove();
  }, [layer, canonical_date, layerParamsJson]);

  const getCropMonDesc = useCallback(
    (f: MapboxGeoJSONFeature) => {
      if (layerParams?.layer_type != 'crop-mon' || !f.properties) {
        return null;
      }

      const mapCardNode = document.createElement('div');
      mapCardNode.id = 'crop-mon-popup';
      const root = createRoot(mapCardNode);
      root.render(
        <ApisContext.Provider value={apis}>
          <QueryClientProvider client={appQueryClient}>
            <Provider store={apis.store as Store}>
              <RegionStatPopup
                properties={f.properties as CropMonGeoProps}
                layerParams={layerParams}
                regionNames={regionNames.current}
              />
            </Provider>
          </QueryClientProvider>
        </ApisContext.Provider>,
      );
      return mapCardNode;
    },
    [apis, layerParams],
  );

  const getMapStatsDesc = useCallback(
    (f: MapboxGeoJSONFeature) => {
      if (f.layer.id != mapStatsLayerId) {
        return;
      }

      const properties = f.properties as {name?: string};
      const state = f.state as MapStat;
      const noStats = !state.total_area_ha && !state.num_fields && !state.num_samples && !state.estimated_yields;
      if (!properties.name || noStats) {
        return null;
      }

      const mapCardNode = document.createElement('div');
      mapCardNode.id = 'crop-mon-popup';
      const root = createRoot(mapCardNode);
      root.render(
        <div>
          <strong>{properties.name}</strong>
          <ApisContext.Provider value={apis}>
            <Provider store={apis.store as Store}>
              <MapStatPopup state={state} />
            </Provider>
          </ApisContext.Provider>
        </div>,
      );
      return mapCardNode;
    },
    [apis],
  );

  function showPopup(f: MapboxGeoJSONFeature) {
    // always remove old popup
    popup.current?.remove();

    const bbox = getBoundingBox(f);
    const description = getCropMonDesc(f) ?? getMapStatsDesc(f);
    if (!bbox || !description) {
      return false;
    }

    const {ne, sw} = bbox;
    const location: LngLat = [(ne[0] + sw[0]) / 2, (ne[1] + sw[1]) / 2];
    if (!map.current) {
      console.error(`Couldn't add mapbox popup, as map is not present!`);
      return false;
    }
    popup.current = new mapboxgl.Popup({
      className: 'width-20-vw',
      closeOnClick: false,
    })
      .setLngLat(location)
      .setDOMContent(description)
      .setMaxWidth('none')
      .addTo(map.current);

    // pan slightly left (to sw[1]), because we have toolbox on the right
    map.current?.panTo([(ne[0] + sw[0]) / 2, sw[1]]);

    return true;
  }

  return useCallback(showPopup, [getCropMonDesc, getMapStatsDesc, map]);
}

export const RegionStatPopup: React.FC<{
  properties: CropMonGeoProps;
  layerParams: LayerParamsCropMon;
  regionNames: null | undefined | {[region_id: string]: string};
}> = React.memo(({properties, layerParams, regionNames}) => {
  const apis = useApis();
  const units = useSelector(getUnitSystem);
  const [monitoredCrop, setMonitoredCrop] = useState<MonitoredCrop>(layerParams?.params[2] ?? '');
  const [harvestSeason, setHarvestSeason] = useState<HarvestSeason>(layerParams?.params[3] ?? '');

  const {data: boxPlotDatum} = useQuery(
    ['fetchCropMonSeries', monitoredCrop, harvestSeason, layerParams.params[4], properties.shape_type],
    () => {
      return fetchCropMonSeries(apis, monitoredCrop, harvestSeason, layerParams.params[4], properties.shape_type);
    },
    {refetchOnWindowFocus: false}, // required not to refetch all parameters on window focus
  );

  const {data: availability} = useQuery(['cropMonitoringRegionAvailability', properties.shape_type], () => {
    return cropMonitoringRegionAvailability(apis, properties.shape_type);
  });

  const yieldUnit = getYieldUnits(units, layerParams.params[2])[0];
  const regionStr = properties?.shape_type && regionNames && regionNames[properties?.shape_type];
  const val = properties.value;
  let valueStr;
  if (val == null || isNaN(val)) {
    valueStr = '-';
  } else if (layerParams.params[1] == 'benchmark-yield') {
    const yld = convertYield(
      yieldUnit,
      {
        val,
        unit: 'tons-per-hectare',
      },
      layerParams.params[2],
    );
    valueStr = yld ? apis.t({type: 'ValueUnit', ...yld}) : '-';
  } else {
    valueStr = val.toFixed(val > 10 ? 1 : 2) + '%';
    if (properties.benchmark_yield_t_ha != null) {
      const predicted_yield_t_ha = (properties.benchmark_yield_t_ha * val) / 100;
      const benchmark = convertYield(
        yieldUnit,
        {val: predicted_yield_t_ha, unit: 'tons-per-hectare'},
        layerParams.params[2],
      );
      valueStr += benchmark ? ' (' + apis.t({type: 'ValueUnit', ...benchmark}) + ')' : '';
    }
  }
  const cropStr = apis.t(layerParams.params[2]);

  const regionalPredictedYieldEnabled =
    getEnabledFlags(apis.store.getState()).has('regionalPredictedYield') &&
    (layerParams.params[1] === 'benchmark-yield' || layerParams.params[1] === 'predicted-yield');

  return (
    <div>
      <strong>{apis.t(layerParams.params[1])}</strong>
      {regionStr && (
        <>
          <br />
          {regionStr}
        </>
      )}
      <>
        <br />
        {cropStr}: {valueStr}
      </>

      {regionalPredictedYieldEnabled && availability && availability.length > 0 && (
        <>
          <br />
          <select
            id="crop-type-select"
            className="crop-mon-selector"
            value={monitoredCrop}
            onChange={e => setMonitoredCrop(e.target.value as MonitoredCrop)}>
            {[...new Set(availability.map(x => x.harvest_crop))].map(v => (
              <option value={v} key={v}>
                {apis.t(v)}
              </option>
            ))}
          </select>
          <select
            id="crop-season-select"
            className="crop-mon-selector"
            value={harvestSeason}
            onChange={e => setHarvestSeason(e.target.value as HarvestSeason)}>
            {[...new Set(availability.map(x => x.harvest_season))].map(v => (
              <option value={v} key={v}>
                {apis.t(v)}
              </option>
            ))}
          </select>
        </>
      )}
      {regionalPredictedYieldEnabled && boxPlotDatum && boxPlotDatum.length > 0 && (
        <BoxPlot t={apis.t} height={250} id="regional-crop-mon-box-plot" data={boxPlotDatum} />
      )}
      {regionalPredictedYieldEnabled && (!boxPlotDatum || boxPlotDatum.length === 0) && apis.t('NoData')}
    </div>
  );
});

const MapStatPopup: React.FC<{state: MapStat}> = React.memo(({state}) => {
  const {t} = useApis(),
    units = useSelector(getUnitSystem);
  const values: {key: SelectableMapStatValues; label: string; value: string}[] = [];

  if (state.total_area_ha) {
    values.push({
      key: 'total_area_ha',
      label: t('TotalCultivatedArea'),
      value: t({type: 'AreaUnit', ...convertArea(units.areaUnit, {unit: 'hectares', val: state.total_area_ha})!}),
    });
  }
  if (state.num_fields) {
    values.push({key: 'num_fields', label: t('NumberOfFields'), value: state.num_fields.toString()});
  }
  if (state.avg_field_area_ha) {
    values.push({
      key: 'avg_field_area_ha',
      label: t('AverageFieldArea'),
      value: t({type: 'AreaUnit', ...convertArea(units.areaUnit, {unit: 'hectares', val: state.avg_field_area_ha})!}),
    });
  }
  if (state.num_samples) {
    values.push({key: 'num_samples', label: t('NumberOfSamples'), value: state.num_samples.toString()});
  }

  return (
    <div>
      <div>
        {values.map(item => (
          <div key={item.key}>
            <i>{item.label}</i>: {item.value}
          </div>
        ))}
      </div>
      <div>
        <CropHarvestMatrix estimated_yields={state.estimated_yields} />
      </div>
    </div>
  );
});

type CropHarvestMatrixData = {crop_id: string; [year: string]: unknown};

const CropHarvestMatrixType = ['straight_estimated_yield_t_ha', 'weighted_estimated_yield_t_ha'] as const;
type CropHarvestMatrixType = (typeof CropHarvestMatrixType)[number];

const CropHarvestMatrix: React.FC<{estimated_yields: MapStat['estimated_yields']}> = React.memo(
  ({estimated_yields}) => {
    const {t, clock} = useApis();
    const [type, setType] = useState<CropHarvestMatrixType>('weighted_estimated_yield_t_ha');
    const rowsWithData = estimated_yields.filter(e => !!e.crop_id && e[type] != null);
    const cropIdsAll = [...new Set(estimated_yields.map(e => e.crop_id!))];
    const cropIdsWithData = [...new Set(rowsWithData.map(e => e.crop_id!))];
    const cropTags = useCropTags(cropIdsWithData);
    const crops: IndexedCrops = useSelector(getCrops);
    const now: Date = new Date(clock.now());
    // Only show the last 5 years and only years with data.
    const years = ([...new Set(rowsWithData.map(e => e.harvest_year))] as string[])
      .filter(year => parseInt(year) <= now.getFullYear() && parseInt(year) >= now.getFullYear() - 5)
      .sort();
    if (years.length === 0) return <CropTags cropIds={cropIdsAll} />;
    const columns: ColumnsType<CropHarvestMatrixData> = [
        {
          title: 'Crop',
          dataIndex: 'crop_id',
          key: 'crop_id',
          render: (cropId: string) => {
            const tag = cropTags.find(t => t.cropId === cropId);
            return tag ? <CropTag key={tag.cropId} {...tag} /> : t(cropId as I18nSimpleKey);
          },
          sorter: (a: CropHarvestMatrixData, b: CropHarvestMatrixData) => cmp(a.crop_id, b.crop_id),
          filterSearch: true,
          filterMode: 'menu',
          filters: [...new Set(cropIdsWithData.map(c => getBaseCrop(crops, c)).filter(b => !!b))].map(baseCrop => ({
            text: t(baseCrop as I18nSimpleKey),
            value: baseCrop as string,
          })),
          onFilter: (value: boolean | number | string, record) => getBaseCrop(crops, record.crop_id) === value,
        },
        ...years.map(year => ({
          title: year,
          dataIndex: year as string,
          key: 'year-' + year,
          sorter: (a: CropHarvestMatrixData, b: CropHarvestMatrixData) =>
            ((a[year] as number) ?? 0) - ((b[year] as number) ?? 0),
          render: (value: unknown, record: CropHarvestMatrixData) => {
            return <YieldTHa value={value as number} cropId={record.crop_id} />;
          },
        })),
      ],
      data: readonly CropHarvestMatrixData[] = rowsWithData.reduce((data, e) => {
        if (!e.crop_id || e[type] == null) return data;
        const cropId = data.find(d => d.crop_id === e.crop_id);
        if (!cropId) {
          data.push({key: e.crop_id + ';' + e.harvest_year, crop_id: e.crop_id, [e.harvest_year as string]: e[type]});
        } else {
          cropId[e.harvest_year as string] = e[type];
        }
        return data;
      }, [] as CropHarvestMatrixData[]);

    return (
      <div>
        <hr />
        <Form size="small" layout="horizontal">
          <Form.Item label={t('EstimatedYield')}>
            <Radio.Group onChange={e => setType(e.target.value)} value={type}>
              <Radio value="weighted_estimated_yield_t_ha">{t('ByFieldArea')}</Radio>
              <Radio value="straight_estimated_yield_t_ha">{t('ByFieldCount')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
        <Table<CropHarvestMatrixData>
          columns={columns}
          dataSource={data}
          size="small"
          pagination={{
            pageSize: 4,
          }}
          scroll={{x: true}}
        />
      </div>
    );
  },
);
