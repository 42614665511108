// An object that implements this interface is able to read data from an Sql response and also serialize
// to a format that can be used for insert/update requests. For example, PostGIS Geometry is returned as a WKB string.
import {Buffer} from 'buffer';
import {GeoJSON, Geometry, MultiPolygon, Point, Polygon} from 'geojson';
import wkt from 'terraformer-wkt-parser';
import {UnreachableCaseError} from 'ts-essentials';
// We use twkb to parse TWKB because it has support for geometry collections. See also:
// https://github.com/cschwarz/wkx/issues/30
import twkb from 'twkb';
import wkx from 'wkx';
import {LngLat} from '../geo';
import {
  Claim,
  ClaimDamage,
  Farm,
  Field,
  Harvest,
  HarvestData,
  Policy,
  RegionInfo,
  Sample,
  Visit,
  VisitLite,
} from './interfaces';
import {DateRange, NumRange, Range} from './types';

// TODO(savv): simplify entity interfaces by removing all serialization requirements

// order is important as some code uses it for insert order
export const TableName = ['farm', 'policy', 'claim', 'field', 'harvest', 'visit', 'claim_damage', 'sample'] as const;
export const TableWithChildren = ['farm', 'claim', 'field', 'harvest'] as const;
export const BundleTable = [...TableName, 'update_log'] as const;

export type TableName = (typeof TableName)[number];
export type TableWithChildren = (typeof TableWithChildren)[number];
export type BundleTable = (typeof BundleTable)[number];

export type EntityType = Claim | ClaimDamage | Farm | Field | Harvest | Policy | Sample | VisitLite;

export function isTableName(x: any): x is TableName {
  return typeof x == 'string' && TableName.includes(x as any);
}

export function isTableWithChildren(x: any): x is TableWithChildren {
  return typeof x == 'string' && TableWithChildren.includes(x as any);
}

export function isBundleTable(x: any): x is BundleTable {
  return typeof x == 'string' && BundleTable.includes(x as any);
}

export type SqlTransportFormat<T extends {}> = {
  [K in keyof T]: T[K] extends null | Date | DateRange | Geometry | LngLat | NumRange
    ? null extends T[K]
      ? null | string
      : string
    : T[K];
};

export type EntitySqlTransportFormat<T extends {}> = {updated_at: string} & SqlTransportFormat<T>;

class SerializationError extends Error {}

export type TransportOptions = {
  geo: 'twkb' | 'wkt';
};

function transportToGeojson(x: boolean | number | string): GeoJSON {
  if (typeof x !== 'string') {
    throw new SerializationError(`transportToGeojson: couldn't parse non-string (${typeof x}) geometry: ${x}`);
  }
  try {
    return twkb.toGeoJSON(Buffer.from(x, 'base64'));
  } catch (e) {
    throw new SerializationError(`Error for geometry ${x}: ${e}`);
  }
}

function transportToPoint(x: boolean | number | string): Point {
  const geom = transportToGeojson(x);
  if (geom.type === 'FeatureCollection' && geom.features.length === 1) {
    return geom.features[0].geometry as Point;
  } else if (geom.type === 'Point') {
    return geom;
  } else {
    throw new SerializationError(`transportToPoint: Geography ${x} was not a point (${geom.type}): ${geom}`);
  }
}

export function transportToLnglat(x: boolean | number | string): LngLat {
  return transportToPoint(x).coordinates as LngLat;
}

export function transportToPolygon(x: boolean | number | string): Polygon {
  const geom = transportToGeojson(x);
  const polygon: null | Polygon =
    geom.type == 'Polygon'
      ? geom
      : geom.type === 'FeatureCollection' && geom.features.length === 1 && geom.features[0].geometry.type == 'Polygon'
        ? geom.features[0].geometry
        : null;

  if (!polygon) {
    throw new SerializationError(`transportToPoint: Geography ${x} was not a polygon (${geom.type}): ${geom}`);
  }

  // TWKB is a lossy format, which may result in the last vertex only being approximately equal to the first one,
  // which is results in invalid Geojsons. We correct this here.
  for (const ring of polygon.coordinates ?? []) {
    if (ring.length) {
      ring[ring.length - 1] = ring[0];
    }
  }

  return polygon;
}

export function transportToMultiPolygon(x: boolean | number | string): MultiPolygon {
  const geom = transportToGeojson(x);
  const multiPolygon: null | MultiPolygon =
    geom.type == 'MultiPolygon'
      ? geom
      : geom.type === 'FeatureCollection' && geom.features.every(f => f.geometry.type == 'Polygon')
        ? {
            type: 'MultiPolygon',
            coordinates: geom.features.map(f => (f.geometry as Polygon).coordinates),
          }
        : null;

  if (!multiPolygon) {
    throw new SerializationError(`transportToPoint: Geography ${x} was not a multipoligon (${geom.type}): ${geom}`);
  }

  multiPolygon.coordinates.forEach(coordinates => {
    // TWKB is a lossy format, which may result in the last vertex only being approximately equal to the first one,
    // which is results in invalid Geojsons. We correct this here.
    for (const ring of coordinates) {
      if (ring.length) {
        ring[ring.length - 1] = [...ring[0]];
      }
    }
  });

  return multiPolygon;
}

export const lnglatToTransport = (lnglat: LngLat, opts: TransportOptions): string => {
  const point = {type: 'Point', coordinates: lnglat} as Point;
  if (opts.geo === 'twkb') {
    return new wkx.Point(lnglat[0], lnglat[1]).toTwkb().toString('base64');
  } else if (opts.geo === 'wkt') {
    return wkt.convert(point);
  } else {
    throw new SerializationError('Unknown value for TransportOptions.geo:  ' + opts.geo);
  }
};

export function geoJSONToTransport(x: Geometry, opts: TransportOptions): string {
  if (opts.geo === 'twkb') {
    return wkx.Geometry.parseGeoJSON(x).toTwkb().toString('base64');
  } else if (opts.geo === 'wkt') {
    return wkt.convert(x);
  } else {
    throw new SerializationError('Unknown value for TransportOptions.geo:  ' + opts.geo);
  }
}

export function farmToTransport(farm: Partial<Farm>, opts: TransportOptions): EntitySqlTransportFormat<Farm> {
  const serialized: EntitySqlTransportFormat<Farm> = {...farm} as any;
  if (farm.farm_location != null) {
    serialized.farm_location = lnglatToTransport(farm.farm_location, opts);
  }

  return serialized;
}

export function transportToFarm(serialized: EntitySqlTransportFormat<Farm>): Farm {
  const farm: Partial<Farm> = {...serialized} as any;
  if (serialized.farm_location != null) {
    farm.farm_location = transportToLnglat(serialized.farm_location);
  }

  return farm as Farm;
}

export function policyToTransport(policy: Partial<Policy>, opts: TransportOptions): EntitySqlTransportFormat<Policy> {
  const serialized: EntitySqlTransportFormat<Policy> = {...policy} as any;
  return serialized;
}

export function claimToTransport(claim: Partial<Claim>, opts: TransportOptions): EntitySqlTransportFormat<Claim> {
  const serialized: EntitySqlTransportFormat<Claim> = {...claim} as any;
  return serialized;
}

export function claimDamageToTransport(
  claimDamage: Partial<ClaimDamage>,
  opts: TransportOptions,
): EntitySqlTransportFormat<ClaimDamage> {
  const serialized: EntitySqlTransportFormat<ClaimDamage> = {...claimDamage} as any;
  return serialized;
}

export function transportToPolicy(serialized: EntitySqlTransportFormat<Policy>): Policy {
  const policy: Partial<Policy> = {...serialized} as any;
  return policy as Policy;
}

export function visitToTransport(visit: Partial<Visit>, opts: TransportOptions): EntitySqlTransportFormat<Visit> {
  const serialized: EntitySqlTransportFormat<Visit> = {...visit} as any;
  if (visit.signed_report) {
    serialized.signed_report = '\\x' + Buffer.from(visit.signed_report, 'ascii').toString('hex');
  }
  if (visit.attachments) {
    serialized.attachments = visit.attachments.map(x => '\\x' + Buffer.from(x, 'ascii').toString('hex'));
  }
  return serialized;
}

export function transportToVisitLite(serialized: EntitySqlTransportFormat<VisitLite>): VisitLite {
  const visit: Partial<VisitLite> = {...serialized} as any;
  // Convert visits to VisitLite, by deleting unwanted columns.
  delete (visit as Partial<Visit>).signed_report;
  delete (visit as Partial<Visit>).attachments;
  return visit as VisitLite;
}

export function visitToLite(visit: Partial<Visit>): Partial<VisitLite>;

export function visitToLite(visit: Visit): VisitLite;

export function visitToLite(visit: Partial<Visit> | Visit): Partial<VisitLite> | VisitLite {
  const {signed_report, attachments, ...lite} = visit;
  return lite;
}

export function fieldToTransport(field: Partial<Field>, opts: TransportOptions): EntitySqlTransportFormat<Field> {
  const serialized: EntitySqlTransportFormat<Field> = {...field} as any;
  if (field.field_location != null) {
    serialized.field_location = lnglatToTransport(field.field_location, opts);
  }
  if (field.user_location != null) {
    serialized.user_location = lnglatToTransport(field.user_location, opts);
  }
  if ('field_shape' in field && field.field_shape != null) {
    serialized.field_shape = geoJSONToTransport(field.field_shape, opts);
  }
  return serialized;
}

export function transportToField(serialized: EntitySqlTransportFormat<Field>): Field {
  return {
    ...serialized,
    field_location: serialized.field_location ? transportToLnglat(serialized.field_location) : null,
    user_location: serialized.user_location ? transportToLnglat(serialized.user_location) : null,
    field_shape: serialized.field_shape == null ? null : transportToPolygon(serialized.field_shape),
  };
}

export function harvestToTransport(harvest: Partial<Harvest>, _: TransportOptions): EntitySqlTransportFormat<Harvest> {
  const serialized: EntitySqlTransportFormat<Harvest> = {...harvest} as any;
  return serialized;
}

export function transportToHarvest(serialized: EntitySqlTransportFormat<Harvest>): Harvest {
  return {...serialized};
}

export function transportToClaim(serialized: EntitySqlTransportFormat<Claim>): Claim {
  const claim: Partial<Claim> = {...serialized} as any;
  return claim as Claim;
}

export function transportToClaimDamage(serialized: EntitySqlTransportFormat<ClaimDamage>): ClaimDamage {
  const claimDamage: Partial<ClaimDamage> = {...serialized} as any;
  return claimDamage as ClaimDamage;
}

export function transportToHarvestData(serialized: EntitySqlTransportFormat<HarvestData>): HarvestData {
  const harvestData: Partial<HarvestData> = {...serialized} as any;
  if (serialized.harvest_range) {
    harvestData.harvest_range = transportToDateRange(serialized.harvest_range as unknown as string);
  }
  return harvestData as HarvestData;
}

export function harvestDataToTransport(
  harvestData: Partial<HarvestData>,
  _: TransportOptions,
): EntitySqlTransportFormat<HarvestData> {
  const serialized: EntitySqlTransportFormat<HarvestData> = {...harvestData} as any;
  if (harvestData.harvest_range) {
    serialized.harvest_range = dateRangeToTransport(harvestData.harvest_range);
  }
  return serialized;
}

export function sampleToTransport(sample: Partial<Sample>, opts: TransportOptions): EntitySqlTransportFormat<Sample> {
  const serialized: EntitySqlTransportFormat<Sample> = {...sample} as any;
  if (sample.sample_location != null) {
    serialized.sample_location = lnglatToTransport(sample.sample_location, opts);
  }
  if (sample.user_location != null) {
    serialized.user_location = lnglatToTransport(sample.user_location, opts);
  }
  if (sample.sample_shape != null) {
    serialized.sample_shape = geoJSONToTransport(sample.sample_shape, opts);
  }
  return serialized;
}

export function transportToSample(serialized: EntitySqlTransportFormat<Sample>): Sample {
  const sample: Partial<Sample> = {...serialized} as any;
  if (serialized.sample_location != null) {
    sample.sample_location = transportToLnglat(serialized.sample_location);
  }
  if (serialized.user_location != null) {
    sample.user_location = transportToLnglat(serialized.user_location);
  }
  if (serialized.sample_shape != null) {
    sample.sample_shape = transportToPolygon(serialized.sample_shape);
  }
  return sample as Sample;
}

export function transportToEntity(table: 'farm', serialized: EntitySqlTransportFormat<Farm>): Farm;
export function transportToEntity(table: 'policy', serialized: EntitySqlTransportFormat<Policy>): Policy;
export function transportToEntity(table: 'field', serialized: EntitySqlTransportFormat<Field>): Field;
export function transportToEntity(table: 'harvest', serialized: EntitySqlTransportFormat<Harvest>): Harvest;
export function transportToEntity(table: 'sample', serialized: EntitySqlTransportFormat<Sample>): Sample;
export function transportToEntity(table: 'visit', serialized: EntitySqlTransportFormat<VisitLite>): VisitLite;
export function transportToEntity(table: 'claim', serialized: EntitySqlTransportFormat<Claim>): Claim;
export function transportToEntity(
  table: 'claim_damage',
  serialized: EntitySqlTransportFormat<ClaimDamage>,
): ClaimDamage;
export function transportToEntity(table: TableName, serialized: EntitySqlTransportFormat<EntityType>): EntityType;
export function transportToEntity(table: TableName, serialized: EntitySqlTransportFormat<EntityType>): EntityType {
  if (table == 'farm') {
    return transportToFarm(serialized as EntitySqlTransportFormat<Farm>);
  }
  if (table == 'policy') {
    return transportToPolicy(serialized as EntitySqlTransportFormat<Policy>);
  }
  if (table == 'field') {
    return transportToField(serialized as EntitySqlTransportFormat<Field>);
  }
  if (table == 'harvest') {
    return transportToHarvest(serialized as EntitySqlTransportFormat<Harvest>);
  }
  if (table == 'sample') {
    return transportToSample(serialized as EntitySqlTransportFormat<Sample>);
  }
  if (table == 'visit') {
    return transportToVisitLite(serialized as EntitySqlTransportFormat<VisitLite>);
  }
  if (table == 'claim') {
    return transportToClaim(serialized as EntitySqlTransportFormat<Claim>);
  }
  if (table == 'claim_damage') {
    return transportToClaimDamage(serialized as EntitySqlTransportFormat<ClaimDamage>);
  }

  throw new UnreachableCaseError(table);
}

export function transportToNumRange(range: null | string): null | NumRange {
  if (!range) return null;
  const match = /^([([])([^,]*),([^)\]]*)([)\]])$/.exec(range);
  if (!match) {
    console.error("transportToNumRange: couldn't parse range: %s (%s)", range, typeof range);
    return null;
  }
  return {
    begin: parseFloat(match[2]),
    end: parseFloat(match[3]),
    bounds: match[1] + match[4],
  };
}

export function numRangeToTransport(range: null | NumRange): null | string {
  return rangeToTransport(range);
}

export function transportToDateRange(range: null | string): null | DateRange {
  if (!range) return null;
  const match = /^([([])([^,]*),([^)\]]*)([)\]])$/.exec(range);
  if (!match) {
    console.error("transportToDateRange: couldn't parse range: %s (%s)", range, typeof range);
    return null;
  }
  return {
    begin: match[2],
    end: match[3],
    bounds: match[1] + match[4],
  };
}

function rangeToTransport<T>(range: null | Range<T>): null | string {
  if (!range) return null;
  return `${range.bounds.substring(0, 1)}${range.begin},${range.end}${range.bounds.substring(1)}`;
}

export function dateRangeToTransport(dateRange: null | DateRange): null | string {
  return rangeToTransport(dateRange);
}

export function transportToRegionInfo(regionInfo: null | string): null | (null | RegionInfo)[] {
  return (regionInfo?.match(/\(([^)]+)\)/g) ?? []).map(regionString => {
    const matches = regionString?.match(/\((\d+),([^,]+),([^)]+)\)/);
    if (matches) {
      const [, regionLevel, regionId, regionName] = matches;
      return {
        region_level: regionLevel,
        region_id: regionId,
        region_name: regionName.replace(/"/g, ''), // multi word region names are wrapped with double quotes and we must remove them manually
      } as RegionInfo;
    }
    return null;
  });
}
