import {Feature, FeatureCollection, Point, Polygon, Position} from 'geojson';
import {ClockI} from '../Clock';
import {CommonApis} from '../CommonApis';
import {FieldSeriesResponse, getEnabledAnomalies2} from '../components/FieldSeriesPlot';
import {ReportConfig} from '../forms/ReportConfig';
import {EntityGeoProps} from '../models/geojson';
import {Harvest, HarvestCrop, getFieldSeries} from '../models/interfaces';
import {IndexedCrops} from '../redux/reducers/crops';
import {DbState} from '../redux/reducers/db';
import {getHarvestAnalytics, getMinMaxSampleDate, getSum} from '../selectors/aggregators';
import {getBaseCrop} from '../selectors/crops';
import {getHarvestKey} from '../selectors/harvest-key';
import {UnitSystem} from '../selectors/units';
import {UserConfig} from '../selectors/userConfig';
import {filterFalsy, filterNulls} from '../util/arr-util';
import cmp from '../util/cmp';
import {isFarmHarvest} from '../util/harvest-util';
import {getSelectedKeys} from '../util/obj-util';
import {FarmReportPackage, FarmReportValues} from './report-types';
import {cmpSamples, filterFarmEntities} from './report-util';

type HarvestData = FarmReportValues['harvestData'];
type SampleData = FarmReportValues['sampleData'];

export interface InterfieldData {
  harvest_id: string;
  interfield: any;
}

interface InterfieldShapeProperties {
  shape_type: string;
}

export async function generateReport(
  apis: CommonApis,
  userConfig: UserConfig,
  filteredDbState: Pick<DbState, 'farm' | 'field' | 'harvest' | 'policy' | 'sample'>,
  config: ReportConfig,
  offline: boolean,
  fieldShapes: {[field_id: string]: undefined | Polygon},
): Promise<FarmReportPackage> {
  const {units, flags, crops, countryGroups} = userConfig;

  const numFields = Object.keys(filteredDbState.field).length;
  if (!offline && numFields > 100) {
    throw new Error(`Too many fields (${numFields})`);
  }

  const harvestData: null | FieldSeriesResponse[] = offline
    ? []
    : await getFieldSeries(
        apis.authedFetcher,
        {_farm_ids: [config.farm_id], _harvest_year: config.harvest_year, _by_harvest: true},
        true,
      );

  const cropIds = Object.entries(config.cropIds)
    .filter(x => x[1])
    .map(x => x[0]);
  const params2: [string, string][] = [
    ['farm_id', config.farm_id!],
    ['harvest_year', config.harvest_year!],
    ['crop_ids', '{' + cropIds.join(',') + '}'],
  ];

  const harvestMapFeatures: null | InterfieldData[] = offline
    ? []
    : await apis.authedFetcher({
        method: 'GET',
        path: 'api/rpc/get_farm_report_interfield',
        params: params2,
      });

  const anomalies = {...getEnabledAnomalies2(flags, 'web'), vegetation: false};
  const reportValues = getReportValues(
    units,
    crops,
    apis.clock,
    filteredDbState,
    fieldShapes,
    config,
    harvestData || [],
    harvestMapFeatures || [],
  );
  return {...reportValues, crops, countryGroups, flags, anomalies};
}

function prepareHarvestData(harvestData: FieldSeriesResponse[]): HarvestData {
  const result: HarvestData = {};

  for (const {harvest_id, series, emergence_date, max_vi_date} of harvestData) {
    result[harvest_id!] = {
      harvestPeak: max_vi_date ?? null,
      emergenceDate: emergence_date ?? null,
      fieldSeries: series,
    };
  }
  return result;
}

export type HarvestMapFeatures = {
  field: null | Feature<Polygon>;
  interfield: FeatureCollection<Polygon>;
  samples: FeatureCollection<Point, EntityGeoProps>;
  sampleShapes: FeatureCollection<Polygon>;
};

function prepareHarvestMapFeatures(
  harvestMapsData: InterfieldData[],
  fieldShapes: FarmReportValues['fieldShapes'],
  summaryByHarvest: FarmReportValues['summaryByHarvest'],
  sampleData: SampleData,
): FarmReportValues['harvestMapFeatures'] {
  const harvestFeatures: FarmReportValues['harvestMapFeatures'] = {};

  const interfieldFeatures = Object.fromEntries(harvestMapsData.map(x => [x.harvest_id, x.interfield]));

  for (const summary of summaryByHarvest) {
    for (const harvest of summary.harvests) {
      const harvest_id = harvest.harvest.harvest_id;
      const field_shape = (harvest.field && fieldShapes[harvest.field.field_id]) ?? null;

      const fieldShape: HarvestMapFeatures['field'] = field_shape
        ? {
            type: 'Feature',
            geometry: field_shape,
            properties: {},
          }
        : null;

      const interfield = interfieldFeatures[harvest.harvest.harvest_id] || [];

      // sort in case zones are overlapping and make sure that lower NDVI zones are on top
      interfield.sort((a: InterfieldShapeProperties, b: InterfieldShapeProperties) => cmp(b.shape_type, a.shape_type));

      const interfieldShapes: HarvestMapFeatures['interfield'] = {
        type: 'FeatureCollection',
        features: interfield.map((x: any) => ({
          type: 'Feature',
          geometry: x.shape,
          properties: {shape_type: x.shape_type},
        })),
      };

      const samplesFeatures: HarvestMapFeatures['samples'] = {
        type: 'FeatureCollection',
        features: harvest.samples
          .filter(x => !!x.sample_location)
          .map(x => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: x.sample_location as Position,
            },
            properties: {
              label: sampleData[x.sample_id].index,
              type: 'sample',
            },
          })),
      };

      const sampleShapes: HarvestMapFeatures['sampleShapes'] = {
        type: 'FeatureCollection',
        features: filterNulls(
          harvest.samples.map(x => {
            if (x.sample_shape?.type != 'Polygon') {
              return null;
            }
            return {
              type: 'Feature',
              geometry: x.sample_shape,
              properties: {
                label: sampleData[x.sample_id].index,
                type: 'sample',
              },
            };
          }),
        ),
      };

      harvestFeatures[harvest_id] = {
        samples: samplesFeatures,
        interfield: interfieldShapes,
        field: fieldShape,
        sampleShapes,
      };
    }
  }

  return harvestFeatures;
}

export function getReportValues(
  units: UnitSystem,
  crops: IndexedCrops,
  clock: ClockI,
  // NOTE: expects dbState to have been pre-filtered according to the config!
  dbState: Pick<DbState, 'farm' | 'field' | 'harvest' | 'policy' | 'sample'>,
  fieldShapes: {[field_id: string]: undefined | Polygon},
  config: ReportConfig,
  harvestTimeseriesData: FieldSeriesResponse[],
  harvestMapsData: InterfieldData[],
): FarmReportValues {
  if (!config.farm_id || !config.harvest_year) {
    throw new Error('ReportConfig.farm_id or harvest_year was null');
  }

  // Only keep samples with sample dates that are in the config.
  const filteredDbState = filterFarmEntities(
    dbState,
    config.harvest_year,
    getSelectedKeys(config.cropIds),
    getSelectedKeys(config.sampleDates),
  );
  const {farm: farmObj, field: fieldObj, sample: sampleObj, harvest: harvestObj} = filteredDbState;

  const farm_ids = Object.keys(farmObj);
  const farm = farm_ids.length == 1 ? farmObj[farm_ids[0]] : null;

  const summaryByHarvest = getHarvestAnalytics(units, filteredDbState, crops, getHarvestKey);
  summaryByHarvest.sort((a, b) => {
    // Sort "unknown" crop type to the end.
    const aCrop: HarvestCrop = getBaseCrop(crops, a.crop_id) ?? 'unknown';
    const bCrop: HarvestCrop = getBaseCrop(crops, b.crop_id) ?? 'unknown';
    if (aCrop == 'unknown' && bCrop != 'unknown') {
      return 1;
    }
    if (aCrop != 'unknown' && bCrop == 'unknown') {
      return -1;
    }

    const samplesA = a.harvests.map(x => x.samples.length).reduce((a, b) => a + b, 0);
    const samplesB = b.harvests.map(x => x.samples.length).reduce((a, b) => a + b, 0);
    // Sort by number of samples, and then number of fields.
    return samplesB - samplesA || b.harvests.length - a.harvests.length;
  });

  const sampleData: SampleData = {};
  for (const summary of summaryByHarvest) {
    for (const harvest of summary.harvests) {
      harvest.samples = harvest.samples.filter(
        sample => sample.sample_date && sample.sample_date in config.sampleDates,
      );
      // Sort samples by sample date and then by sample id.
      // IMPORTANT: the renderer's harvest map features generator depends on this logic, in order to generate consistent labels.
      harvest.samples.sort(cmpSamples);
      for (let i = 0; i < harvest.samples.length; ++i) {
        sampleData[harvest.samples[i].sample_id] = {
          index: '#' + String(i + 1),
        };
      }
    }
    summary.harvests.sort((a, b) => b.samples.length - a.samples.length);
  }
  const harvestsById = Object.fromEntries<undefined | Harvest>(
    Object.values(harvestObj).map(harvest => [harvest.harvest_id, harvest]),
  );
  const allSamplesExceptOverride = Object.values(sampleObj).filter(sample => {
    const harvestForSample = harvestsById[sample.harvest_id];
    return harvestForSample && !isFarmHarvest(harvestForSample);
  });
  const allFields = Object.values(fieldObj);
  const sampleDateMinMax = getMinMaxSampleDate(allSamplesExceptOverride);
  const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const generationDate = new Date(clock.now() - tzOffset).toISOString().slice(0, 10);

  const harvestData = prepareHarvestData(harvestTimeseriesData);
  const harvestMapFeatures = prepareHarvestMapFeatures(harvestMapsData, fieldShapes, summaryByHarvest, sampleData);

  const dates = summaryByHarvest
    .map(fh => fh.harvests.map(x => filterNulls(x.samples.map(x => x.sample_date))))
    .flat(2);
  const samplingDates = [...new Set(dates)];
  samplingDates.sort();

  return {
    type: 'ExpertReport',
    units,
    config,
    farm,
    sampleDateMinMax,
    samplingDates,
    totalSurface: getSum(filterNulls(allFields.map(x => x.field_area)), units.areaUnit, null),
    numFields: allFields.length,
    numSamples: allSamplesExceptOverride.length,
    numPhotos: allSamplesExceptOverride.map(x => x.images.length).reduce((a, b) => a + b, 0),
    numHarvestYears: new Set(summaryByHarvest.map(x => x.harvest_year)).size,
    fieldShapes,
    summaryByHarvest,
    sampleData,
    harvestData,
    harvestMapFeatures,
    generationDate,
    experts: filterFalsy(Array.from(new Set(allSamplesExceptOverride.map(x => x.added_by)))),
  };
}
