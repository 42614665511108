import {Alert, Form, Radio, RadioChangeEvent, Select} from 'antd';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MIN_ENTITY_FEATURES_ZOOM} from '../../../src/constants/colors';
import {getEnabledFlags} from '../../../src/feature-flags';
import {getLayerDates} from '../../../src/layers/canonical-date';
import {LayerParams} from '../../../src/layers/layer-params';
import {MapLayers} from '../../../src/layers/map-layers';
import {HarvestCrop, HarvestYear} from '../../../src/models/interfaces';
import {setDbFilter} from '../../../src/redux/actions/filters';
import {IndexedCrops} from '../../../src/redux/reducers/crops';
import {DbFilterState} from '../../../src/redux/reducers/filters';
import {getBaseCrop, getCrops} from '../../../src/selectors/crops';
import {getTodaysDate} from '../../../src/selectors/dbMeta';
import {getFilters} from '../../../src/selectors/filters';
import {getSelectableHarvestYears} from '../../../src/selectors/harvest';
import {convertArea, convertYield, getUnitSystem} from '../../../src/selectors/units';
import {cropDesc} from '../../../src/text/desc';
import cmp from '../../../src/util/cmp';
import {useApis} from '../apis/ApisContext';
import {NoDataAlert} from '../components/NoDataAlert';
import SpinningDots from '../components/SpinningDots';
import {getLastZoom} from '../redux/selectors';
import {DateSelector} from './DateSelector';
import {MapLegend, MapLegendView} from './MapLegend';
import './MapToolbox.css';
import {PlanetToolbox} from './PlanetToolBox';
import {SelectableMapStatValues, getLinearLabels, useCanShowMapStats, useMapStats} from './useMapStatsLayer';

interface MapToolboxProps {
  layer: Exclude<MapLayers, 'crop-mon'>;
  canonical_date: null | string;
  onCanonicalDateChanged: (date: null | string) => void;
  onSelectedMapStatChanged: (selected: SelectableMapStatValues) => void;
  showEntityFeatures: boolean;
  selectedMapStat: SelectableMapStatValues;
  layerParams: null | LayerParams;
  onLayerParamsChanged: (layerParams: null | LayerParams) => void;
}

export function MapToolbox({
  layer,
  onCanonicalDateChanged,
  onSelectedMapStatChanged,
  canonical_date,
  showEntityFeatures,
  selectedMapStat,
  layerParams,
  onLayerParamsChanged,
}: MapToolboxProps) {
  const {t} = useApis();
  const todaysDateUtc = useSelector(getTodaysDate),
    lastZoom = useSelector(getLastZoom),
    flags = useSelector(getEnabledFlags);
  const planetItemId = layerParams && layerParams.layer_type == 'planet' ? layerParams.params[0] : null;
  const layerDates = useMemo(() => getLayerDates(todaysDateUtc, layer), [layer, todaysDateUtc]);
  const [curDate, setCurDate] = useState(canonical_date || layerDates[layerDates.length - 1]);
  const {enabled, lowMapStats, highMapStats} = useMapStats(showEntityFeatures);
  const [satLayerTile, setSatLayerTile] = useState<'planet' | 'satellite'>(planetItemId ? 'planet' : 'satellite');

  const onPlanetItemIdChanged = useCallback(
    (planetItemId: null | string) => {
      onLayerParamsChanged({
        layer_type: 'planet',
        params: [planetItemId ?? ''],
      });
    },
    [onLayerParamsChanged],
  );

  useEffect(() => {
    if (layer != 'satellite') {
      onPlanetItemIdChanged(null);
    }
  }, [layer, onPlanetItemIdChanged]);

  useEffect(() => {
    setCurDate(canonical_date || layerDates[layerDates.length - 1]);
  }, [canonical_date, layer, layerDates]);

  const onDateChange = useCallback(
    (curDate: string) => {
      setCurDate(curDate);
      onCanonicalDateChanged(curDate);
    },
    [onCanonicalDateChanged],
  );

  const onTabChange = useCallback(
    (e: RadioChangeEvent) => {
      if (e.target.value != 'planet') {
        onPlanetItemIdChanged(null);
      }
      setSatLayerTile(e.target.value);
    },
    [onPlanetItemIdChanged],
  );

  const hasMapStats = enabled && lowMapStats && highMapStats;
  const showBasicMapLegend = !(layer == 'base' || layer == 'satellite') || lastZoom >= MIN_ENTITY_FEATURES_ZOOM;
  if (!showBasicMapLegend && !hasMapStats) {
    return null;
  }

  return (
    <div id="map-toolbox" className="card">
      {layer == 'satellite' ? (
        <>
          <Radio.Group className="map-toolbox-tabs" value={satLayerTile} buttonStyle="solid" onChange={onTabChange}>
            <Radio.Button value="satellite">{t('satellite')}</Radio.Button>
            <Radio.Button value="planet">{t('PlanetTiles')}</Radio.Button>
          </Radio.Group>
          <div className="map-toolbox-tabs-content">
            {satLayerTile == 'satellite' && (
              <DateSelector
                layerDates={layerDates}
                curDate={curDate}
                onChange={onDateChange}
                layer={layer}
                yearOnly={false}
              />
            )}
            {satLayerTile == 'planet' && (
              <PlanetToolbox planetItemId={planetItemId} onPlanetItemIdChanged={onPlanetItemIdChanged} />
            )}
          </div>
        </>
      ) : (
        <DateSelector
          layerDates={layerDates}
          curDate={curDate}
          onChange={onDateChange}
          layer={layer}
          yearOnly={false}
        />
      )}
      {showBasicMapLegend && <MapLegend layer={layer} layerParams={null} />}
      <MapStatsLegend
        showEntityFeatures={showEntityFeatures}
        selectedMapStat={selectedMapStat}
        onSelectedMapStatChanged={onSelectedMapStatChanged}
      />
    </div>
  );
}

const MapStatsLegend: React.FC<{
  showEntityFeatures: boolean;
  onSelectedMapStatChanged: (selected: SelectableMapStatValues) => void;
  selectedMapStat: SelectableMapStatValues;
}> = React.memo(({showEntityFeatures, onSelectedMapStatChanged, selectedMapStat}) => {
  const {clock, t} = useApis();
  const {areaUnit, yieldUnit} = useSelector(getUnitSystem);
  const crops: IndexedCrops = useSelector(getCrops);
  const filters: DbFilterState = useSelector(getFilters);
  const {harvest_year, crop_id} = useSelector(getFilters);
  const dispatch = useDispatch();
  const options = useMemo(() => {
    const options: {label: string; value: SelectableMapStatValues}[] = [
      {label: `${t('TotalCultivatedArea')} (${t(areaUnit)})`, value: 'total_area_ha'},
      {label: `${t('AverageFieldArea')} (${t(areaUnit)})`, value: 'avg_field_area_ha'},
      {label: `${t('EstimatedYield')} (${t('ByFieldCount')})`, value: 'straight_estimated_yield_t_ha'},
      {label: `${t('EstimatedYield')} (${t('ByFieldArea')})`, value: 'weighted_estimated_yield_t_ha'},
      {label: `${t('NumberOfFields')}`, value: 'num_fields'},
      {label: `${t('NumberOfSamples')}`, value: 'num_samples'},
    ];
    return options;
  }, [areaUnit, t]);
  const {enabled, lowMapStats, highMapStats, isLoading} = useMapStats(showEntityFeatures);
  const canShowMapStats = useCanShowMapStats(selectedMapStat);
  const regionItems = useMemo(() => {
    if (!enabled || lowMapStats?.[selectedMapStat] == null || highMapStats?.[selectedMapStat] == null) {
      return null;
    }

    switch (selectedMapStat) {
      case 'weighted_estimated_yield_t_ha':
      case 'straight_estimated_yield_t_ha': {
        // *_estimated_yields is only selectable, if the user selects exactly one crop + year each.
        const crop_id: null | HarvestCrop = getBaseCrop(crops, filters.crop_id[0]!);
        const lowYield = convertYield(
            yieldUnit,
            {
              unit: 'tons-per-hectare',
              val: lowMapStats[selectedMapStat],
            },
            crop_id,
          )?.val,
          highYield = convertYield(
            yieldUnit,
            {
              unit: 'tons-per-hectare',
              val: highMapStats[selectedMapStat],
            },
            crop_id,
          )?.val;
        if (lowYield == null || highYield == null) {
          return null;
        }
        return getLinearLabels(lowYield, highYield);
      }
      case 'avg_field_area_ha':
      case 'total_area_ha': {
        const lowArea = convertArea(areaUnit, {unit: 'hectares', val: lowMapStats[selectedMapStat]!}),
          highArea = convertArea(areaUnit, {unit: 'hectares', val: highMapStats[selectedMapStat]!});
        if (lowArea == null || highArea == null) {
          return null;
        }
        return getLinearLabels(lowArea.val, highArea.val);
      }
      default: {
        const low = lowMapStats[selectedMapStat],
          high = highMapStats[selectedMapStat];
        if (low == null || high == null) {
          return null;
        }
        return getLinearLabels(low, high);
      }
    }
  }, [crops, filters.crop_id, yieldUnit, areaUnit, enabled, lowMapStats, highMapStats, selectedMapStat]);

  const years: {label: HarvestYear; value: HarvestYear}[] = useMemo(() => {
    return getSelectableHarvestYears(clock)
      .sort()
      .reverse()
      .map(y => ({label: y, value: y}));
  }, [clock]);

  const cropOptions: {label: string; value: string}[] = useMemo(() => {
    return Object.keys(crops)
      .map(crop_id => ({label: cropDesc(t, crops, crop_id), value: crop_id}))
      .sort((a, b) => cmp(a.label, b.label));
  }, [crops, t]);

  if (!enabled) return null;
  if (isLoading) return <SpinningDots size={20} />;
  const hasData = regionItems && regionItems.length > 0;

  const hideHarvestAndCropSelectors =
    selectedMapStat !== 'weighted_estimated_yield_t_ha' && selectedMapStat !== 'straight_estimated_yield_t_ha';

  return (
    <>
      <Form layout="vertical">
        <h3>{t('RegionalStatistic')}</h3>
        <Form.Item label="">
          <Select
            defaultValue={'total_area_ha'}
            onChange={onSelectedMapStatChanged}
            options={options}
            value={selectedMapStat}
          />
        </Form.Item>
        <Form.Item label={t('HarvestYear')} hidden={hideHarvestAndCropSelectors} required>
          <Select
            options={years}
            onChange={(year: HarvestYear) => {
              dispatch(setDbFilter({harvest_year: [year]}));
            }}
            value={harvest_year.length === 1 ? harvest_year[0] : undefined}
            placeholder={t('HarvestYear')}
          />
        </Form.Item>
        <Form.Item label={t('harvest_crop')} hidden={hideHarvestAndCropSelectors} required>
          <Select
            options={cropOptions}
            onChange={(crop_id: string) => {
              dispatch(setDbFilter({crop_id: [crop_id]}));
            }}
            value={crop_id.length === 1 ? crop_id[0] : undefined}
            showSearch
            placeholder={t('harvest_crop')}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          />
        </Form.Item>
      </Form>
      {!canShowMapStats && hasData && <Alert type="info" description={t('MustSelectCropAndYear')} showIcon />}
      {canShowMapStats && hasData && <MapLegendView infoText={null} showInfoText={undefined} items={regionItems!} />}
      {canShowMapStats && !hasData && <NoDataAlert />}
    </>
  );
});
